@import "assets/styles/scss/config/globals";

.homepage {
    // TODO : remove old styles
    .hero-mobile{
        display: none;
    }
    .hero-desktop{
        display: inline;
    }
    hero{
        margin-top: 24px;
        img{
            max-height: 420px;
            object-position: 50% 50%;
            object-fit: cover;
        }
    }
    // TODO : remove old styles end
    ads{
        width: 100%;
        height: auto;
        margin-block: 24px; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        ad-left{
           margin-right: 24px;
        }
        ad-right,
        ad-left{
            position: relative;
            &:hover{
                picture:first-of-type img{
                    opacity: 0;
                }
                picture:last-of-type img{
                   opacity: 1;
                }
            }
            picture:last-of-type img{
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
            }
            picture:first-of-type img{
               opacity: 1;
            }
            img{
                width: 100%;
                transition: all 0.5s ease-in-out;
            }
        }
    }
   @media screen and(min-width: $desktop-bp){
         ads{
            margin-block: 32px;
            ad-right,
            ad-left{
                img{
                    max-width: 624px;
                }
            }
        }
    }
     @media screen and(max-width: $mobile-bp) {
        .hero-mobile{
            display: inline;
            min-width: 100%;
        }
        .hero-desktop{
            display: none;
        }
        hero{
            img{
                max-height: 100%;
                width: 100%;
                height: auto;
            }
        }
        ads{
            margin-block: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            ad-left{
                margin-right: 0;
                margin-block: 16px;
            }
            ad-right,
            ad-left{
                img{
                    padding-inline: 24px;
                }
            }
        }
        footer-like{
            display: none;
        }
     }

    cms-cardoverlay {
        margin-bottom: 0;
    }

}

input[type="search"],
input[type="text"] {
    -webkit-appearance: none;
    border-radius: 0;
}

ad-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: auto;

    @media screen and(max-width: $mobile-bp) {
        order: -1;
        margin-bottom: 30px;
    }

    >picture {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        >img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    ad-content {
        width: 100%;
        max-width: 1000px !important;
    }

    ad-description {
        width: 240px;
        display: flex;
        flex-direction: column;

        p {
            line-height: 1;
            font-family: var(--camptonmedium);
            text-align: center;
            color: var(--black);

            &:first-child {
                display: flex;
                align-items: baseline;
                margin-bottom: 0;
                font-family: var(--camptonsemibold);
                font-size: 17rem;

                sub {
                    line-height: 1;
                    font-family: var(--camptonmedium);
                    font-size: 60px;
                }
            }

            &:nth-child(2) {
                margin-bottom: 10px;
                text-transform: uppercase;
                font-size: 4rem;
            }

            &:last-child {
                font-size: 18px;
                margin-bottom: 20px;
            }

            @media screen and(max-width: $mobile-bp) {
                &:first-child {
                    font-size: 127px;

                    sub {
                        font-size: 44px;
                    }
                }

                &:nth-child(2) {
                    font-size: 16px;
                }

                &:last-child {
                    font-size: 14px;
                }
            }
        }
    }
}

.search-wrapper-no-image {
    bottom: -125px;
    width: 100%;
    padding: 20px 25px !important;
    @media screen and(max-width: $mobile-bp) {
        bottom: inherit;
        width: inherit;
        padding: inherit;
    }
}

search-wrapper {
    position: absolute !important;
    bottom: -60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 125px;
    height: auto;
    padding: 15px 35px !important;
    z-index: 5;
    background: var(--beige);

    @media screen and(max-width: $mobile-bp) {
        bottom: -125px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 25px !important;
        position: initial !important;
    }

    >img {
        width: 85px;

        @media screen and(max-width: $mobile-bp) {
            display: none;
        }
    }

    input-group {
        display: flex;
        flex: 0 1 260px;

        @media screen and(max-width: $mobile-bp) {
            flex: 0;
        }

        input[type="search"] {
            -webkit-appearance: none;
            border-radius: 0;
            height: 50px;
            width: 260px;
            padding: 15px;
            border: 0;
            font-family: var(--camptonbook);
            font-size: 18px;
            background: #fff;

            @media screen and(max-width: 479px) {
                width: 220px;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 auto;
            height: 50px;
            width: 50px;
            background: var(--black);
        }
    }

    search-description {
        display: flex;
        flex-direction: column;
        margin: 0 35px;

        @media screen and(max-width: $mobile-bp) {
            margin: 0 0 15px 0;
        }

        search-title {
            font-family: var(--camptonsemibold);
            font-size: 2.8rem;

            @media screen and(max-width: $mobile-bp) {
                font-size: 22px;
                font-family: var(--camptonmedium);
            }
        }

        p {
            font-size: 18px;
            font-family: var(--camptonbook);

            b {
                font-family: var(--camptonsemibold);
                font-weight: normal;
            }

            @media screen and(max-width: $mobile-bp) {
                display: none;
            }
        }
    }
}

.clickable {
    cursor: pointer;
}

title-wrapper {
    display: flex;
    height: auto;
    margin: 20px;
    margin-bottom: 40px;
    padding: 0 24px;
}

duo-images {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-bottom: 80px !important;

    @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
        min-height: 0;
        margin-bottom: 20px !important;
        margin-top: 20px !important;
    }

    wrapper-global {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: calc(50% - 15px);
        position: relative;

        @media screen and(max-width: $mobile-bp) {
            width: 100%;
            min-height: 215px;
            height: auto;
            margin-bottom: 20px;
        }

        &:hover {
            wrapper-desc .title {
                transform: translateY(-4rem);
            }

            wrapper-desc .desc {
                opacity: 1;
                transform: translateY(0);

                @media screen and(max-width: $mobile-bp) {
                    display: none;
                }
            }

            wrapper-desc .discover {
                opacity: 1;
            }
        }

        >a>.wrapper {
            padding: 0;
        }
    }

    wrapper-desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
            overflow: hidden;
            color: #fff;
            transform: translateY(0);
            transition: transform .6s ease-in-out;
            text-transform: uppercase;
            font-family: var(--camptonsemibold);
            font-size: 2.8rem;
            font-weight: normal;
            line-height: 1.2;

            img {
                width: 65px;
                margin-bottom: 5px;
            }

            @media screen and(max-width: $mobile-bp) {
                font-size: 22px;
            }
        }

        .discover {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            overflow: hidden;
            width: 100%;
            padding: 20px 10px;
            bottom: 0;
            position: absolute;
            transition: opacity .6s .1s ease-in-out;
            font-family: var(--camptonmedium);
            font-size: 1.4rem;
            font-weight: normal;
            line-height: 1.2;
            text-transform: uppercase;
            color: #ffffff;
            background: var(--black);

        }

        .desc {
            display: inline-block;
            opacity: 0;
            overflow: hidden;
            transform: translateY(20px);
            position: absolute;
            bottom: 100px;
            transition: opacity .6s .1s ease-in-out, transform .6s .1s ease-in-out;

            font-family: var(--camptonbook);
            font-size: 18px;
            font-weight: normal;
            line-height: 1.4;
            color: #ffffff;

            b,
            strong {
                font-family: var(--camptonsemibold);
                font-size: 18px;
                font-weight: normal;
            }
        }
    }

    wrapper-img {
        display: block;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &::before {
            content: "";
            background: rgba(44, 46, 53, 0.3);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: rotate(0);
            transition: transform .6s ease-in-out;
        }

        &::after {
            content: "";
            opacity: 0;
            background: rgba(44, 46, 53, 0.3);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: opacity .6s ease-in-out;
        }
    }
}

subtitle-wrapper {
    display: flex;
    justify-content: space-between;
    line-height: 1.2;
    margin-bottom: 45px !important;

    @media screen and(max-width: $mobile-bp) {
        margin-bottom: 30px !important;
        flex-direction: column;
    }

    sub-content {
        font-family: var(--camptonbook);
        font-size: 22px;
        font-weight: normal;

        @media screen and(max-width: $mobile-bp) {
            font-size: 18px;
            margin-bottom: 15px;
        }

        h1,
        h2 {
            font-family: var(--camptonsemibold);
            font-size: 28px;
            font-weight: normal;
            text-align: left;
            padding-left: 0px !important;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 10px;
                font-size: 22px;
            }
        }

        a:hover{
            font-weight: bold;
        }
    }

    follow-wrapper {
        display: flex;
        align-items: center;
        font-family: var(--camptonsemibold);
        font-size: 20px;
        font-weight: normal;

        @media screen and(max-width: $mobile-bp) {
            font-size: 18px;
        }

        .social_link {
            width: 35px;
            margin-right: 10px;

            &:first-child {
                margin-left: 5px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: var(--camptonmedium);
            font-weight: normal;
            text-transform: uppercase;

            dots-wrapper {
                display: flex;
                margin-right: 3px;

                .dot {
                    height: 5px;
                    width: 5px;
                    background-color: var(--black);
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
            span{
                &:hover{
                    font-weight: bold;
                }
            }
        }
    }
}

range-slideshow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-x: hidden;
    margin-bottom: 70px;

    .swiper-button-lock {
        display: flex;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: 80px;
        width: 30px;
        background-color: var(--black);

        &:after {
            font-size: 18px;
            color: #fff;
        }

        &.swiper-button-disabled {
            background: #00000026;
            opacity: 0;
        }
    }

    .swiper-container {
        overflow: visible !important;
        height: 365px !important;
        min-height: 365px !important;
        padding: 0 15px;

        @media screen and(max-width: $mobile-bp) {
            height: 170px;
            min-height: 170px;
        }
    }

    card-container {
        height: auto;
        overflow: hidden;
        padding: 0;
        position: relative;

        a {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            &.social {
                height: 35px;
                width: 35px;
                position: absolute;
                bottom: 5px;
                left: 10px;
            }
        }
    }

    .solo {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: normal;
        font-size: 35px;

        .more {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;
            border-radius: 51%;
            width: 50px;
            height: 50px;
            border: 2px solid #fff;
            margin: 15px 0;

            &:after {
                content: '+';
                font-size: 40px;
                font-weight: normal;
            }
        }

        b {
            border: 1px solid #fff;
            font-weight: normal;
            font-size: 45px;
        }
    }
}

mosaic-slideshow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 100px;
    overflow-x: hidden;

    @media screen and(max-width: $mobile-bp) {
        margin-bottom: 60px;
    }

    .swiper-container {
        overflow: visible !important;
        height: auto;
        min-height: 350px;
        padding: 0 24px;
        width: 100%;

        @media screen and(max-width: $mobile-bp) {
            padding: 0 15px;
            height: 300px;
            min-height: 300px;
        }
    }

    .swiper-button-lock {
        display: flex;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-wrapper {
        // Exception to the breakpoint rule because complicated to refacto (In V2 possibly?)
        @media screen and(min-width: $mobile-bp) {
            height: 770px;
            /* Add  css */
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1.3fr 0.4fr 1.3fr;
            grid-template-areas:
                "one two three three"
                "one two five six"
                "four four five six"
            ;

            grid-gap: 25px;
            margin: 0 auto;
            max-width: $desktop-max-width;

            card-container {
                display: flex !important;
                width: auto !important;
                margin-right: 0 !important;

                &.one {
                    grid-area: one;
                }

                &.two {
                    grid-area: two;
                }

                &.three {
                    grid-area: three;
                }

                &.four {
                    grid-area: four;
                }

                &.five {
                    grid-area: five;
                }

                &.six {
                    grid-area: six;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
        
        @media screen and(max-width: $mobile-bp) {
            display: flex;
            transform: translateY(-50%);
            top: 50%;
            position: absolute;
            height: 80px;
            width: 30px;
            background-color: var(--black);
        }

        &:after {
            font-size: 18px;
            color: #fff;
        }

        &.swiper-button-disabled {
            background: #00000026;
            opacity: 0;
        }
    }

    card-container {
        position: relative;

        @media screen and(max-width: 599px) {
            width: 100% !important;
        }

        &:hover {
            wrapper-desc .title {
                transform: translateY(-5rem);
            }

            wrapper-desc .desc {
                opacity: 1;
                transform: translateY(0);
            }

            wrapper-desc .discover {
                opacity: 1;
            }

            wrapper-img {
                &::after {
                    opacity: 1;
                }
            }
        }

        .pimcore_area_mosaic-card {
            height: 100%;
        }

        wrapper-desc {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding: 15px;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            .title {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 0;
                padding-left: 10px;
                padding-bottom: 10px;
                overflow: hidden;
                color: #fff;
                transform: translateY(0);
                transition: transform .6s ease-in-out;
                text-align: left;
                font-family: var(--camptonsemibold);
                font-weight: normal;
                font-size: 22px;
                line-height: 1.2;
            }

            .discover {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                overflow: hidden;
                width: 100%;
                padding: 20px 10px;
                bottom: 0;
                position: absolute;
                transition: opacity .6s .1s ease-in-out;
                font-family: var(--camptonmedium);
                font-size: 1.4rem;
                font-weight: normal;
                line-height: 1.2;
                text-transform: uppercase;
                color: #ffffff;
                background: var(--black);

            }

            .desc {
                display: inline-block;
                opacity: 0;
                overflow: hidden;
                transform: translateY(20px);
                position: absolute;
                bottom: 100px;
                transition: opacity .6s .1s ease-in-out, transform .6s .1s ease-in-out;

                font-family: var(--camptonbook);
                font-size: 18px;
                font-weight: normal;
                line-height: 1.4;
                color: #ffffff;

                b,
                strong {
                    font-family: var(--camptonsemibold);
                    font-size: 18px;
                    font-weight: normal;
                }
            }
        }

        wrapper-img {
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;

            @media screen and(max-width: $mobile-bp) {
                position: inherit;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            &::before {
                content: "";
                // background: rgba(44, 46, 53, 0.3); grey transparent effect
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transform: rotate(0);
                transition: transform .6s ease-in-out;
            }

            &::after {
                content: "";
                opacity: 0;
                background: rgba(44, 46, 53, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transition: opacity .6s ease-in-out;
            }
        }
    }
}

homepage-image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* min-height: 645px; */
    height: auto;

    .gallery-top {
        width: 100%;

        .swiper-button-lock {
            display: flex;
        }

        .swiper-button-disabled {
            display: none;
        }

        .swiper-button-prev {
            left: 0;
            background: hsla(0, 0%, 100%, .741);
            color: #c3a269;
            height: 66px;

            &::after {
                font-size: 20px;
                font-weight: bold;
            }

            @media screen and(max-width: $mobile-bp) {
                display: none;
            }
        }

        .swiper-button-next {
            right: 0;
            background: hsla(0, 0%, 100%, .741);
            color: #c3a269;
            height: 66px;

            &::after {
                font-size: 20px;
                font-weight: bold;
            }

            @media screen and(max-width: $mobile-bp) {
                display: none;
            }
        }

        .swiper-pagination {
            display: none;
            position: absolute;
            bottom: 10px;
            margin: 0;
            justify-content: center;

            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                margin-right: 5px;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            .swiper-pagination-bullet-active {
                background: var(--black);
            }

            @media screen and(max-width: $mobile-bp) {
                display: flex;
            }
        }

        .swiper-slide {
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
            cursor: pointer;
            position: relative;

            card-desc {
                position: absolute;
                top: 20px;
                left: 0;
                display: flex;
                padding: 15px 20px;
                margin-right: auto;
                font-family: var(--camptonmedium);
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                background: var(--beige);
                color: #fff;

                @media screen and(max-width: $mobile-bp) {
                    font-size: 12px;
                    left: 25px;
                    padding: 10px 13px;
                }
            }

            .swiper-slide-img-desktop {
                @media screen and(max-width: $mobile-bp) {
                    display: none;
                }
            }

            .swiper-slide-img-mobile {
                display: none;
                @media screen and(max-width: $mobile-bp) {
                    display: block;
                }
            }

            img {
                object-fit: contain;

                &.icon_top {
                    object-fit: none;
                    width: 55px;
                    height: 40px;
                    position: absolute;
                    top: 20px;
                    right: 20px;

                    @media screen and(max-width: $mobile-bp) {
                        object-fit: contain;
                        width: 40px;
                        top: 10px;
                        right: 25px;
                    }
                }
            }
        }
    }
}

// Doesn't seem to be used
// logos-slideshow {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     overflow-x: hidden;
//     margin-bottom: 70px;

//     @media screen and(max-width: $mobile-bp) {
//         margin-bottom: 50px;

//     }

//     .swiper-logos .swiper-wrapper {
//         @media screen and(max-width: $mobile-bp) {
//             padding-bottom: 30px;
//         }
//     }

//     .swiper-pagination-lock {
//         display: none;
//         @media screen and(max-width: $mobile-bp) {
//             display: flex;
//             position: absolute;
//             bottom: 0;
//         }

//         .swiper-pagination-bullet {
//             width: 12px;
//             height: 12px;
//             margin-right: 5px;

//             &:last-of-type {
//                 margin-right: 0;
//             }
//         }

//         .swiper-pagination-bullet-active {
//             background: var(--black);
//         }
//     }

//     .swiper-button-lock {
//         display: none;
//         @media screen and(max-width: $mobile-bp) {
//             display: flex;
//             z-index: 999;
//             cursor: pointer;
//         }
//     }

//     .swiper-button-prev {
//         left: 0;
//     }

//     .swiper-button-next {
//         right: 0;
//     }

//     .swiper-button-prev,
//     .swiper-button-next {
//         transform: translateY(-50%);
//         top: 50%;
//         position: absolute;
//         height: 80px;
//         width: 30px;
//         background-color: var(--black);

//         &:after {
//             font-size: 18px;
//             color: #fff;
//         }

//         &.swiper-button-disabled {
//             background: #00000026;
//             opacity: 1;
//         }
//     }

//     .swiper-container {
//         overflow: visible !important;
//         height: auto;
//         min-height: 365px;
//         padding: 0 15px;
//         width: 100%;

//         @media screen and(max-width: $mobile-bp) {
//             min-height: 290px;
//         }
//     }

//     .swiper-wrapper {
//         @media screen and(min-width: 768px) {
//             flex-wrap: wrap;
//             display: flex;
//             flex-direction: column;
//         }
//     }

//     .swiper-slide {
//         display: flex;
//         flex-wrap: wrap;

//         @media screen and(min-width: 768px) {
//             width: 100% !important;
//             margin-right: 0 !important;
//         }
//     }

//     a {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: calc((100% / 6) - 10px) !important;
//         height: 140px !important;
//         padding: 10px;
//         margin: 15px 5px !important;
//         background: #fff;

//         @media screen and(max-width: 768px) {
//             width: calc(50% - 20px) !important;
//             height: 65px !important;
//             margin: 10px !important;
//         }

//         @media screen and(min-width: 768px) {
//             &:hover {
//                 box-shadow: 0px 3px 10px #0000001A;
//             }
//         }

//         img {
//             object-fit: contain;
//             width: 105px;
//         }
//     }
// }

design-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    margin-bottom: 90px;

    @media screen and(max-width: $mobile-bp) {
        margin-bottom: 60px;
        order: -1;
    }

    nav-tabs {
        position: relative;
        display: block;
        width: 100%;

        .tabs {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background-color: #fff;
            padding: 0;
            margin: 0 0 35px 0;
            border-bottom: 1px solid rgba(44, 46, 53, 0.4);
            ;

            @media screen and(max-width: $mobile-bp) {
                border: 1px solid var(--black);
                border-top: 0px;
                position: absolute;
                top: 51px;
                left: 25px;
                right: 25px;
                z-index: 100;
            }

            li {
                display: flex;
                align-items: center;
                cursor: pointer;
                min-height: 50px;
                height: auto;
                margin: 0 20px;
                border-bottom: 4px solid transparent;
                font-size: 18px;
                font-family: var(--camptonbook);
                font-weight: 400;
                text-transform: uppercase;
                color: rgba(44, 46, 53, 0.4);

                @media screen and(max-width: $mobile-bp) {
                    width: 100%;
                    padding: 5px;
                    margin: 0;
                    font-size: 16px;
                    border-bottom: 1px solid var(--black) !important;
                }

                &:nth-of-type(2) {
                    @media screen and(max-width: $mobile-bp) {
                        border-bottom: 0 !important;
                    }
                }

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                &.active_tab {
                    border-bottom: 4px solid var(--black);
                    font-family: var(--camptonmedium);
                    color: var(--black);
                    
                    @media screen and(max-width: $mobile-bp) {
                        border-bottom: 0;
                    }
                }

                &.follow {
                    margin-left: auto;
                    font-size: 14px;
                    font-family: var(--camptonmedium);
                    font-weight: 400;
                    color: var(--black);

                    @media screen and(max-width: $mobile-bp) {
                        display: none;
                    }

                    a {
                        display: flex;
                        align-items: center;
                    }

                    dots-wrapper {
                        display: flex;
                        margin-right: 3px;
                    }

                    .dot {
                        height: 5px;
                        width: 5px;
                        background-color: var(--black);
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 5px;
                    }
                }
            }

            li:hover {
                    font-weight: bold;
                    color: var(--black);
            }

        }
    }

    .wrapper {
        width: 100%;
    }

    .tabs_content {
        overflow: hidden;
        @media screen and(max-width: $mobile-bp) {
             overflow: visible;
        }
        li {
            display: none;

            &.show_tab {
                display: block;
            }
        }
    }

    .design_slideshow {
        position: relative;

        .swiper-container {
            overflow: hidden;
            height: auto;
            min-height: 350px;
            padding: 0 15px;
        }

        .swiper-button-lock {
            display: flex;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev,
        .swiper-button-next {
            transform: translateY(-50%);
            top: 50%;
            position: absolute;
            height: 80px;
            width: 30px;
            background-color: var(--black);

            &:after {
                font-size: 18px;
                color: #fff;
            }

            &.swiper-button-disabled {
                background: #00000026;
                opacity: 0;
            }
        }

        card-container {
            display: flex;
            flex-direction: column;
            padding: 30px;
            min-height: 350px;
            height: auto !important;
            background: var(--grey_light);
            min-width: 297px;

            &:hover {
                img {
                    &:first-of-type {
                        display: none;
                    }

                    &:last-of-type {
                        display: block;
                    }
                }
            }

            a {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
            }
            .wrapper-img-title{
                display: flex;
                flex-direction: column;
                height: 100%;

                .wrapper-img{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        margin-bottom: 15px;
                        display: block;
                    }
                }

                title-product {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: auto;

                    name-content {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        margin-bottom: 13px;
                        font-size: 18px;
                        font-family: var(--camptonsemibold);
                        font-weight: 400;
                        height: auto;
                        overflow: hidden;
              
                    }
                    .fiche-produit {
                        margin-bottom: 0;
                    }
                    .card-subline {
                        font-family: var(--camptonbook);
                        font-size: 14px;
                    }

                    .main_title {
                        width: 100%;
                        margin-bottom: 5px;
                        font-size: 14px;
                        font-family: var(--camptonbook);
                        font-weight: 400;
                        text-transform: uppercase;
                        color: rgba(44, 46, 53, 0.3);
                    }

                    .price {
                        font-size: 18px;
                        font-family: var(--camptonbook);
                        font-weight: 400;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        line-height: 18px; 

                        del,
                        .real_price {
                            text-align: right;
                            white-space: nowrap;
                        }

                        .new_price {
                            text-align: right;
                            color: #e51d3c;
                            font-family: var(--camptonmedium);
                            white-space: nowrap;
                        }
                        .old-price{
                            text-align: right;
                            font-size: 14px;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .color-choice,
            color-choice {
                display: flex;

                .colorContent,
                color-content {
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    margin-right: 8px;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.active {
                        position: relative;

                        &::before {
                            content: '';
                            border: 1px solid var(--black);
                            border-radius: 100%;
                            width: 21px;
                            height: 21px;
                            position: absolute;
                            left: -3px;
                            top: -3px;
                        }
                    }
                }
            }
        }
    }

    .tabs_responsive {
        display: none;

        &:after {
            content: ' ';
            position: absolute;
            top: 10%;
            right: 0;
            background: url(/assets/images/icons/_icon_filtre_chevron.svg) no-repeat;
            background-size: auto;
            background-size: 40px 40px;
            width: 40px;
            height: 40px;
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        @media screen and(max-width: $mobile-bp) {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            display: block;
            position: relative;
            height: auto;
            min-height: 50px;
            width: 100%;
            text-transform: uppercase;
            padding: 15px 25px 15px 5px;
            margin-bottom: 20px;
            border: 1px solid var(--black);
            text-align: left;
            font-size: 16px;
            font-family: var(--camptonmedium);
            font-weight: normal;
        }
    }

    /* mobile-select-input */
    button.tabs_responsive:not(:focus)+ul.tabs {
        @media screen and(max-width: $mobile-bp) {
            opacity: 0;
            pointer-events: none;
        }
    }

    /* mobile-select-input.unfolded  */
    button.tabs_responsive:focus+ul.tabs {
        @media screen and(max-width: $mobile-bp) {
            opacity: 1;
            pointer-events: none;
            visibility: visible;
        }
    }

    button.tabs_responsive+ul.tabs {
        pointer-events: unset !important;
        opacity: 1 !important;
        visibility: unset !important;
    }

    button.tabs_responsive:not(.js_responsive)+ul.tabs {
        @media screen and(max-width: $mobile-bp) {
            pointer-events: none !important;
            opacity: 0 !important;
        }
    }

    button.tabs_responsive:focus {
        @media screen and(max-width: $mobile-bp) {
            &:after {
                transform: rotate(-180deg);
                transition: all 0.25s ease-out;
            }
        }
    }
}

notice-wrapper {
    display: flex;
    background: var(--grey_light);
    margin-bottom: 90px;
    padding: 100px 0;

    @media screen and(max-width: $mobile-bp) {
        padding: 40px 25px;
        margin-bottom: 60px;
    }

    .swiper-button-lock {
        display: flex;
    }

    .swiper-button-prev {
        left: -30px;

        @media screen and(max-width: $mobile-bp) {
            left: -25px;
        }
    }

    .swiper-button-next {
        right: 0;

        @media screen and(max-width: $mobile-bp) {
            right: -25px;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: 80px;
        width: 30px;
        background-color: var(--black);

        &:after {
            font-size: 18px;
            color: #fff;
        }

        &.swiper-button-disabled {
            background: #00000026;
            opacity: 0;
        }
    }

    notice-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0 24px;

        @media screen and(min-width: $desktop-bp) {
            flex-direction: row;
        }

        @media screen and(max-width: $mobile-bp) {
            padding: 0 !important;
        }
    }

    notice-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;
        padding-bottom: 15px;

        @media screen and(max-width: $mobile-bp) {
            padding-bottom: 0;
        }

        @media screen and(min-width: $desktop-bp) {
            width: 40%;
            padding: 25px 0;
            margin-top: 0;
        }

        overall-average {
            display: flex;
            width: calc(100% - 35px);
            margin: 20px 0;

            @media screen and(min-width: $desktop-bp) {
                margin: inherit;
            }

            >img {
                height: 65px;
                width: 65px;
                margin-right: 12px;
            }
            a{
				margin-right: 12px;
			}

            feed-back {
                display: flex;
                flex-direction: column;
                padding-left: 12px;
                border-left: 1px solid #00000026;
                font-family: var(--camptonsemibold);
                font-weight: normal;
                font-size: 18px;

                .all {
                    display: flex;
                    font-family: var(--camptonbook);
                    font-size: 14px;
                    font-weight: normal;

                    b,
                    strong {
                        margin: 0 5px;
                    }
                }

                rate-wrapper {
                    margin: 5px 0 10px 0;
                    display: flex;
                    align-items: center;
                    font-family: var(--camptonsemibold);
                    font-weight: 400;
                    font-size: 18px;
                    color: var(--green);

                    half-star {
                        position: relative;
                        display: flex;
                        width: 25px;

                        img {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;

                            &:last-of-type {
                                z-index: 9;
                            }
                        }
                    }

                    card-rate {
                        display: flex;
                        margin-right: 10px;
                    }
                }
            }
        }

        h2 {
            font-size: 28px;
            font-weight: normal;
            font-family: var(--camptonsemibold);
            text-transform: uppercase;
            line-height: 1.2;

            @media screen and(max-width: $mobile-bp) {
                font-size: 22px;
            }
        }

        sub-title,
        h3 {
            font-family: var(--camptonbook);
            font-weight: normal;
            font-size: 22px;
            line-height: 1.2;

            @media screen and(max-width: $mobile-bp) {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }

        .btn {
            margin-right: auto;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 20px;
            }
        }
    }

    notice-slideshow {
        width: 100%;
        position: relative;

        @media screen and(min-width: $desktop-bp) {
            width: 60%;
        }

        .swiper-notice {
            padding: 25px 0;
        }

        card-container {
            min-height: 280px;
            height: auto !important;
            background: #fff;
            box-shadow: 0px 3px 30px #00000012;

            card-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 30px;
                height: 100%;
            }

            card-title {
                display: flex;
                justify-content: center;
                font-family: var(--camptonsemibold);
                font-size: 18px;
                font-weight: normal;
                color: rgba(44, 46, 53, 0.5);
            }

            card-description {
                display: flex;
                justify-content: center;
                font-family: var(--camptonbook);
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                line-height: 1.2;
                color: var(--black);
            }

            card-date {
                display: flex;
                justify-content: center;
                font-family: var(--camptonbook);
                font-size: 14px;
                font-weight: normal;
                line-height: 1.2;
                color: rgba(44, 46, 53, 0.5);
            }

            rate-wrapper {
                display: flex;
                align-items: center;
                margin-top: 20px;
                font-family: var(--camptonsemibold);
                font-weight: normal;
                font-size: 18px;
                color: var(--green);
            }

            card-rate {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 25px;
                    margin-right: 5px;
                }
            }
        }
    }
}

h1, h2 {
    margin-bottom: 30px;
    padding: 0 15px;
    text-align: center;
    line-height: 1.2;
    font-size: 28px;
    font-family: var(--camptonsemibold);
    font-weight: normal;
    text-transform: uppercase;
}

.follow_xs_visible {
    display: none;
    @media screen and(max-width: $mobile-bp) {
        display: flex;
    }

    margin-bottom: 30px;
    padding: 0 30px;
    max-width: 480px;
}

.follow_tab {
    margin-left: auto !important;

    @media screen and(max-width: $mobile-bp) {
        display: none !important;
    }

    &.active_tab {
        border: 0px !important;
    }
}

.follow_xs_visible,
.follow_tab {
    a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: var(--camptonmedium);
        font-weight: 400;
        text-transform: uppercase;
        color: var(--black);
    }

    span{
        &:hover{
            font-weight: bold;
        }
    }

    dots-wrapper {
        display: flex;
        margin-right: 3px;
    }

    .dot {
        height: 5px;
        width: 5px;
        background-color: var(--black);
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }
}

/*************************************************/
/*              Style DOOFINDER                  */
/*          CSS in order to style DOOFINDER      */
/*************************************************/
.df-topbar {
    .df-searchbox {
        overflow: hidden;
        position: relative !important;
        border: 0 !important;
        margin-right: 20px !important;
        padding-left: 25px;

        &:before {
            content: ' ' !important;
            position: absolute !important;
            top: 50%;
            transform: translateY(-50%);
            left: 35px;
            background: url(/assets/images/icons/_icon_search.svg) no-repeat !important;
            background-size: 35px 35px !important;
            width: 35px !important;
            height: 35px !important;
        }

        >input {
            background: #f6f6f6 !important;
            padding: 0 15px 0 60px !important;
            font-size: 18px !important;
            font-weight: normal !important;
            font-family: var(--camptonbook) !important;
            line-height: 1.2 !important;
            height: 50px !important;
            color: var(--black) !important;
        }

        >input::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-family: var(--camptonbook);
            font-size: 1.3rem;
            opacity: 1;
            color: var(--black);

            @media screen and (max-width: $mobile-bp),
                screen and (min-width: $desktop-bp) {
                font-size: 18px;
            }
        }

        >input::-moz-placeholder {
            /* Firefox 19+ */
            font-family: var(--camptonbook);
            font-size: 1.3rem;
            opacity: 1;
            color: var(--black);

            @media screen and (max-width: $mobile-bp),
                screen and (min-width: $desktop-bp) {
                font-size: 18px;
            }
        }

        >input:-ms-input-placeholder {
            /* IE 10+ */
            font-family: var(--camptonbook);
            font-size: 1.3rem;
            opacity: 1;
            color: var(--black);

            @media screen and (max-width: $mobile-bp),
                screen and (min-width: $desktop-bp) {
                font-size: 18px;
            }
        }

        >input:-moz-placeholder {
            /* Firefox 18- */
            font-family: var(--camptonbook);
            font-size: 1.4rem;
            opacity: 1;
            color: var(--black);

            @media screen and (max-width: $mobile-bp),
                screen and (min-width: $desktop-bp) {
                font-size: 18px;
            }
        }
    }

    .df-icon--close {
        transform: rotate(0deg);

        &:hover {
            transform: rotate(-180deg);
            transition: all 0.25s ease-out;
        }

        svg {
            fill: var(--black) !important;
            width: 33px !important;
        }
    }
}

.df-fullscreen .df-topbar__content [data-role="close"] {
    margin-left: 0 !important;
    flex: 0 0 33px !important;
}

.df-fullscreen .df-card__image {
    background: transparent !important;
}

.df-card__main {
    padding: 20px !important;
}

.df-layer__content .df-results .df-card {
    background: #f6f6f6 !important;
}

.df-card__title {
    font-family: var(--camptonbook) !important;
    font-weight: normal !important;
    color: var(--black) !important;
    line-height: 1.2 !important;
}

.df-card__pricing {
    font-family: var(--camptonmedium) !important;
    font-weight: normal !important;
}

.df-card__price {
    font-weight: normal !important;
}

[data-layout="grid"][data-browser-support~="cssgrid"] .df-results {
    grid-column-gap: 2px !important;
    grid-row-gap: 2px !important;
}

.df-fullscreen[data-facets="left"] .df-aside {
    margin-right: 0 !important;
}

.df-header {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 30px !important;
    margin-top: 20px !important;

    .df-header-title {
        >span {
            font-family: var(--camptonbook) !important;
            font-weight: normal !important;
            font-size: 22px !important;

            >span {
                font-family: var(--camptonsemibold) !important;
                font-size: 22px !important;
            }
        }
    }
}

.df-header-title {
    font-family: var(--camptonbook) !important;
}

.df-aside .df-panel__title {
    max-height: none !important;
    background-color: #f6f6f6 !important;
    padding: 15px 20px !important;
    text-transform: uppercase !important;
    font-family: var(--camptonsemibold) !important;
    font-size: 14px !important;
    font-weight: normal !important;
}

.df-panel__content>button {
    min-height: 50px !important;
    height: auto !important;
    display: inline-block !important;
    padding: 15px 30px !important;
    margin-bottom: 0 !important;
    margin-top: 15px !important;
    border: 1px solid var(--black) !important;
    color: var(--black) !important;
    background: 0 0 !important;
    font-family: var(--camptonmedium) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    text-decoration: none !important;
    line-height: 1.2 !important;
    text-align: center !important;
    cursor: pointer !important;
    transition: all .5s ease-in-out !important;
    appearance: none !important;
    position: relative !important;

    &:hover {
        background: var(--black) !important;
        border: 1px solid var(--black) !important;
        color: #fff !important;
    }
}

.df-panel--collapse .df-panel__title {
    background-size: 25px !important;
}

.df-term {
    font-family: var(--camptonbook) !important;
    font-size: 16px !important;
    padding-left: 20px !important;

    &:hover {
        border-radius: 0 !important;
        background-color: #f6f6f6 !important;
    }

    .df-term__value {
        color: var(--black) !important;
    }

    .df-term__count {
        color: #ABABAE !important;
    }
}

.noUi-pips.noUi-pips-horizontal {
    display: none !important;
}

.df-slider {
    padding-bottom: 14px !important;
    padding-top: 64px !important;
}

.df-slider .noUi-connect {
    background: var(--black) !important;
}

.df-slider .noUi-horizontal {
    height: 2px !important;
}

.df-branding {
    display: none !important;
}

.df-fullscreen .df-slider .noUi-tooltip {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 40px !important;
    padding: 5px !important;
    font-size: 16px !important;
    font-family: var(--camptonbook) !important;
    border-radius: 0px !important;
    color: var(--black) !important;
    border: 1px solid #CDCECF;
}

.df-fullscreen .df-aside__content {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.df-fullscreen .df-term[data-selected] {
    border-radius: 0 !important;
    background-color: #f6f6f6 !important;
    border: 1px solid transparent !important;

    &:hover {
        border: 1px solid transparent !important;
    }
}

.df-slider .noUi-horizontal .noUi-handle {
    border-radius: 50% !important;
    border: 0px !important;
    background: var(--black) !important;
    box-shadow: 0px 0px 10px #00000012 !important;

    &::after,
    &::before {
        display: none;
    }
}

.df-slider .noUi-target {
    box-shadow: none !important;
    border: none !important;
    border-radius: none !important;
    background: rgba(44, 46, 53, 0.1) !important;
    margin: 0 15px !important;
}

follow-wrapper-url {
    display: flex;
    align-items: center;

    span {
        font-size: 14px;
        font-family: var(--camptonmedium);
        font-weight: normal;
        text-transform: uppercase;
        color: black;
    }
}

#faceShapeIframe{
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
}

#openFaceShape{
    position: fixed;
    bottom: 100px;
    right: 50px;
    border-radius: 5px;
    z-index: 1;
}