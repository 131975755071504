@import "assets/styles/scss/config/globals";

responsive-title {
  display: none;

  @media screen and(max-width: $mobile-bp) {
    display: flex;
    flex-direction: column;
    padding: 20px 25px 15px 25px;
    background: #fff;
  }

  title-wrapper {
    display: flex;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    align-items: baseline;
    font-family: var(--camptonsemibold);
    font-size: 28px;
    font-weight: normal;
    margin-left: 0px;
    margin-right: 0px;

    h3 {
      padding-right: 10px;
    }

    div {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      align-items: flex-end;
      font-family: var(--camptonsemibold);
      font-size: 28px;
      font-weight: normal;
      white-space: nowrap;

      .padding-r1{
        padding-right: 1px;
      }

      del {
        padding-left: 10px;
        color: var(--red);
      }

      del + span {
        font-family: var(--camptonsemibold);
      }
    }
  }
}

product-card-title{
  margin-bottom: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;

  h1 {
    font-size: inherit;
    margin-bottom: 0px;
    text-transform: none;
  }

  product-card-title-name {
    font-size: 26px;
    font-weight: inherit;
  }

  product-card-title-price {
    font-size: 30px;
    font-weight: bold;
  }

  product-card-title-description {
    font-size: 16px;
    margin-bottom: 10px;
    @media screen and(max-width: $mobile-bp) {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }

  .product-card-desktop {
    @media screen and(max-width: $mobile-bp) {
      display: none;
    }
  }
}

.detail_product {
  @media screen and(max-width: $mobile-bp) {
    padding-top: 10px;
  }

  design-wrapper subtitle-wrapper {
    @media screen and(max-width: $mobile-bp) {
      margin-bottom: 0 !important;
    }
  }

  breadcrumb-block {
    padding-top: 25px !important;
    margin-bottom: 0px !important;
    flex-direction: row !important;

    @media screen and(max-width: $mobile-bp) {
      display: none !important;
    }
  }

  cms-fifty-image {
    @media screen and(max-width: $mobile-bp) {
      padding: 0 25px;
    }
  }

  .frame_glasses {
    display: none;

    &.is_active {
      display: inline-block;
    }
  }

  .frame_no_glasses {
    display: none;

    &.is_active {
      display: inline-block;
    }
  }
}

.product_content {
  position: relative;

  > .wrapper {
    display: flex;
    flex-direction: column;

    @media screen and(min-width: $desktop-bp) {
      flex-direction: row;
    }

    @media screen and(max-width: $mobile-bp) {
      padding: 0;
    }
  }

  //grey bg  ⚠️ ⚠️ ⚠️  with vto-btn "essayer en ligne": dynamic backgroundWrapper in show.html.twig
  background-wrapper {
    display: flex;
    height: 650px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: var(--grey_light);

    @media screen and(min-width: $desktop-bp) {
      height: 630px;
    }
    @media screen and(max-width: $mobile-bp) {
      height: 515px;
    }
  }

  wrapper-container {
    width: 100%;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and(min-width: $desktop-bp) {
      width: calc(58% - 15px);
    }
  }
}

product-size {
  display: flex;
}

product-description {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  margin-left: 0;
  padding: 30px 50px;
  box-shadow: 0px 3px 50px #00000012;
  background: #fff;

  title-wrapper{
    margin-bottom: 0;
    @media screen and(min-width: $desktop-bp) {
      margin-bottom: 20px;
    }
  }

  wrapper-frame-only {
    font-size: 15px;
    font-family: var(--camptonsemibold);
    text-decoration-line: underline;
    margin-top: 5px;

    @media screen and(max-width: $mobile-bp) {
      margin-top: 25px;
    }

    .frame-only {
      cursor:pointer;
    }
  }

  @media screen and(max-width: $large-thr) {
    padding: 30px;
  }

  @media screen and(min-width: $desktop-bp) {
    width: calc(42% - 15px);
    margin-left: 15px;
  }

  @media screen and(max-width: $mobile-bp) {
    padding: 30px 25px;
    padding-top: 0px;
  }

  .cancel-order-change {
    order: 0 !important;
  }

  .choose-lenses-button {
    order: -5;
    margin-bottom: 20px;

    @media screen and(min-width: $desktop-bp) {
      order: 0;
    }
  }
  
  .bbgr-banner {
    padding: 5px;
    width: 218px;
    margin-bottom: 20px;
    img {
        height: auto;
        width: 100%;
        object-fit: contain;
    }
  }

  .alma-banner {
    border: 1px solid lightgray;
    padding: 5px;
    width: fit-content;
    margin-bottom: 20px;
    font-size: 14px;

    @media screen and(max-width: $mobile-bp) {
      margin-bottom: 0;
    }

    .div-img {
      display: flex;
      align-items: center;

      span {
        padding-left: 12px;
        display:inline-block;
        padding-top: 5px;
        font-weight: bold;
        font-family: var(--camptonsemibold);
      }
    }

    .div-text {
      margin: 5px 9px;
    }

    .alma-img {
      width: 65px;
    }
  }

  form {
    order: -5;
    @media screen and(min-width: $desktop-bp) {
      order: 0;
    }

    .btn_3,
    beautified-select,
    .custom_select {
      margin-bottom: 20px;
    }

    .custom_select {
      width: 90px;
      min-width: auto;
    }
  }

  text-wrapper-container {
    margin-top: 15px;
  }

  text-wrapper {
    .accordion_responsive {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 5px 40px 5px 20px;
      margin-bottom: 5px;
      font-size: 18px;
      font-family: var(--camptonsemibold);
      font-weight: normal;
      background: #f6f6f6;

      &.is_active {
        &::after {
          content: "-";
          position: absolute;
          right: 20px;
          font-size: 25px;
          font-family: var(--camptonbook);
          font-weight: 400;
        }
      }

      &::after {
        content: "+";
        position: absolute;
        right: 20px;
        font-size: 25px;
        font-family: var(--camptonbook);
        font-weight: 400;
      }
    }

    .content_text {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      font-family: var(--camptonbook);
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: 10px;
      padding: 0 15px;

      h2,
      h3,
      h4 {
        margin-bottom: 15px !important;
        font-size: 18px !important;
        font-family: var(--camptonmedium) !important;
        font-weight: normal;
        color: var(--black) !important;
      }

      a {
        display: inline-block;
        margin-bottom: 15px !important;
        font-size: 14px;
        font-family: var(--camptonmedium) !important;
        font-weight: normal;
        text-decoration: underline !important;
        text-transform: uppercase !important;
      }

      ul {
        padding-left: 10px;
        margin-bottom: 25px;
        list-style: none;
        font-size: 18px;
        font-family: var(--camptonbook);
        font-weight: normal;

        li {
          display: flex;
          margin-bottom: 5px;

          div,
          p,
          span {
            margin-bottom: 0;
            margin-right: 10px;
            color: var(--grey);
            white-space: nowrap;
          }

          &::before {
            content: "\2022";
            color: var(--black);
            font-weight: bold;
            display: inline-block;
            width: 10px;
            margin-left: -10px;
            padding-right: 10px;
          }

          .glasses_shape {
            display: flex;
            flex-direction: column;
            margin-right: 0;

            picture {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: 25px;
              width: 60px;
              margin-right: 10px;
              overflow: hidden;

              img {
                flex: 1 0 60px;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            p {
              margin-bottom: 10px;
              display: flex;
              align-items: flex-start;
            }

            p,
            span {
              margin-right: 0;
              white-space: normal;
              line-height: 1.2;
              color: var(--black);
            }

            span {
              width: calc(100% - 60px);
              margin-top: auto;
            }
          }
        }
      }

      p,
      div,
      span {
        margin-bottom: 15px;
      }

      b,
      strong {
        font-weight: normal;
        font-family: var(--camptonsemibold);
      }
      .category{
        margin-right: 5px;
      }
      .visage{
        margin: 15px 0 5px -10px!important;
      }
    }
  }

  h2 {
    margin-bottom: 5px;
    text-transform: uppercase;
    line-height: 1;
    font-size: 16px;
    font-family: var(--camptonbook);
    font-weight: normal;
    color: rgba(44, 46, 53, 0.3);

    @media screen and(max-width: $mobile-bp) {
      display: none !important;
    }
  }

  choice-prices {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(44, 46, 53, 0.2);
    padding-top: 20px;

    .glasses {
      font-family: var(--camptonbook);
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 15px;
      b {
        font-family: var(--camptonsemibold);
      }
    }

    div {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 20px;
      }

      label {
        display: flex;
        align-items: center;
        font-family: var(--camptonbook);
        font-size: 16px;
        font-weight: normal;

        b,
        strong {
          font-family: var(--camptonsemibold);
          font-weight: normal;
        }

        :last-child {
          font-family: var(--camptonsemibold);
          font-weight: bold;
        }
      }
    }
  }

  title-wrapper {
    display: flex;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    align-items: baseline;
    font-family: var(--camptonsemibold);
    font-size: 25px;
    font-weight: normal;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px !important;
    margin-bottom: 20px !important;

    h1 {
      padding: 0;
      margin: 0;
      margin-right: 10px;
      font-size: 35px;
      text-transform: capitalize;

      @media screen and(max-width: $mobile-bp) {
        display: none;
      }
    }

    div {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      font-family: var(--camptonsemibold);
      font-size: 100%;
      font-weight: bold;
      white-space: nowrap;
      
      .padding-r1{
        padding-right: 1px;
      }

      del {
        padding-left: 10px;
        color: var(--red);
      }

      del + span {
        font-family: var(--camptonsemibold);
        padding-left: 5px;
      }
    }

    @media screen and(max-width: $mobile-bp) {
      div {
        display: none;
      }

      h1 {
        position: absolute; //mobile : h1 move to top
        top: 20px;
        text-align: left;
        padding: 0;
        margin-bottom: 5px;
        text-transform: uppercase;
        line-height: 1;
        font-size: 16px;
        font-family: var(--camptonbook);
        font-weight: 400;
        color: rgba(44, 46, 53, 0.3);
      }
    }
  }

  .wysiwyg {
    line-height: 1.4;
    position: relative;
    font-size: 16px;
    @media screen and(max-width: $mobile-bp) {
      order: -2;
    }
  }
  .see-more {
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
    button {
      font-size: 10px;
      padding: 2px 5px;
      border: 1px solid var(--black);
      background-color: var(--white);
      border-radius: 30px;
      -webkit-text-fill-color: var(--black);
    }
  }
  .hide-description {
    max-height: 4em;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
  }
  color-wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and(max-width: $mobile-bp) {
      order: -3;
      border-bottom: 0;
      margin-top: 0;
    }

    span {
      display: inline-block;
      margin: 5px 0 20px 0;
      font-family: var(--camptonbook);
      font-size: 14px;
      font-weight: normal;
    }

    .color-choice,
    color-choice {
      display: flex;
      flex-wrap: wrap;
      padding-left: 4px;
      padding-block: 10px;

      .colorContent,
      color-content {
        height: 24px;
        width: 24px;
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 50%;
        margin-right: 18px;
        // margin-bottom: 40px; 
        cursor: pointer;
        position: relative;

        &:last-of-type {
          margin-right: 0;
        }

        &.active {
          position: relative;

          &::before {
            content: "";
            border: 2px solid var(--black);
            border-radius: 100%;
            width: 36px;
            height: 36px;
            position: absolute;
            left: -6px;
            top: -6px;
          }
        }

        //colors for color-content are defined in _detail.scss, _listing.scss and src>Twig>AppExtension.php
        &.blue {
          background: #20417e;
        }

        &.black {
          background: var(--black);
        }

        &.red {
          background: #e30a1c;
        }

        &.grey {
          background: #9b9b9b;
        }

        &.yellow {
          background: var(--yellow);
        }

        &.green {
          background: var(--green);
        }

        &.white {
          background: var(--white);
        }
        &.brun, 
        &.brown {
          background: #681d03;
        }

        &.orange {
          background: #ff8403;
        }

        &.purple {
          background: #9869d4;
        }

        &.pink {
          background: #e39cbd;
        }
        &.bordeaux {
          background: var(--bordeaux);
        }
        &.multicolore {
          background: var(--multicolore);
        }
        &.transparent {
          background: var(--transparent);
        }

        &.tortoi {
          background-image: url(../../../images/tortoi.png);
          background-position: top;
        }

        span {
          position: absolute;
          bottom: -50px;
          white-space: nowrap;

          h2 {
            font-size: 14px;
            font-family: var(--camptonbook);
            color: var(--black);
            text-transform: none;
            display: inline !important;
            padding-left: 0px !important;
            margin-left: -4px;
          }
        }
      }
    }

    color-label {
      font-size: 14px;
      font-family: var(--camptonbook);
      color: var(--black);
      height: 30px;
    }
  }

  //sizes buttons
  .listing-sizes {
    display: flex;
    margin-bottom: 20px;

    li {
      display: flex;
      cursor: pointer;
      padding: 15px 30px;
      margin-right: 20px;
      text-transform: uppercase;
      font-family: var(--camptonmedium);
      font-size: 14px;
      border: 2px solid var(--black);
      color: var(--black);
    }

    li.active_tab {
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        background: url(/assets/images/icons/_icon_checkbox_white.svg),
          var(--black) no-repeat;
        background-size: auto;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
      }
    }
  }

  wrapper-buttons {
    display: flex;
    justify-content: space-between;

    @media screen and(max-width: $mobile-bp) {
      margin-bottom: 0;
    }

    .hometrial-comparator-button {
      display: flex;
    }

    .btn-hometrial {
      @media screen and(max-width: $mobile-bp) {
        margin-bottom: 20px !important;
      }
    }

    .btn {
      @media screen and(min-width: $large-thr) {
        flex: 1 0 auto;
      }

      @media screen and(max-width: $mobile-bp) {
        padding: 15px;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      &:first-of-type {
        @media screen and(max-width: $mobile-bp) {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        @media screen and(max-width: $mobile-bp) {
          padding: 15px 30px;
        }

        &.active {
          position: relative;
          border: 2px solid var(--black);
          color: var(--black);

          &:after {
            content: " ";
            position: absolute;
            top: -2px;
            right: -1px;
            background: url(/assets/images/icons/_icon_checkbox_white.svg),
              var(--black) no-repeat;
            background-size: auto;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .wrapper {
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

size-wrapper {
  display: flex;
  align-items: center;
  justify-self: center;
  position: relative;
  height: auto;
  margin: 50px auto 20px auto;
  padding: 30px 0;

  @media screen and(min-width: $desktop-bp) {
    height: 400px;
    margin: 40px auto 35px auto;
  }

  bridge-width,
  left-glasse,
  right-glasse,
  branch-glasse,
  global-glasses {
    position: absolute;
    display: inline-block;
    font-family: var(--camptonmedium);
    font-size: 14px;
    // text-transform: uppercase;
    line-height: 1;
  }

  bridge-width{
    top: 45%;
    left: 48%;
    @media screen and(min-width: $desktop-bp) {
      left: 45%;
    }
  }

  left-glasse {
    top: 34%;
    left: 22%;

    @media screen and(min-width: $desktop-bp) {
      left: 18%;
    }
  }

  right-glasse {
    top: 30%;
    right: 20%;

    @media screen and(min-width: $desktop-bp) {
      right: 16%;
    }
  }

  branch-glasse {
    left: 44%;
    bottom: 0;

    @media screen and(min-width: $desktop-bp) {
      left: 43%;
    }
  }

  global-glasses {
    top: 0;
    left: 44%;

    @media screen and(min-width: $desktop-bp) {
      left: 43%;
    }
  }

  img {
    height: 100%;
  }

  @media screen and(max-width: $mobile-bp) {
    display: none;
  }
}

size-wrapper-responsive {
  display: none;
  @media screen and(max-width: $mobile-bp) {
    display: flex;
    align-items: center;
    justify-self: center;
    position: relative;
    overflow: hidden;
    height: auto;
    margin-top: 30px;
    padding: 30px 25px;
  }
  
  bridge-width,
  left-glasse,
  right-glasse,
  branch-glasse,
  global-glasses {
    position: absolute;
    display: inline-block;
    font-family: var(--camptonmedium);
    font-size: 12px;
    // text-transform: uppercase;
    line-height: 1;
  }
  bridge-width{
    top: 45%;
    left: 45%;
  }

  left-glasse {
    top: 34%;
    left: 22%;
  }

  right-glasse {
    top: 30%;
    right: 19%;
  }

  branch-glasse {
    left: 45%;
    bottom: 0;
  }

  global-glasses {
    top: 0;
    left: 45%;
  }

  img {
    height: 100%;
  }
}

carousel-thumbnails {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 35px;

  .swiper-pagination {
    display: none;
    @media screen and(max-width: $mobile-bp) {
      display: block;
    }
  }

  .swiper-pagination .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    background-color: #b9bfc2;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0px;
  }

  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  > .btn,
  > .btn_small {
    margin: 0 auto;
    width: 300px;

    @media screen and(max-width: 374px) {
      width: auto;
    }
  }

  gallery-thumbnails {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 490px;

    @media screen and(max-width: $mobile-bp) {
      display: none;
    }

    // Swiper carousel with thumbs
    .swiper-button-lock {
      display: flex;
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 22px;
      position: absolute;
      height: 70px;
      width: 20px;
      background-color: var(--black);

      &:after {
        font-size: 18px;
        color: #fff;
      }

      &.swiper-button-disabled {
        background: #00000026;
        opacity: 1;
      }
    }
  }

  .gallery-thumbs {
    width: 450px;
    height: 70px;
    margin-bottom: 35px;

    .swiper-slide {
      border: 1px solid transparent;
      cursor: pointer;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.5);
      }

      &.swiper-slide-thumb-active {
        border: 2px solid #2c2e35;

        &:after {
          display: none;
        }
      }
    }
  }

  .gallery-top {
    position: relative;
    height: 400px;
    width: 100%;
    margin-bottom: 20px;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: var(--grey);

      &.swiper-pagination-bullet-active {
        background: var(--black);
      }
    }

    @media screen and(max-width: $mobile-bp) {
      padding-bottom: 40px;
      height: auto;
    }

    .swiper-slide {
      width: 100% !important;
      height: 100% !important;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      .product-tags {
        position: absolute;
        width: 100%;
        height: 44px;
      }

      card-desc {
        display: flex;
        padding: 15px 20px;
        margin-right: auto;
        font-family: var(--camptonmedium);
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        background: var(--beige);
        color: #fff;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;

        &:not(.icon_top) {
          margin-top: 30px;
          @media screen and(min-width: $desktop-bp) {
            margin-top: 0;
          }
        }

        @media screen and(max-width: $mobile-bp) {
          &:not(.icon_top) {
            height: 250px;
            margin-top: 50px;
          }
        }

        &.icon_top {
          object-fit: contain;
          width: 55px;
          height: auto;
        }
        &.web-price {
          object-fit: cover;
        }
      }
    }
  }
}

/*********************************/
/*			  Modal	    		 */
/*********************************/
modal-wrapper {
  overflow: auto;

  block-wrapper {
    width: 100%;
  }
}

modal-wrapper.modal_dialog {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;

  &:target {
    opacity: 1;
    pointer-events: auto;
  }

  card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: #fff;
  }

  .close {
    position: absolute;
    padding: 0;
    content: " ";
    background: url("/assets/images/icons/_icon_close_filter_white.svg")
      no-repeat;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    right: 0px;
    top: 15px;
    cursor: pointer;
    transform: rotate(0);
    transition: transform 0.2s ease-out;
    opacity: 1;

    &:hover {
      right: 0px;
      top: 15px;
      transform: rotate(180deg);
    }

    @media (max-width: $mobile-bp) {
      top: 5px;
      background-size: 45px 45px;
      width: 45px;
      height: 45px;

      &:hover {
        top: 5px;
      }
    }
  }

  wrapper-title, .wrapper-title{
    display: flex;
    flex-direction: column;
    background: var(--black);

    block-title, .block-title {
      display: flex;
      flex-direction: column;
      padding: 25px 15px;
      width: 100%;
      font-size: 22px;
      font-family: var(--camptonbook);
      font-weight: normal;
      line-height: 1.2;
      color: #fff;

      h2 {
        font-size: 28px;
        font-family: var(--camptonsemibold);
        font-weight: normal;
        line-height: 1.5;
        text-transform: uppercase;
        color: #fff;

        @media (max-width: $mobile-bp) {
          font-size: 22px;
        }
      }

      @media (max-width: $mobile-bp) {
        span {
          display: none;
        }
      }
    }
  }

  block-wrapper {
    p {
      font-size: 18px;
      font-family: var(--camptonbook);
      font-weight: normal;
      line-height: 1.4;
      padding: 30px 0;

      @media (max-width: $mobile-bp) {
        padding-bottom: 0;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        position: relative;
        width: calc((100% / 3) - 20px);
        height: 300px;
        margin-right: 30px;
        margin-bottom: 30px;

        width: calc((100% / 2) - 15px);
        &:nth-child(even) {
          margin-right: 0px;
        }

        &:nth-child(3n + 3) {
          margin-right: 30px;
        }

        &:last-of-type {
          margin-right: 0;
        }

        @media screen and (min-width: $desktop-bp) {
          &:nth-child(even) {
            margin-right: inherit;
          }

          &:nth-child(3n + 3) {
            margin-right: inherit;
          }

          &:last-of-type {
            margin-right: inherit;
          }
        }

        a {
          height: 100%;
          position: absolute;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(44, 44, 43, 0.2);
          }
        }

        @media (max-width: $mobile-bp) {
          height: 200px;
        }

        @media (max-width: $mobile-bp) {
          width: 100%;
          margin-right: 0 !important;
          margin-bottom: 20px !important;
          align-items: center;
          display: flex;
          justify-content: center;

          &:nth-child(3n + 3) {
            margin-right: 0px !important;
          }
        }

        &:nth-child(3n + 3) {
          margin-right: 0;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        span {
          z-index: 10;
          position: absolute;
          bottom: 30px;
          left: 30px;
          font-family: var(--camptonsemibold);
          font-size: 22px;
          font-weight: normal;
          line-height: 1.2;
          color: #fff;
        }
      }
    }
  }
}

modal-wrapper.modal_basic {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(55, 71, 79, 0.65);
  z-index: 9999999;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;

  &:target {
    opacity: 1;
    pointer-events: auto;
  }

  block-title, .block-title {
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
    font-size: 28px;
    font-weight: normal;
    font-family: var(--camptonsemibold);
    line-height: 1.2;

    @media screen and(max-width: $mobile-bp) {
      margin-top: 25px;
    }

    .yellow {
      color: var(--yellow);
    }
  }

  card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 60%;
    margin: 200px auto 0 auto;
    padding: 70px;
    background: #fff;

    @media screen and(max-width: 1199px) {
      width: 80%;
      padding: 35px;
    }

    @media screen and(max-width: $mobile-bp) {
      padding: 20px;
      margin-top: 50px;
    }

    @media screen and(max-width: 479px) {
      width: 100%;
    }

    buttons-wrapper {
      display: flex;
      justify-content: space-between;

      div {
        @media screen and(max-width: $mobile-bp) {
          margin-left: 0;
        }

        margin-left: 20px;
        @media screen and(min-width: $large-thr) {
          margin-left: 0;
        }
      }

      @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
        margin-top: 15px;

        div {
          margin-top: 20px;
        }

        button {
          margin: 0 auto;

          &:last-of-type {
            margin-top: 15px !important;
          }
        }
      }
    }

    buttons-wrapper-centered {
      display: flex;
      justify-content: center;

      @media screen and(max-width: $mobile-bp) {
        flex-direction: column;

        button {
          margin: 0 auto;

          &:last-of-type {
            margin-top: 15px !important;
          }
        }
      }
    }
  }

  .wysiwyg {
    display: block;
    margin-bottom: 50px;
    line-height: 1.4;
    font-size: 18px;
    font-family: var(--camptonbook);
    font-weight: normal;
    text-align: center;

    b,
    strong {
      font-weight: normal;
      font-family: var(--camptonsemibold);
      font-size: 18px;
    }
  }

  .close {
    position: absolute;
    padding: 0;
    content: " ";
    background: url("/assets/images/icons/_icon_close_filter_white.svg") #2c2e35
      no-repeat;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    right: 0;
    top: 0;
    cursor: pointer;
    opacity: 1;

    @media (max-width: $mobile-bp) {
      background-size: 45px 45px;
      width: 45px;
      height: 45px;
    }
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  color: #fff;
  text-align: center;
  padding: 10px 10px;
  border-radius: 6px;
  background-color: #555;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  line-height: 1.2;
}

.tooltip-top {
  bottom: 110%;
  left: 50%;
  margin-left: -100px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.hide {
  display: none !important;
}

.display_modal {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.hide-color-label{
  display: none;
}

.price-p-absolute{
  position: absolute;
  bottom: -20px;
}

#FrameImages {
  visibility: hidden;
}

@media screen and(min-width: $desktop-bp) {
  // modal image gallery
  #FrameImages {
    visibility: visible;

  .product-tags {
      position: absolute;
      width: 100%;
      height: 44px;
    }
    card-desc {
        display: flex;
        padding: 15px 20px;
        margin-right: auto;
        font-family: var(--camptonmedium);
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        background: var(--beige);
        color: #fff;
    }
    
    block-wrap, 
    .swiper-modal,
    .swiper-pagination-modal,
    .swiper-wrapper {
      margin: 0;
    }
    >card-container{
      margin: auto;
      position: absolute;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 100vh;
      img:not(.icon_top){
          height: 100%;
          object-fit: contain;
          width: auto;
          max-height: 80vh;
      }
    }

    .swiper-modal{
      img{
        cursor:grab;
      }
    }
    
    .swiper-slide{
      margin-bottom: 0;
      width: 100%;
    }
    .swiper-frame-images{
      margin:0;
    }
    .swiper-button-lock {
      display: flex;
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        height: 80px;
        width: 30px;
        background-color: #00000026;

        &:after {
            font-size: 18px;
            color: #fff;
        }
        &:hover{
          background-color: var(--black);
        }

        &.swiper-button-disabled {
            background: #00000026;
            opacity: 0;
        }
    }
    .swiper-pagination-modal{
      position: absolute;
      top: 15px;
    }
    .swiper-pagination-modal,
    .swiper-pagination-modal span 
    {
      font-family:  var(--camptonsemibold);
    }

    .close{
      z-index: 2;
      background-color: #00000026;
      &:hover{
          background-color: var(--black);
          cursor: pointer;
        }
    }
  }
  .img-zoom-lens {
    // border: 1px solid red; //to see the zoom area
    position: absolute;
    width: 400px;
    height: 330px;
  }
  #img-zoom-result {
    position: absolute;
    z-index:0;
    top:0;
    left:0;
    width: 100%;
    height: 400px;
  }
  .zoom{
    cursor: zoom-in;
  }
  .modal-hidden {
    overflow: hidden;
  }
}