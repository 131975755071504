/***********************************
  _ )  |  | __ __| __ __| _ \   \ |   __|
  _ \  |  |    |      |  (   | .  | \__ \
 ___/ \__/    _|     _| \___/ _|\_| ____/

***********************************/

@import "assets/styles/scss/config/globals";

.btn,
.btn_small {
	min-height: 50px;
	height: auto;
	display: inline-block;
	padding: 15px 30px;
	margin-bottom: 0;
	border: 1px solid var(--black);
	color: var(--black) !important;
	background: transparent;
	font-family: var(--camptonmedium);
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	text-decoration: none !important;
	line-height: 1.2;
	text-align: center;
	cursor: pointer;
	user-select: none;
	transition: all 0.5s ease-in-out;
	appearance: none;
	position: relative;

	&:hover,
	&:focus {
		background: var(--black);
		border: 1px solid var(--black);
		color: #fff !important;
	}

	&.is_disabled {
		pointer-events: none;
		background: rgba(44, 46, 53, .3) !important;
		color: #fff !important;
		border: none !important;

		&:hover,
		&:focus {
			background: rgba(44, 46, 53, .3) !important;
			color: #fff !important;
			border: none !important;
		}
	}
}

.btn_2,
.btn_small_2 {
	min-height: 50px;
	height: auto;
	display: inline-block;
	padding: 15px 30px;
	margin-bottom: 0;
	border: 1px solid #fff;
	color: #fff !important;
	background: transparent;
	font-family: var(--camptonmedium);
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	text-decoration: none !important;
	line-height: 1.2;
	text-align: center;
	cursor: pointer;
	user-select: none;
	transition: all 0.5s ease-in-out;
	appearance: none;
	position: relative;

	&.is_disabled {
		pointer-events: none;
		background: rgba(44, 46, 53, .3) !important;
		color: #fff !important;
		border: none !important;

		&:hover,
		&:focus {
			background: rgba(44, 46, 53, .3) !important;
			color: #fff !important;
			border: none !important;
		}
	}

	&:hover,
	&:focus {
		background: #fff;
		border: 1px solid #fff;
		color: var(--black) !important;
	}
}

.btn_3,
.btn_small_3 {
	min-height: 50px;
	height: auto;
	display: inline-block;
	padding: 15px 30px;
	margin-bottom: 0;
	border: 1px solid var(--black);
	color: #fff !important;
	background: var(--black);
	font-family: var(--camptonmedium);
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	text-decoration: none !important;
	line-height: 1.2;
	text-align: center;
	cursor: pointer;
	user-select: none;
	transition: all 0.5s ease-in-out;
	appearance: none;
	position: relative;

	&.is_disabled {
		pointer-events: none;
		background: rgba(44, 46, 53, .3) !important;
		color: #fff !important;
		border: none !important;

		&:hover,
		&:focus {
			background: rgba(44, 46, 53, .3) !important;
			color: #fff !important;
			border: none !important;
		}
	}

	&:hover,
	&:focus {
		background: #fff;
		border: 1px solid var(--black);
		color: var(--black) !important;
	}
}

.btn_4,
.btn_small_4 {
	min-height: 50px;
	height: auto;
	display: inline-block;
	padding: 15px 30px;
	margin-bottom: 0;
	border: 1px solid var(--black);
	color: var(--black) !important;
	background: #fff;
	font-family: var(--camptonmedium);
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	text-decoration: none !important;
	line-height: 1.2;
	text-align: center;
	cursor: pointer;
	user-select: none;
	transition: all 0.5s ease-in-out;
	appearance: none;
	position: relative;

	&.is_disabled {
		pointer-events: none;
		background: rgba(44, 46, 53, .3) !important;
		color: #fff !important;
		border: none !important;

		&:hover,
		&:focus {
			background: rgba(44, 46, 53, .3) !important;
			color: #fff !important;
			border: none !important;
		}
	}

	&:hover,
	&:focus {
		background: var(--black);
		border: 1px solid #fff;
		color: #fff !important;
	}
}

.btn_5,
.btn_small_5 {
	min-height: 50px;
	height: auto;
	display: inline-block;
	padding: 15px 30px;
	margin-bottom: 0;
	border: 1px solid transparent;
	color: var(--black) !important;
	background: var(--grey_light);
	font-family: var(--camptonmedium);
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	text-decoration: none !important;
	line-height: 1.2;
	text-align: center;
	cursor: pointer;
	user-select: none;
	transition: all 0.5s ease-in-out;
	appearance: none;
	position: relative;

	&.is_disabled {
		pointer-events: none;
		background: rgba(44, 46, 53, .3) !important;
		color: #fff !important;
		border: none !important;

		&:hover,
		&:focus {
			background: rgba(44, 46, 53, .3) !important;
			color: #fff !important;
			border: none !important;
		}
	}

	&:hover,
	&:focus {
		background: var(--black);
		border: 1px solid transparent;
		color: var(--grey_light) !important;
	}
}

.btn_small,
.btn_small_2,
.btn_small_3,
.btn_small_4,
.btn_small_5 {
	padding: 10px 30px;
	min-height: 30px !important;

	&.is_disabled {
		pointer-events: none;
		background: rgba(44, 46, 53, .3) !important;
		color: #fff !important;
		border: none !important;

		&:hover,
		&:focus {
			background: rgba(44, 46, 53, .3) !important;
			color: #fff !important;
			border: none !important;
		}
	}
}

// loader / spinner
.loader {
	width: 22px;
	height: 22px;
	border: 2px solid var(--black);
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.white-loader {
	width: 22px;
	height: 22px;
	border: 2px solid var(--white);
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader-wrapper {
	padding: 13px 30px;
}

.lens-selector-loader {
	width: 210px;
	@media screen and(max-width: $mobile-bp) {
		width: auto;
	}
}