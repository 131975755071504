@import "assets/styles/scss/config/globals";

.quote-link {
    cursor: default;
    display: contents;
    a{
        cursor: pointer;
        justify-content: left;
        text-decoration: underline;
        font-weight: normal;
        margin:0;
        
        &:hover {
            font-weight: bold;
        }
    }
}

.form-group{
    li.active a .text{
        color: #fff;
    }
    .text{
        color: var(--black);
    }
}

#quote_container{
    width: 50%;
    margin-top: 110px;
    @media screen and(max-width: $large-thr) {
        width: 75%;
        padding: 35px;
    }
    @media screen and(max-width: $mobile-bp) {
        width: 100%;
        padding: 20px;
        margin-top: 50px;
    }

    buttons-wrapper {
        display: flex;
        justify-content: space-between;

        @media screen and(max-width: $mobile-bp) {
            flex-direction: row;
        }
    }
}

.quote-block {
    display: block;
    margin-bottom: 50px;
    line-height: 1.4;
    font-size: 18px;
    font-family: var(--camptonbook);
    font-weight: normal;
    text-align: center;
    
    .subtitle-step{
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 10px;
        line-height: 1.2;
        font-size: 22px;
        font-family: var(--camptonsemibold);
        font-weight: normal;
    }

    form .form-group{
        margin-bottom: 15px;
        line-height: 1.4;
        font-size: 18px;
        font-family: var(--camptonbook);
        font-weight: normal;
    }

    global-four {
        display: block;
        margin-top: 20px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;

        h3 {
            margin-bottom: 15px;
            font-family: var(--camptonmedium);
            font-size: 16px;
            font-weight: normal;
            line-height: 1.2;
        }

        wrapper-four {
            display: flex;

            @media screen and(max-width: $mobile-bp) {
                flex-wrap: wrap;
            }

            label {
                line-height: 1.2;
                font-size: 14px !important;
                white-space: nowrap;
            }

            >div {
                width: calc(100% / 4);
                margin-right: 30px;
                margin-bottom: 30px !important;

                @media screen and(max-width: $mobile-bp) {
                    width: calc(50% - 5px);
                    margin-right: 10px;
                    margin-bottom: 20px !important;

                    &:nth-child(even) {
                        margin-right: 0;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    card-wrapper{
        width: 100%;
        .form-column{
            label{
                margin-right:20px;
            }
            .filter-option-inner-inner {
            font-size: 18px;
            text-transform: none;
            font-family: var(--camptonbook);
            }

        }
    }

    .block-add-beneficiary, 
    .block-add-doctor{
        padding-top: 20px;
        width: 100%;
    }
}