.phone-input-container {
    position: relative;
}

.phone-input-wrapper {
    display: flex;
    align-items: center;
}

.iti {
    width: 100%;
}

.iti__flag-container {
    z-index: 2;
}

.iti__search-input {
    width: 100% !important;
}

.iti__country-container{
    height: 50px;
}

/* Hide the original country code fields since we're using intl-tel-input */
.phone-country-code {
    display: none;
}
