@import "assets/styles/scss/config/globals";

greeting-message {
    grid-column: 2 / span 3;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    font-size: 22px;
    background-color: #f6f6f6;
    width: 100%;
    order: -1;

    .admin_name {
        line-height: 1.2;
        font-family: var(--camptonbook);
        font-size: 22px;
        font-weight: normal;
        
        strong {
            font-family: var(--camptonsemibold);
            font-weight: normal;
        }
    }
    
    p {
        margin-bottom: 0 !important;
        line-height: 1.5;
        font-family: var(--camptonbook);
        font-size: 18px;
        color: #ABABAE;
    }

    @media screen and(min-width: $desktop-bp) {
        order: 0;

        .admin_name {
            font-size: 28px;
        }
        
        p {
            font-size: 22px;
        }
    }

    @media screen and(max-width: 468px) {
        width: 100%;
    }
}

content-aside {
    flex-direction: column;

    greeting-message {
        display: none;

        @media screen and(min-width: $desktop-bp) {
            display: flex;
        }
    }
}

client-dashboard-wrapper > greeting-message {
    @media screen and(min-width: $desktop-bp) {
        display: none;
    }
}

