@import "assets/styles/scss/config/globals";

.mes-commandes-wrapper {
  width: 100%;
  padding: 0;

  .mes-commandes-holder {
    display: flex;
    flex-direction: column;
  }

  .nav-tabs {
    display: flex;
    width: 100%;
    position: relative;

    .tabs_responsive {
      display: none;

      @media screen and(max-width: $mobile-bp) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        position: relative;
        height: auto;
        min-height: 50px;
        width: 100%;
        text-transform: uppercase;
        padding: 15px 25px 15px 5px;
        margin-bottom: 20px;
        border: 1px solid var(--black);
        text-align: left;
        font-size: 16px;
        font-family: var(--camptonmedium);
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          top: 10%;
          right: 0;
          background: url(/assets/images/icons/_icon_filtre_chevron.svg) no-repeat;
          background-size: auto;
          background-size: 40px 40px;
          width: 40px;
          height: 40px;
          -webkit-transform: rotate(0);
          transform: rotate(0);
          -webkit-transition: all 0.25s ease-in-out;
          transition: all 0.25s ease-in-out;
        }

        &.open {
          &::after {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
    }

    ul.tabs {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 0 25px 0;
      border-bottom: 0.7px solid rgba(44, 46, 53, 0.1);
      pointer-events: unset !important;
      opacity: 1 !important;
      visibility: unset !important;
      width: 100%;

      @media screen and(max-width: $mobile-bp) {
        border: 1px solid var(--black);
        border-top: 0;
        position: absolute;
        top: 52px;
        left: 0;
        right: 15px;
        z-index: 100;
        background: #f6f6f6;
        height: 0;
        overflow: hidden;

        &.open {
          height: auto;
        }
      }

      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        min-height: 50px;
        height: auto;
        margin: 0 20px;
        border-bottom: 4px solid transparent;
        font-size: 16px;
        font-family: var(--camptonbook);
        font-weight: 400;
        text-transform: uppercase;
        color: rgba(44, 46, 53, 0.4);

        &:first-of-type {
          margin-left: 0;
        }

        &.active_tab {
          border-bottom: 4px solid var(--black);
          font-family: var(--camptonmedium);
          color: var(--black);
        }

        @media screen and(max-width: $large-thr) {
          font-size: 15px;
          margin: 0 11px;
        }

        @media screen and(max-width: $mobile-bp) {
          width: 100%;
          padding: 5px;
          margin: 0;
          font-size: 16px;
          border-bottom: 1px solid var(--black) !important;
          color: var(--black);
        }
      }
    }
  }

  .commandes-list {
    // background-color: #fff;
    display: flex;
    padding: 15px 0;
    box-sizing: border-box;
    flex-direction: column;

    .orders-block {
      display: none;
      flex-direction: column;
    }

    .commandes {
      background: white;
      width: 100%;
      padding: 0 30px;
      margin: 10px 0;

      @media screen and(max-width: 468px) {
        padding: 0 10px;
      }
    }

    .commande-teaser {
      // padding: 20px 0px;
      display: flex;
      color: #2c2e35;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0px;
      }

      .details-short-content {
        // width: 90%;
        // display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        padding: 15px 15px 10px 0;

        @media screen and(max-width: 468px) {
          width: 83%;
          font-size: 16px;
        }

        .date-status {
          width: 100%;

          @media screen and(max-width: $mobile-bp) {
            display: flex;
            flex-direction: column;
          }

          .date {
            white-space: nowrap;
            margin-right: 5px;
            font-family: var(--camptonsemibold);

            @media screen and(max-width: $mobile-bp) {
              margin-bottom: 10px;
            }
          }

          .dash {
            @media screen and(max-width: $mobile-bp) {
              display: none;
            }
          }

          .waiting-color {
            color: #fdc52d;
            font-family: var(--camptonsemibold);
          }

          .deliverd-color {
            color: #078b47;
            font-family: var(--camptonsemibold);
          }

          .workflow-place {
            flex-grow: 1;
            font-weight: bold;
            margin-bottom: 10px;
            white-space: nowrap;
            line-height: normal;
            @media screen and(max-width: $mobile-bp) {
              white-space: break-spaces;
            }
          }

          .created {
            color: #a0a0a0;
          }

          .awaiting_payment {
            color: #b0c4de;
          }

          .in_verification {
            color: #add8e6;
          }

          .awaiting_preparation {
            color: #87ceeb;
          }

          .in_preparation {
            color: #6495ed;
          }

          .delivery_in_progress {
            color: #00bfff;
          }

          .completed {
            color: #0cd100;
          }

          .cancelled {
            color: #ff7d7a;
          }

          .trial_in_progress {
            color: #00bfff;
          }
        }

        .info-short {
          font-size: 17px;
          margin-top: 12px;
          display: flex;
          justify-content: flex-start;
          width: 100%;

          @media screen and(max-width: $mobile-bp) {
            flex-direction: column;

            &>* {
              margin-bottom: 10px;
            }
          }

          @media screen and(max-width: 468px) {
            font-size: 16px;
          }

          .no-commande,
          .quantity,
          .total {
            white-space: nowrap;
          }

          .no-commande,
          .quantity {
            margin-right: 5px;
          }

          .quantity {
            width: 100px;
          }

          .no-commande,
          .total {
            width: 150px;
          }
        }
      }

      .payment-button {

        // margin-left: 10px;
        button {
          min-height: 35px;
          padding: 10px 20px
        }

        @media screen and(max-width: $mobile-bp) {
          margin-left: inherit;
        }
      }

      .facture-btn {
        width: 15%;
        height: 0;
        display: flex;
        justify-content: center;
        padding: 10px;
        align-items: center;

        @media screen and(max-width: $mobile-bp) {
          width: 200px;
        }
      }

      .see-more {
        padding-left: 20px;
        position: relative;
        margin-top: -18px;

        @media screen and(max-width: $mobile-bp) {
          position: absolute;
          right: 25px;
          margin-top: 10px;
        }

        .plus,
        .minus {
          white-space: nowrap;
          font-size: 14px;
          text-decoration: underline;
        }

        .minus {
          display: none;
        }

        .plus:after,
        .minus:after {
          display: block;
          right: 15px;
          top: 12px;
          position: absolute;
          content: " ";
          background: url("/assets/images/icons/_icon_filtre_chevron.svg") no-repeat;
          background-size: 30px 30px;
          width: 30px;
          height: 30px;
        }

        .minus:after {
          right: 18px;
          transform: rotate(180deg);
        }

        .plus:hover,
        .minus:hover {
          font-weight: bold;
        }

        .plus:hover:after {
          transform: scale(1.1);
          transition: transform 0.25s ease-out;
        }

        .minus:hover:after {
          transform: rotate(180deg) scale(1.1);
          transition: transform 0.25s ease-out;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .invoice-request {
      margin-bottom: 10px;

      a {
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }

      @media screen and (max-width: $mobile-bp) {
        margin-bottom: 20px;
      }

      .disabled-color {
        color: var(--silver);
      }

      .message {
        margin-top: 10px;
        font-size: 15px;
        color: steelblue;
      }
    }

    .no-displayable-orders {
      text-align: center;
      font-size: 24px;
      height: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hometrials-block {
      flex-direction: column;
    }
  }

  emails {
    width: 100%;

    div {
      font-size: 17px;
    }

    li {
      border-bottom: 1px solid var(--silver);
      margin-top: 3px;
      font-size: 15px;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .email-title {
      line-height: 1.5;
    }

    .envelope-link {
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        font-weight: bold;
      }
    }

    .envelope-icon {
      margin-bottom: 7px;
    }

    .emails-txt-grey {
      color: var(--grey);
      font-size: 14px;
      margin-block: 20px;
    }

  }

  .commande-detail {
    display: flex;
    // margin-top: 60px;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    flex-basis: content;
    height: 0; // remove commant once correction finished

    .detail-commande-title {
      font-family: var(--camptonsemibold);
      width: 100%;
    }

    detail-commande-essdom {
      display: flex;
      justify-content: space-between;
      width: 100%;

      detail-commande-essdom-title {
        font-family: var(--camptonsemibold);
      }

      detail-commande-essdom-amount {
        span {
          font-family: var(--camptonsemibold);
          white-space: nowrap;
        }
      }
    }

    .detail-card {
      border: 0.5px solid rgba(44, 46, 53, 0.1);
      min-height: 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 25px 30px;
      margin-top: 20px;

      @media screen and(max-width: 468px) {
        padding: 25px 15px;
      }

      .recommandation {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-transform: uppercase;
        font-size: 14px;
        color: #1e1e1e;
        font-family: var(--camptonmedium);

        img {
          height: 30px;
          width: 30px;
        }
      }

      .order-product-detail-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and(max-width: $small-thr) {
          flex-direction: column;
          align-items: center;
        }

        .product-image {
          display: flex;
          max-width: 150px;
          padding: 15px 0 20px 0;
        }

        .order-product-detail-description {
          display: flex;
          width: 100%;
          justify-content: space-between;
          flex-direction: column;

          .hometrial {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media screen and(max-width: $mobile-bp) {
              flex-direction: column;
            }

            .thumbnail-wrapper {
              cursor: pointer;
              width: 30%;

              @media screen and(max-width: $mobile-bp) {
                margin-bottom: 10px;
                width: 100%;
              }
            }

            .frame-description {
              display: flex;
              flex-direction: column;
              flex-grow: 1;

              @media screen and(max-width: $mobile-bp) {
                margin-bottom: 20px;
              }
            }

            .frame-choice-button {
              width: 20%;

              @media screen and(max-width: $mobile-bp) {
                width: 100%;
              }
            }

            .order-title {
              width: 100%;
              color: #1e1e1e;
              font-family: var(--camptonmedium);
              font-size: 18px;
              display: flex;
              justify-content: flex-start;

              @media screen and(max-width: $mobile-bp) {
                justify-content: center;
              }
            }

            .order-detail {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              font-size: 16px;

              @media screen and(min-width: $desktop-bp) {
                font-size: 18px;
              }

              .item {
                color: #76777c;

                @media screen and(max-width: $small-thr) {
                  width: 75%;
                }
              }

              .price {
                color: #2c2e35;
                min-width: 60px;
                text-align: right;
                white-space: nowrap;
              }
            }

            img {
              vertical-align: middle;
              height: auto;
              max-width: 90%;

              @media screen and(max-width: $mobile-bp) {
                max-width: 100%;
              }
            }
          }

          .order {
            display: flex;
            flex-wrap: wrap;

            @media screen and(max-width: $mobile-bp) {
              flex-direction: column;
            }

            .order-image {
              width: 50%;
              padding: 0 50px 0 15px;

              @media screen and(max-width: $mobile-bp) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 15px 0;
              }

              img {
                width: 275px;
                object-fit: contain;
              }
            }

            .order-block {
              width: 50%;

              .order-title {
                width: 100%;
                color: #1e1e1e;
                font-family: var(--camptonmedium);
                font-size: 18px;
              }

              .order-detail {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 16px;

                @media screen and(min-width: $desktop-bp) {
                  font-size: 18px;
                }

                .item {
                  color: #76777c;

                  @media screen and(max-width: $small-thr) {
                    width: 75%;
                  }
                }

                .price {
                  color: #2c2e35;
                  min-width: 60px;
                  text-align: right;
                  white-space: nowrap;
                }
              }
            }

            img {
              vertical-align: middle;
              max-width: 90%;
              height: auto;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }

    .total-blocks {
      display: flex;
      width: 90%;
      margin-top: 30px;
      flex-wrap: wrap;

      @media screen and(max-width: 468px) {
        font-size: 16px;
        width: 100%;
      }

      .sub-total,
      .delivery-charge {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-family: var(--camptonbook);
      }

      .delivery-charge {
        margin-top: 10px;
      }

      .total {
        // border-top: 1px solid rgba(44, 46, 53, 0.1);
        // padding-top: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        // margin-top: 10px;
        .price {
          font-family: var(--camptonsemibold);
          white-space: nowrap;
        }
      }
    }

    .prescription-block {
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      // width: 100%;
      .prescription-title {
        font-family: var(--camptonsemibold);
        margin-right: 5px;
      }

      .prescription-card {
        border: 1px solid rgba(44, 46, 53, 0.1);
        padding: 25px 40px;
        min-height: 50px;
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media screen and(max-width: 468px) {
          padding: 25px 15px;
        }

        .file-detail {
          display: flex;
          width: 80%;
          align-items: center;

          @media screen and(max-width: 468px) {
            width: 95%;
            font-size: 16px;
          }

          .icon-pdf {
            margin-right: 15px;
          }
        }

        .prescription-teaser {
          display: flex;
          justify-content: space-between;
        }

        .prescription-detail {
          display: flex;
          margin-top: 20px;
          // border: 0.5px solid #2c2e3512;
          width: 100%;
          border-collapse: collapse;
          justify-content: center;
        }

        .prescription-detail th,
        .prescription-detail td {
          border: 1px solid #ccc;
          padding: 10px;
          text-align: center;
        }

        .prescription-detail th {
          background-color: #f2f2f2;
        }

        .prescription-detail td:first-child {
          text-transform: uppercase;
          font-weight: bold;
        }

        @media screen and (max-width: $mobile-bp) {
          .prescription-detail {
            border-collapse: separate;
            border-spacing: 0 20px;
          }

          .prescription-detail th,
          .prescription-detail td {
            display: block;
            text-align: left;
          }

          .prescription-detail th {
            display: none;
          }

          .prescription-detail td:first-child {
            background-color: #f2f2f2;
            display: block;
          }
        }

        .image-wrapper {
          position: relative;
          height: fit-content;
        }
        .download-label-link {
          font-size: 16px;
        }
        .linktofile {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          background-color: transparent;
          border-radius: 5px;
          opacity: 0;
        }

        .prescription-download {
          display:flex;
          cursor: pointer;
          icon-download {
            img {
              max-width: 80%;
            }
          }

          .download-label {
            display: block;
            
            @media screen and(max-width: 468px) {
              display: none;
            }
          }

          &:hover {
            font-weight: bold;
          }
        }
        
      }
    }
  }

  .delivery-option-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;

    @media screen and(max-width: $mobile-bp) {
      font-size: 16px;
    }

    @media screen and(max-width: 468px) {
      display: inline-block;
    }

    .delivery-option-title {
      font-family: var(--camptonsemibold);
      margin-right: 5px;
    }
  }

  .delivery-adresses {
    display: flex;
    margin-top: 20px;
    width: 100%;
  }

  .delivery-adresse-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and(max-width: 468px) {
      font-size: 16px;
    }

    .delivery-adresse-title {
      // font-family: var(--camptonsemibold);
      margin-bottom: 13px;
    }

    .delivery-adresse {
      display: flex;
      line-height: 25px;
      margin-bottom: 45px;
    }
  }

  .detail-commande-title {
    margin-top: 20px;
  }

  .commande-status-timeline {
    padding-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;

    .time-line-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;

      .time-line-continue-line {
        height: calc(90% - 35px);
        border-left: 1px solid #000;
        position: absolute;
        left: 17px;
        top: 38px;
        z-index: 0;
      }

      &:last-child {
        .time-line-continue-line {
          display: none;
        }
      }

      .time-line-icon-check {
        border: 1px solid #2c2e35;
        display: flex;
        border-radius: 100%;
        align-items: center;
        height: 35px;
        width: 35px;
        justify-content: center;
        position: relative;
        background: #000;

        img {
          width: 15px;
          height: 30px;
        }

        &.last-icon {
          background: #fff;
        }
      }

      .time-line-icon_sablier {
        border: 1px solid #2c2e35;
        display: flex;
        border-radius: 100%;
        align-items: center;
        height: 35px;
        width: 35px;
        justify-content: center;
        position: relative;

        img {
          width: 15px;
          height: 30px;
        }

        &.last-icon {
          background: #fff;
        }
      }
    }

    .time-line-event {
      display: flex;
      justify-content: space-between;
      line-height: 25px;
      margin-bottom: 25px;
      flex-direction: column;
      width: 90%;

      @media screen and(max-width: 468px) {
        width: 80%;
      }
    }

    .time-line-text {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      line-height: 1;

      @media screen and(max-width: 468px) {
        font-size: 16px;
        margin-bottom: 7px;
      }
    }

    .event-description {
      color: #76777c;
      font-size: 16px;
      width: 100%;

      @media screen and(max-width: 468px) {
        font-size: 14px;
        line-height: 1;
      }
    }

    .time-line-date {
      display: flex;
      align-items: center;
    }
  }

  .see-more-orders {
    padding: 20px 55px;
    display: flex;
    width: auto;
    justify-content: center;
    margin: 50px auto 0 auto;
  }
}

#emailModal {
  >card-container {
    padding: 50px;
    margin: auto;
    position: absolute;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 100%;

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      padding: 0;
    }
  }

  .close {
    background-color: #00000026;

    &:hover {
      background-color: var(--black);
      cursor: pointer;
    }
  }
}