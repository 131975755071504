@import "assets/styles/scss/config/globals";

@media screen and(max-width: $mobile-bp){

    .bbgr-info{
    margin: 2rem 0;
    text-align: start !important;
    }

    .bbgr-image {
    width: auto;
    height: 65px !important;
    }
}

.bbgr-info{
    display: flex;
    justify-content: end;
    text-align: justify;
    gap: 15px;

    p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    line-height: 1;
    color: rgba(44, 46, 53, 0.65);
    font-size: 16px;
    }

    .bbgr-image {
    width: auto;
    height: 80px;
    }
}
