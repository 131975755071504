@import "assets/styles/scss/config/globals";

$small-local-thr: 468px;
$large-local-thr: 1280px;

.client-dashboard
{
    display: flex;
    width: 100%;
    min-height: 70vh;
    background: #F6F6F6;
    flex-wrap: wrap;
    flex-direction: column;

    h1
    {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: none;
        font-family: var(--camptonsemibold);
        text-align: left;
        padding-left: 0;

        .prev-arrow
        {
            display: flex;
            transform: rotate(90deg);
            @media screen and(min-width: $desktop-bp) 
            {
                display: none;
            }
        }

        // Another exception here because of the creation of a hover effect
        // Note: this h1 tag doesn't seem to be used at all at the moment
        @media screen and(max-width: $desktop-bp) 
        {
            padding-left: 0;
            &:hover
            {
                cursor: pointer;
            }
        }

        @media screen and(max-width: $mobile-bp) 
        {
            font-size: 22px !important;
        }
    }

    breadcrumb-block {
        display: none;
        margin-top: 20px;
        margin-bottom: 0 !important;
        width: 100%;

        @media screen and(min-width: $desktop-bp) {
            display: flex;
        }
    }

    .client-dashboard-wrapper
    {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
        height: 100%;
        padding-bottom: 80px;

        @media screen and(min-width: $desktop-bp) 
        {
            justify-content: space-between;
            margin-top: 35px;
        }
    }
    .menu-aside {
        width: 100%;
        transform:translate(9999px);
        opacity: 0;
        display: none; 
        flex-direction: column;
        height: 100%;

        @media screen and(min-width: $desktop-bp) {
            width: calc(100% / 3);
            transform: none;
            opacity: 1;
            display: flex;
        }

        &.show
        {
            transition: all 300ms linear;
            @media screen and(min-width: $desktop-bp) {
                transition: none;
            }
            
            transform:translate(0);
            opacity: 1;
            display: flex;
        }

        @media screen and(min-width: $desktop-bp) {
            padding-right: 20px;
        }
        
        @media screen and(min-width: $large-local-thr) {
            padding-right: 55px;
        }

        @media screen and(max-width: $small-local-thr) {
            width: 100%;
        }

        > a,
        > li {
            background: #fff;
        }
        
        a {
            display: flex;
            align-items: center;
            border-bottom: 0.5px solid rgba(44, 46, 53, 0.1);
        }

        li.dashboard-link {
            padding: 20px 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 0.5px solid rgba(44, 46, 53, 0.1);
            text-transform: uppercase;
            font-size: 14px;
            &:last-child
            {
                border-bottom: 0;
            }
            &:hover
            {
                cursor: pointer;
            }
            icon.icon
            {
                margin-right: 20px;
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                img[data-active-yes]
                {
                    display: none;
                }
            }
            &.dashboard-link-selected
            {
                color: #CAC0B6;
                position: relative;
                font-family: var(--camptonsemibold);
                &::before
                {
                    content: '';
                    position: absolute;
                    width: 5px;
                    background-color: #CAC0B6;
                    height: 100%;
                    left: 0;
                }
                icon.icon
                {
                    img[data-active-yes]
                    {
                        display: flex;
                    }
                    img[data-active-non]
                    {
                        display: none;
                    }
                }
            }
        }
    }
    .content-aside
    {
        width: 100%;
        /* transform:translate(9999px); */
        opacity: 0; 
        display: none;
        padding-left: 0;

        @media screen and(min-width: $desktop-bp) {
            width: calc(2/3 * 100%);
        }
            
        &.show
        {
            transition: all 300ms linear;
            @media screen and(min-width: $desktop-bp) {
                transition: none;
            }
            /* transform:translate(0); */
            opacity: 1;
            display: flex;
        }

        .admin_customer {
            margin-top: 60px;

            @media screen and(min-width: $desktop-bp) {
                margin-top: 0;
            }
        }

        .admin_customer cms-triplet-blocks {
            padding: 0;

            text-wrapper {
                margin-right: 15px;
                background-color: var(--white);
            }

            text-wrapper picture {
                height: 190px;
            }

            text-wrapper p {
                padding: 0 25px 25px 25px;
                margin-bottom: 0;

                @media screen and(max-width: $large-local-thr) {
                    padding: 0 15px 15px 15px;
                    line-height: 1.2;
                }
            }
            
            text-wrapper h3 {
                padding: 0 25px;
                margin-bottom: 5px;
                font-size: 18px;

                @media screen and(max-width: $large-local-thr) {
                    padding: 0 15px;
                }
            }
        }

        @media screen and(max-width: $small-local-thr) 
        {
            width: 100%;
        }
    }
}
