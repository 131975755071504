@import "assets/styles/scss/config/globals";

header {
  display: flex;
  flex-direction: column;
  font-family: var(--camptonbook);
  font-size: 1.4rem;
  color: var(--black);
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 100;

  &.js_header header-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    opacity: 1;
    height: auto;

    position: fixed;
    top: 101px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    background: var(--white);
    overflow: auto;
    @media screen and(max-width: $mobile-bp) {
      top: 86px;
    }
  }

  // &.js_header header-search {
  //     @media screen and(max-width: $mobile-bp) {
  //         z-index: 20;
  //         position: fixed;
  //         top: 0;
  //         left: 0;
  //         right: 0;
  //     }
  // }

  &.js_header header-search .wrapper {
    padding: 0 25px;
    @media screen and(min-width: $desktop-bp) {
      padding: 0;
    }
  }

  // &.js_header header-top {
  //     visibility: hidden;
  //     min-height: 0;
  //     height: 0;
  //     padding: 0;
  // }

  header-top {
    display: flex;
    height: 30px;
    padding: 4px 0 4px 0;
    background: var(--grey_anthracite);
    color: var(--white);

    .do-phone {
      display: flex;
      margin-top: 2px;

      b {
        font-weight: normal;
      }
    }

    .do-mail-desktop {
      display: contents !important;
      @media screen and (max-width: $mobile-bp) {
        display: none !important;
      }
    }

    a {
      text-decoration: none;
    }

    .do-mail-mobile {
      display: none;
      @media screen and (max-width: $mobile-bp) {
        display: flex;
        align-items: center;
        width: 120px;
        justify-content: space-around;

        .email-icon {
          padding-top: 2px;
        }
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      @media screen and (max-width: $mobile-bp) {
        justify-content: space-between;
        width: 100%;
      }

      > div {
        display: flex;
        align-items: center;
        font-size: 16px;
        justify-content: space-around;
        width: 100%;

        img {
          height: 18px;
          width: 18px;
          object-fit: contain;
        }

        .hotline-hours {
          display: block;

          @media screen and (max-width: $mobile-bp) {
            display: none;
          }
        }
        .slogan {
          display: contents;

          @media screen and (max-width: $mobile-bp) {
            display: none;
          }
        }
      }
    }

    img {
      margin-right: 5px;
    }

    b,
    strong {
      font-weight: normal;
      font-family: var(--camptonsemibold);
    }
  }

  .header-contact-edit {
    height: 100px;
  }

  input-group {
    align-items: center;
    background: var(--grey_light);
    width: 300px;
    font-size: 1.3rem;
    margin-right: 10px;

    // @media screen and(min-width: $desktop-bp) {
    //   width: 480px;
    //   height: 50px;
    //   font-size: inherit;
    // }

    width: auto;
    margin-left: auto;
    background: transparent;
    position: relative;
    // }

    button {
      // padding: 0 10px;

      // @media screen and(max-width: $mobile-bp) {
      padding: 0 5px;
      // }
    }

    input {
      height: 50px;
      width: 100%;
      border: 0;
      font-family: var(--camptonbook);
      background: transparent;
      font-size: 1.3rem;

      // @media screen and (max-width: $mobile-bp),
      //   screen and (min-width: $desktop-bp) {
      font-size: 18px;
      // }
    }

    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-family: var(--camptonbook);
      font-size: 1.3rem;
      opacity: 1;
      color: var(--black);

      // @media screen and (max-width: $mobile-bp),
      //   screen and (min-width: $desktop-bp) {
      font-size: 18px;
      // }
    }

    input::-moz-placeholder {
      /* Firefox 19+ */
      font-family: var(--camptonbook);
      font-size: 1.3rem;
      opacity: 1;
      color: var(--black);

      // @media screen and (max-width: $mobile-bp),
      //   screen and (min-width: $desktop-bp) {
      font-size: 18px;
      // }
    }

    input:-ms-input-placeholder {
      /* IE 10+ */
      font-family: var(--camptonbook);
      font-size: 1.3rem;
      opacity: 1;
      color: var(--black);

      // @media screen and (max-width: $mobile-bp),
      //   screen and (min-width: $desktop-bp) {
      font-size: 18px;
      // }
    }

    input:-moz-placeholder {
      /* Firefox 18- */
      font-family: var(--camptonbook);
      font-size: 1.4rem;
      opacity: 1;
      color: var(--black);

      // @media screen and (max-width: $mobile-bp),
      //   screen and (min-width: $desktop-bp) {
      font-size: 18px;
      // }
    }
  }

  .doofinder-input-responsive {
    display: none !important;
    margin-left: 15px;

    @media screen and(max-width: 350px) {
      display: flex !important;
    }
  }

    header-search {
      padding-top: 9px;
      padding-bottom: 9px;
      background: var(--white);
      box-shadow: 0px 3px 10px #2c2e3512;
      border-bottom: 1px solid var(--light_grey_blue);
      height: 71px;

      svg:hover {
        path {
          fill: var(--light_icon_grey);
        }
        cursor: pointer;
        .is-stroke {
          fill: none;
          stroke: var(--light_icon_grey);
        }
      }

      @media screen and(max-width: $mobile-bp) {
        padding-top: 4px;
        padding-bottom: 4px;
        height: 56px;
      }

      .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        overflow: visible;

        > a {
          width: 200px;
          height: 53px;

          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
          }

          @media screen and (min-width: $desktop-bp) {
            width: 200px;
            height: 53px;
          }

          @media screen and(max-width: $mobile-bp) {
            width: 143px;
            height: 38px;
          }
        }

        ul li {
          @media screen and(max-width: $mobile-bp) {
            width: 34px;
          }
        }
      }

      input-group {
        input {
          // @media screen and(max-width: $mobile-bp) {
          display: block;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          // }
        }

        button {
          @media screen and(max-width: $mobile-bp) {
            width: 45px;
          }
        }

        .search-container {
          display: flex;
          align-items: center;
          position: relative;
          height: 40px;
          font-family: var(--camptonmedium);
          font-style: italic;
          font-size: 16px;
          color: #919191;
          border-radius: 5px;
          width: 190px;
          justify-content: space-between;

          @media screen and (max-width: $mobile-bp) {
            width: 45px;
            justify-content: inherit;
          }

          @media screen and (min-width: $desktop-bp) {
            width: 245px;
          }

          span {
            display: inline;
            margin-left: 5px;
            @media screen and (max-width: $mobile-bp) {
              display: none;
            }

          }

          svg {
            margin-top: -3px;
          }
        }
      }

      .cart-icon {
        img {
          margin-top: -2px;
        }

        &:hover {
          .empty-cart {
            opacity: 1;
            pointer-events: visible;
          }
        }
      }

      .search-icon {
        background-color: #F6F6F6;
        padding: 0 26px;
        padding-left: 5px;
        padding-right: 10px;
        margin-right: 16px;

        @media screen and (max-width: $mobile-bp) {
          margin-right: 0;
          background-color: transparent;
        }
        
        @media screen and(max-width: 350px) {
          display: none;
        }
      }

      .account {
        display: flex;
        position: relative;

        
        .sub_account_desktop {
          top: 36px;
          left: 0;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          pointer-events: none;
          position: absolute;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          z-index: 20;
          font-family: var(--camptonbook);
          font-size: 16px;
          font-weight: normal;
          line-height: 1.2;

          background: var(--grey_light);
          border: 1px solid #2c2e3512;

          @media screen and(max-width: $mobile-bp) {
            display: none;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 7px;
            color: var(--black);

            &:last-of-type {
              margin-bottom: 0;

              img {
                width: 14px;
              }
            }

            &.not_connected {
              &:last-of-type {
                margin-bottom: 0;

                img {
                  transform: rotate(180deg);
                }
              }
            }

            img {
              position: absolute;
              left: 0;
              margin-right: 5px;
              width: 16px;
            }

            a {
              width: 100%;
              position: relative;
              padding-left: 22px;
              white-space: nowrap;
            }
          }
        }

        .do-phone {
          display: none;
          @media screen and(min-width: $desktop-bp) {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            color: var(--dark_grey_blue);
            cursor: pointer;

            a {
              display: flex;
              align-items: center;
            }

            &:hover {
              color: var(--light_icon_grey);

              path {
                fill: var(--light_icon_grey);
                transition-property: fill;
                transition-delay: 0.1s;
                transition-duration: 0s;
              }
            }

            span {
              margin-left: 5px;
            }
          }
        }

        .empty-cart {
          top: 36px;
          right: 0;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          pointer-events: none;
          position: absolute;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          z-index: 20;
          font-family: var(--camptonbook);
          font-size: 16px;
          font-weight: normal;
          line-height: 1.2;

          background: var(--grey_light);
          border: 1px solid #2c2e3512;
          transition: border 1s ease, opacity 0.3s ease-in-out;
          @media screen and(max-width: $mobile-bp) {
            display: none;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 165px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 7px;
            color: var(--black);

            &:last-of-type {
              margin-bottom: 0;
            }

            img {
              position: absolute;
              left: 0;
              margin-right: 5px;
              width: 14px;
            }

            a {
              width: 100%;
              position: relative;
              padding-left: 22px;
            }

            .disabled {
              pointer-events: none;
              cursor: default;
            }
          }
        }
        .clicked-border {
          border: 1px solid black !important; /* Use !important to ensure override */
        }

        @media screen and(max-width: $mobile-bp) {
          margin-right: 15px;
        }

        li {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-right: 35px;
          align-items: center;
          font-family: var(--camptonmedium);
          font-size: 1.4rem;
          color: var(--grey);

          @media screen and(max-width: $mobile-bp) {
            margin-right: 10px;
          }

          &:last-of-type {
            @media screen and(min-width: $desktop-bp) {
              margin-right: 0px;
            }
          }
        }

        .user-navbar {
          position: relative;
          &:hover {
            .sub_account_desktop {
              opacity: 1;
              pointer-events: visible;
            }
          }
          li{
            a{
              &:hover{
                font-weight: bold;
              }
            }
          }
          @media screen and (max-width: $mobile-bp) {
            display: none;
          }

          a {
            padding-top: 1px;
          }
        }

        .hide-xs{
          @media screen and (max-width: $mobile-bp) {
            display: none;
          }
        }

        .home-trial-icon {
          a {
            padding-top: 1px;
            @media screen and (max-width: $mobile-bp) {
              padding-right: 10px;
            }
          }
        }

        .store-locator {
            a {
            padding-top: 2px;
            @media screen and (max-width: $mobile-bp) {
              padding-right: 10px;
            }
          }
        }

        bullet-number {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          bottom: 15px;
          min-width: 17px;
          height: 17px;
          border-radius: 50%;
          font-family: var(--camptonmedium);
          font-size: 1rem;
          background: #fd2d55;
          color: #fff;
        }
      }
    }

    header-nav {
      display: none;
      @media screen and(min-width: $desktop-bp) {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid var(--light_grey_blue);
        max-height: 60px;
        transition: opacity 1s ease-in-out;
        opacity: 1;
      }

      @media screen and(max-width: $mobile-bp) {
        
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
    }

    mega-menu {
      position: relative;
      display: flex;
      max-width: $desktop-max-width;
      width: 100%;
      z-index: 11;
      padding: 0 25px 120px 25px;

      @media screen and(min-width: $desktop-bp) {
        padding: 0;
      }

      .sub_account_mobile {
        display: none;

        @media screen and(max-width: $mobile-bp) {
          display: inherit;
        }
      }

      retail-wrapper {
        display: none;
        margin-top: auto;

        @media screen and(max-width: $mobile-bp) {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          width: calc(100% + 50px);
          margin-left: -25px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 25px;
          font-family: var(--camptonmedium);
          font-size: 14px;

          &:first-child {
            background: var(--grey_light);
            color: var(--grey);
          }

          &:last-child {
            background: var(--black);
            color: var(--white);
            //   text-transform: uppercase;
          }

          img {
            margin-right: 10px;
          }
        }
      }
    }

    .menu_nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      min-height: 45px;
      flex-direction: column;
      li {
        width: 100%;
        margin-right: 0;
        @media screen and(min-width: $desktop-bp) {
          width: auto;
          
        }
      }

      @media screen and(min-width: $desktop-bp) {
        flex-direction: row;
      }

      > li {
        margin-left: 8px;
        margin-right: 8px;
        font-size: 1.4rem;

        @media screen and (max-width: $mobile-bp),
          screen and (min-width: $desktop-bp) {
          margin-left: 15px;
          margin-right: 15px;
          font-size: inherit;
        }

        // Exception to the breakpoints rule. Way around would be with javascript (V2 ?)
        @media screen and(min-width: $desktop-bp) {
          &:hover white-container {
            transform: translateX(-50%);
            left: 50%;
            width: 100vw;
            height: auto;
            z-index: 9;
            background: #fff;
            position: absolute;
            display: block;
          }

          &:hover dark-background {
            position: absolute;
            left: -100%;
            width: calc(100vw + 100%);
            height: 100vh;
            z-index: 9;
            transition: none;
            pointer-events: none;
            background: rgba(44, 46, 53, 0.3);
          }
        }

        @media screen and(max-width: $mobile-bp) {
          position: relative;
          border-bottom: 1px solid rgba(44, 46, 53, 0.1);
          font-family: var(--camptonmedium);
          font-size: 1.6rem;
        }

        &.primary_nav {

            position: relative;
            border-bottom: 1px solid rgba(44, 46, 53, 0.1);
            font-family: var(--camptonmedium);
            font-size: 1.6rem;
            //   text-transform: uppercase;
            @media screen and(min-width: $desktop-bp) {
              position:inherit;
              font-family: var(--camptonbook);
            }
  
            &::after {
              display: block;
              position: absolute;
              content: " ";
              background: url(/assets/images/icons/_icon_filtre_chevron.svg)
                no-repeat;
              background-size: 45px 45px;
              width: 45px;
              height: 45px;
              right: -10px;
              top: 5px;
              transform: rotate(270deg);

              @media screen and(min-width: $desktop-bp) {
                display: none;
              }
            }
  
            a {
              position: relative;
              z-index: 2;
            }
          // }
          
        }

        &:last-of-type {
          margin-right: 0;
        }

        > a {
          height: 100%;
          min-height: 45px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid transparent;
          font-family: var(--camptonmedium);
          font-size: 16px;
          text-transform: uppercase;
          height: 60px;

          &:hover {
            @media screen and(min-width: $desktop-bp) {
              border-bottom: 2px solid var(--black);
            }
          }
        }
      }

      .sub_nav {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: $desktop-max-width;
        margin-inline: auto;
        padding:0 24px;

        @media screen and(min-width: $desktop-bp) {
          flex-direction: row;
        }

        li {
          width: 100%;
          
          @media screen and(min-width: $desktop-bp) {
            width: calc((100% / 4) - 23px);
            margin-right: 30px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .figcaption {
          display: none;

          @media screen and(min-width: $desktop-bp) {
            display: block;
            min-height: 350px;
            height: 350px;
          }
        }

        .has_submenu {
          .top_link {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            width: 100%;
            cursor: pointer;
            position: relative;
            font-family: var(--camptonsemibold);
            font-size: 1.6rem;
            min-height: 45px;

            @media screen and(min-width: $desktop-bp) {
              min-height: auto;
              margin-bottom: 15px;
            }
          }

          .top_link:after {
            display: block;
            right: -10px;
            top: 0;
            position: absolute;
            content: " ";
            background: url("/assets/images/icons/_icon_filtre_chevron.svg")
              no-repeat;
            background-size: 45px 45px;
            width: 45px;
            height: 45px;

            @media screen and(min-width: $desktop-bp) {
              display: none;
              top: auto;
              right: auto;
            }
          }

          .top_link.active:after {
            transform: rotate(180deg);
            transition: all 0.25s ease-out;
          }

          .is_submenu {
            overflow: hidden;
            max-height: 0;
            border-bottom: 1px solid rgba(44, 46, 53, 0.1);
            transition: max-height 0.2s ease-out;
            @media screen and(min-width: $desktop-bp) {
              max-height:fit-content;
              border-bottom: none;
            }

            li {
              height: auto;
              width: 100%;
              display: block;
              margin-bottom: 15px;
              font-size: var(--camptonbook);
                text-transform: none;

              &:last-child {
                margin-bottom: 20px;
              }

              a {
                &:hover {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }

    white-container {
      display: inherit;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: scroll;

      padding-top: 106px;
      padding-bottom: 15px;
      transform: translateX(100%);
      

      transition: 0.5s;
      min-height: 100%;
      width: 100%;
      z-index: 10;
      background: var(--white);

      &.js_overlay {
        transform: translateX(0);
      }

      @media screen and(min-width: $desktop-bp) {
        display: none;
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        border-top: 1px solid #f7f7f7;
        padding-top: 15px;
        padding-bottom: 15px;
        overflow: inherit;
      }

      .all {
        font-family: var(--camptonmedium);
        text-decoration: underline;
      }
  
      .primary_responsive {
        @media screen and(min-width: $desktop-bp) {
          display: none;  
        }
  
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 30px;
        border-bottom: 1px solid rgba(44, 46, 53, 0.1);
        min-height: 45px;
        cursor: pointer;
        font-family: var(--camptonsemibold);

        &:before {
          display: block;
          position: absolute;
          content: " ";
          background: url("/assets/images/icons/_icon_filtre_chevron.svg")
            no-repeat;
          background-size: 45px 45px;
          width: 45px;
          height: 45px;
          left: -15px;
          top: -3px;
          transform: rotate(90deg);
        }
      }

      @media screen and(max-width: $mobile-bp) {
        padding-top: 91px;
      }
    }
}

.unstick {
  position: relative;
}

dark-background {
  display: block;
  flex: 0 0 auto;
  width: 100%;
  transition: width 0.75s ease-in-out 0.25s;
  background-color: rgba(0, 0, 0, 0.3);

  @media screen and(max-width: $mobile-bp) {
    display: none;
  }
}

.headerMenu {
  display: none;
}

@media only screen and (max-width: $mobile-bp) {
  .headerMenu {
    padding: 0 15px;
    background-color: #f1f1f1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease, padding-top 0.5s ease;
    display: block;
  }
  .headerMenu.js_header {
    padding-top: 15px;
    height: 100vh;
  }

  .headerMenu ul {
    list-style-type: none;
    padding: 25px 0px;
    margin: 0;
  }

  .headerMenu li {
    font-size: 1.6rem;
    padding: 12px 12px;
    //   border-bottom: 1px solid #f2f2f2;
  }

  .headerMenu li:last-child {
    border-bottom: none;
  }

  .headerMenu a {
    //   color: #333;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .headerMenu li:first-child {
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    padding-bottom: 60px;
  }

  .close-btn {
    position: relative;
    // top: 85px;
    right: 7px;
    cursor: pointer;
    font-size: 35px;
    font-weight: bold;
    color: #666;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    float: right;
  }
}
/* Begin  burger*/
.js_burger {
  display: block;
  width: 20px;
  opacity: 1;
  transition: width 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0.5s;
  z-index: 8;
  position: relative;

  @media screen and(min-width: $desktop-bp) {
    display: none;
  }

  span {
    position: relative;
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 5px;
    z-index: 1;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.5s ease;
    transform-origin: 4px 0;
    background: var(--black);

    &:first-child {
      transform-origin: 0 0;
    }

    &:nth-child(2) {
      transform-origin: 0 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.js_header .js_burger {
  height: 20px;

  span {
    &:first-child {
      transform: rotate(45deg) translate(3px, -2px);
    }

    &:nth-child(2) {
      transform: rotate(-45deg) translate(-2px, 8px);
    }

    &:last-child {
      transform: rotate(0) scale(0.2, 0.2);
      height: 0;
    }
  }
}

figcaption-wrapper {
  display: flex;
  position: relative;
  height: 100%;
  background: rgba(103, 112, 145, 0.2);

  a {
    height: 100%;
    width: 100%;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      background: linear-gradient(
        to top,
        rgba(44, 46, 53, 0.2),
        rgba(44, 46, 53, 0.2)
      );
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  figcaption-title {
    position: absolute;
    bottom: 20px;
    left: 25px;
    right: 25px;
    z-index: 10;
    font-family: var(--camptonsemibold);
    font-size: 2.2rem;
    font-weight: normal;
    text-align: left;
    color: var(--white);
  }
}

.link-picture {
  margin-bottom: 5px !important;
  picture {
    margin: 0;
    padding: 0;
    margin-right: 15px;
    img {
      min-height: 35px;
      height: 35px;
      width: 50px;
    }
  }
}
