/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
	outline: none;
}

input {
	outline: none;
}
