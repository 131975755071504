@import "assets/styles/scss/config/globals";

warranty-content {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  warranty-card {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    border: 2px solid rgba(44, 46, 53, 0.1);
    padding: 15px 15px 0 15px;
    position: relative;

    &:hover {
      border: 2px solid rgba(30, 31, 36, 0.37);
    }

    warranty-checked-icon {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      transform: none;
      background: url(/assets/images/icons/_icon_checkbox_white.svg),
        var(--black) no-repeat;
      background-size: auto;
      background-size: 20px 20px;
      width: 22px;
      height: 22px;
      border-radius: 0;
    }

    warranty-product-image {
      width: 30%;
      padding: 5px;
    }

    warranty-product-description {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      padding: 10px 10px 5px 10px;
      width: 70%;

      warranty-product-label {
        width: auto;
        margin-bottom: 10px;
      }

      warranty-product-price {
        width: auto;
        margin-bottom: 10px;
        font-weight: bold;
      }

      warranty-choice {
        width: auto;
        display: flex;
        justify-content: flex-end;

        warranty-choice-container {
          width: fit-content;

          warranty-error-message {
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: var(--red);
            margin-top: 5px;
            height: 15px;
            visibility: hidden;
          }

          .show-error-message {
            visibility: visible;
          }
          
          warranty-choice-header {
            display: flex;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 3px;
          }

          warranty-choice-radio-container {
            display: flex;
            flex-direction: row;
            border: 1px solid var(--black);
            border-radius: 10px;
            font-size: 15px;
            width: 250px;
            height: 50px;
            cursor: pointer;

            @media screen and(max-width: $mobile-bp) {
              width: 260px;
            }

            warranty-choice-radio-add {
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              flex-direction: row;
              width: 60%;
              border-right: 1px solid var(--black);

              input {
                display:none;
              }

              &:hover {
                font-weight: bold;
              }

              warranty-choice-radio-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                height: 100%;
                padding: 5px 0;
              }
            }

            .radio-add-error {
              border-right: 1px solid var(--red);
            }

            .input-selected {
              background-color: var(--black);
              color: white;
              font-weight: bold;
              border-radius: 9px;
              z-index: 10;
            }

            .input-not-selected {
              color: var(--grey);
              border-radius: 9px;
              border-right: 0;
            }

            warranty-choice-radio-cancel {
              display: flex;
              justify-content: space-around;
              align-items: center;
              flex-direction: row;
              width: 40%;

              input {
                display:none;
              }

              &:hover {
                font-weight: bold;
              }
            }
          }

          .container-error {
            border: 1px solid var(--red);
          }

          .container-clicked {
            background-color: var(--grey_light);
          }
        }
      }
    }
  }

  .selected {
    border: 2px solid var(--black);

    &:hover {
      border: 2px solid var(--black);
    }
  }

}