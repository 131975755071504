@import "assets/styles/scss/config/globals";

.inscription-wrapper{
    display: flex;
    justify-content: space-around;
    min-height: 100px;
    margin-top: 50px;
    margin-bottom: 100px;
    flex-wrap: wrap;

    .block-title{
        text-transform: uppercase;
        display: flex;
        width: 100%;
        -webkit-box-pack: center;
        justify-content: flex-start;
        margin-top: 25px;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        font-family: var(--camptonsemibold);
        color: #d75931;
        line-height: 20px;
		
        @media screen and(max-width: $small-thr) 
        {
            font-size: 14px;
        }
    }

    &.create_account {
        .inscription-holder {
            width: 100%;

            @media screen and(min-width: $desktop-bp) {
                width: 700px;
            }
        }

        .bootstrap-select {
            height: 50px;

            .dropdown-menu.inner li {
                font-size: 18px;
            }

            > button{
                padding: 0;
                
                > .filter-option {
                    height: 50px;
                    padding: 16px 30px 16px 20px;
                }
            }
        }
    }

    .inscription-holder {
        width: 75%;

        @media screen and(max-width: $mobile-bp) 
        {
            width: 90%;
        }

        @media screen and(max-width: $small-thr) 
        {
            width: 100%;
        }

        h1
        {
            border-bottom: 1px solid rgba(44,46,53,.2);
            text-transform: initial;
            text-align: left;
            padding: 0 0 20px 0;
            font-size: 22px;
            font-family: var(--camptonmedium);
            color: #2C2E35;

            @media screen and(max-width: $mobile-bp) 
            {
                font-size: 22px;
            }

            @media screen and(max-width: $small-thr) 
            {
                font-size: 20px;
            }
        }
    }
    .form-ligne
    {
        display: flex;
        justify-content: space-between;

        div.form-row input[type="text"], input[type='password'], input[type='email']
        {
            width: auto;
        }
    }
    .gender-wrapper
    {
        display: flex;
        justify-content: space-between;
    
        div.inscription-radio-row.form-row {
            margin-bottom: 0 !important;
        }
    }
    .by-2
    {
        flex-wrap: wrap;

        .form-row
        {
            width: 48%;

            @media screen and(max-width: $small-thr) 
            {
                width: 100%;
            }
        }

    }
    .by-3
    {
        flex-wrap: wrap;

        .form-row
        {
            width: 31%;

            @media screen and(max-width: $small-thr) 
            {
                width: 100%;
            }
        }
        &.responsive-maintain
        {
            .form-row
            {
                @media screen and(max-width: $small-thr) 
                {
                    width: 31%;
                }
            }
        }
    }
    label.checkbox
    {
		display: flex !important;
        align-items: center;
        line-height: 1.4;


		&::before
        {
            top: 0 !important;
            transform: translateY(0) !important;
        }
    }
    .help-text, .inscription-instruction
    {
        font-size: 12px;
        color: rgba(44, 46, 53, 0.5);
        margin-top: 10px;
        sup
        {
            font-size: 14px;
        }
    }

    .inscription-instruction
    {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        margin-top: 75px;
        @media screen and(max-width: $small-thr) 
        {
            font-size: 14px;
            margin-top: 40px;
        }
    }

    div.form-row
    {
        margin-bottom: 20px;

        label
        {
            font-size: 14px;
            margin-bottom: 5px;
        }

        input[type='text'], 
        input[type='password'],
        input[type='email'],
        input[type='tel'] {
            height: 50px;
            font-size: 18px;
            color: var(--black);
        }

        input[type="checkbox"].custom_checkbox ~ label {
            padding: 0 0 0 50px;
        }
    }
}

div.inscription-radio-row.form-row
{
    flex-direction: row;
    align-items: baseline;
    
    span, label
    {
        
        font-size: 18px;
        @media screen and(max-width: $small-thr) 
        {
            font-size: 16px;
        }
    }

    input[type="radio"].custom_radio ~ label 
    {
        display: flex;
        padding-top: 0;
        align-items: center;
        margin-left: 40px;
        font-size: 18px;
        align-items: flex-start;
        @media screen and(max-width: $small-thr) 
        {
            font-size: 16px;
            margin-left: 20px;
            

        }
    }
}

div.inscription-select-row.form-row 
{

    beautified-select
    {
        min-width: auto;
        beautiful-title
        {
            justify-content: flex-start;
            border: 1px solid rgba(44,46,53,.2);
        }
        beautiful-list
        {
            max-height: 115px;
        }
    }
}

.information-perso-wrapper
{
    margin-top: 0;
    margin-bottom: 0;
    .inscription-holder {
        h1 {
            border-bottom: 0;
            padding-bottom: 0;
            font-size: 28px;
            font-family: var(--camptonsemibold);
        }

        width: 100%;
        beautiful-title
        {
            background: #fff;
        }

        .password-reset
        {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
        }

        .inscription-instruction
        {
            margin-top: 95px;
        }

        .inscription-form .bootstrap-select .dropdown-toggle {
            height: 50px;
            background-color: #fff !important;

            .filter-option {
                overflow: visible;
            }
        }
        .form_row {
            > div {
                @media screen and (max-width: $mobile-bp) {
                    margin-right: 10px;
                    width: 100%;
                }
            }
        }
    }

    .bootstrap-select .dropdown-menu.inner {
        height: 312px;
    }
}