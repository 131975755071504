.alert_news {
  background: #cce5ff;
  color: #004085;
  border-color: #b8daff;
}

.alert_success {
  background: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

.alert_warning {
  background: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
}

.alert_danger {
  background: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}
.alert_danger_login {
  background: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

alert-wrapper {
  display: flex;
  width: calc(100% - 30px);
  border-radius: 5px;
  padding: 20px !important;
  margin-bottom: 20px !important;
  line-height: 1.4;
  font-family: var(--camptonbook);
  font-weight: normal;
  font-size: 16px;

  img {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transform: rotate(0);

    &:hover {
      transform: rotate(180deg);
    }
  }
}
