@import "assets/styles/scss/config/globals";

$local-thr: 584px;

.bon-de-reduction-wrapper
{
    width: 100%;
    padding: 0;
}

.bon-de-reductions-list
{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    .bon-de-reduction
    {
        background: #fff;
        width: 100%;
        padding: 20px 20px;
        margin: 10px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (max-width: $mobile-bp) 
        {
            padding: 20px 10px;
        }

        @media screen and (max-width: $local-thr) 
        {
            flex-direction: column;
        }

        .montant
        {
            display: flex;
            width: 15%; 
            justify-content: center;
            font-size: 25px;
            font-family:  var(--camptonsemibold);
            @media screen and (max-width: $local-thr) 
            {
                font-size: 22px;
                width: 100%;
                justify-content: flex-start;
            }
        }

        .description
        {
            display: flex;
            flex-direction: column;
            width: 80%;
            @media screen and (max-width: $local-thr) 
            {
                width: 100%;
                justify-content: flex-start;
            }
            p
            {
                font-size: 15px;
                @media screen and (max-width: $local-thr) 
                {
                    font-size: 14px;
                }
                span.text-blue
                {
                    color: #02CBFE;
                    cursor: pointer;
                }
                strong
                {
                    font-size: 18px;
                    font-family:  var(--camptonsemibold);
                    @media screen and (max-width: $local-thr) 
                    {
                        font-size: 16px;
                    }
                }

                &.condition
                {
                    margin-bottom: 0;
                }
            }

            ul, li
            {
                list-style: '- ';
                font-size: 15px;
                margin-left: 7px;
                @media screen and (max-width: $local-thr) 
                {
                    font-size: 14px;
                }
            }

            li
            {
                padding: 7px 0;
            }
        }
    }
}