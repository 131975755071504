/******************************/
/*  	campton Font        */
/******************************/

@font-face {
	font-family: 'campton_bold';
	src: url('/assets/fonts/campton/Campton-Bold.eot');
	src: url('/assets/fonts/campton/Campton-Bold.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/campton/Campton-Bold.woff2') format('woff2'),
		url('/assets/fonts/campton/Campton-Bold.woff') format('woff'),
		url('/assets/fonts/campton/Campton-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'campton_book';
	src: url('/assets/fonts/campton/Campton-Book.eot');
	src: url('/assets/fonts/campton/Campton-Book.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/campton/Campton-Book.woff2') format('woff2'),
		url('/assets/fonts/campton/Campton-Book.woff') format('woff'),
		url('/assets/fonts/campton/Campton-Book.ttf') format('truetype');
}

@font-face {
	font-family: 'campton_medium';
	src: url('/assets/fonts/campton/Campton-Medium.eot');
	src: url('/assets/fonts/campton/Campton-Medium.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/campton/Campton-Medium.woff2') format('woff2'),
		url('/assets/fonts/campton/Campton-Medium.woff') format('woff'),
		url('/assets/fonts/campton/Campton-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'campton_semibold';
	src: url('/assets/fonts/campton/Campton-SemiBold.eot');
	src: url('/assets/fonts/campton/Campton-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/campton/Campton-SemiBold.woff2') format('woff2'),
		url('/assets/fonts/campton/Campton-SemiBold.woff') format('woff'),
		url('/assets/fonts/campton/Campton-SemiBold.ttf') format('truetype');
}

/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
    font-family: "Montserrat100";
    font-weight: 100;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-Thin.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic **/
@font-face {
    font-family: "Montserrat100Italic";
    font-weight: 100;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-ThinItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
    font-family: "Montserrat200";
    font-weight: 200;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-ExtraLight.woff2") format("woff2"),
        url("/assets/fonts/montserrat/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat200Italic";
    font-weight: 200;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-ExtraLightItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat300";
    font-weight: 300;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-Light.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: "Montserrat300Italic";
    font-weight: 300;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-LightItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat400";
    font-weight: 400;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-Regular.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
    font-family: "Montserrat400Italic";
    font-weight: 400;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-Italic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
    font-family: "Montserrat500";
    font-weight: 500;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-Medium.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
    font-family: "Montserrat500Italic";
    font-weight: 500;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-MediumItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat600";
    font-weight: 600;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
    font-family: "Montserrat600Italic";
    font-weight: 600;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-SemiBoldItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat700";
    font-weight: 700;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-Bold.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: "Montserrat700Italic";
    font-weight: 700;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-BoldItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat800";
    font-weight: 800;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat800Italic";
    font-weight: 800;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
    font-family: "Montserrat900";
    font-weight: 900;
    font-style: normal;
    src: url("/assets/fonts/montserrat/Montserrat-Black.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
    font-family: "Montserrat900Italic";
    font-weight: 900;
    font-style: italic;
    src: url("/assets/fonts/montserrat/Montserrat-BlackItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/Montserrat-BlackItalic.woff") format("woff");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Thin **/
@font-face {
    font-family: "MontserratAlternates100";
    font-weight: 100;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-Thin.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-Thin.woff") format("woff");
}

/** Montserrat Alternates Thin-Italic **/
@font-face {
    font-family: "MontserratAlternates100Italic";
    font-weight: 100;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-ThinItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-ThinItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraLight **/
@font-face {
    font-family: "MontserratAlternates200";
    font-weight: 200;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-ExtraLight.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-ExtraLight.woff") format("woff");
}

/** Montserrat Alternates ExtraLight-Italic **/
@font-face {
    font-family: "MontserratAlternates200Italic";
    font-weight: 200;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-ExtraLightItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Alternates Light **/
@font-face {
    font-family: "MontserratAlternates300";
    font-weight: 300;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-Light.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-Light.woff") format("woff");
}

/** Montserrat Alternates Light-Italic **/
@font-face {
    font-family: "MontserratAlternates300Italic";
    font-weight: 300;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-LightItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-LightItalic.woff") format("woff");
}

/** Montserrat Alternates Regular **/
@font-face {
    font-family: "MontserratAlternates400";
    font-weight: 400;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-Regular.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-Regular.woff") format("woff");
}

/** Montserrat Alternates Regular-Italic **/
@font-face {
    font-family: "MontserratAlternates400Italic";
    font-weight: 400;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-Italic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-Italic.woff") format("woff");
}

/** Montserrat Alternates Medium **/
@font-face {
    font-family: "MontserratAlternates500";
    font-weight: 500;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-Medium.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-Medium.woff") format("woff");
}

/** Montserrat Alternates Medium-Italic **/
@font-face {
    font-family: "MontserratAlternates500Italic";
    font-weight: 500;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-MediumItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-MediumItalic.woff") format("woff");
}

/** Montserrat Alternates SemiBold **/
@font-face {
    font-family: "MontserratAlternates600";
    font-weight: 600;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-SemiBold.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-SemiBold.woff") format("woff");
}

/** Montserrat Alternates SemiBold-Italic **/
@font-face {
    font-family: "MontserratAlternates600Italic";
    font-weight: 600;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-SemiBoldItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Bold **/
@font-face {
    font-family: "MontserratAlternates700";
    font-weight: 700;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-Bold.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-Bold.woff") format("woff");
}

/** Montserrat Alternates Bold-Italic **/
@font-face {
    font-family: "MontserratAlternates700Italic";
    font-weight: 700;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-BoldItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-BoldItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraBold **/
@font-face {
    font-family: "MontserratAlternates800";
    font-weight: 800;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-ExtraBold.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-ExtraBold.woff") format("woff");
}

/** Montserrat Alternates ExtraBold-Italic **/
@font-face {
    font-family: "MontserratAlternates800Italic";
    font-weight: 800;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-ExtraBoldItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Black **/
@font-face {
    font-family: "MontserratAlternates900";
    font-weight: 900;
    font-style: normal;
    src: url("/assets/fonts/montserrat/MontserratAlternates-Black.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-Black.woff") format("woff");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
    font-family: "MontserratAlternates900Italic";
    font-weight: 900;
    font-style: italic;
    src: url("/assets/fonts/montserrat/MontserratAlternates-BlackItalic.woff2") format("woff2"),
         url("/assets/fonts/montserrat/MontserratAlternates-BlackItalic.woff") format("woff");
}