@import "assets/styles/scss/config/globals";

.password {
    flex-wrap: wrap;
    margin-top: 20px !important;

    breadcrumb-block {
        flex-direction: row;
        width: 100%;

        @media screen and(max-width: $mobile-bp) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .inscription-btn {
        margin-top: 40px !important;
    }

    .form-row {
        width: 400px;

        @media screen and(max-width: $small-thr) {
            width: 100%;

            input {
                width: 100% !important;
            }
        }

        .btn_3,
        .btn,
        .btn_2,
        a {
            margin: 0 auto;
        }
    }

    sign-in-holder {
        border-right: none !important;

        .rounded {
            background: #008a7c;
            border: 1px solid #008a7c;
            position: relative;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            margin-bottom: 40px;

            &:after {
                position: absolute;
                content: ' ';
                top: -5px;
                right: 6px;
                background: url(/assets/images/icons/_icon_checkbox_white.svg), no-repeat;
                background-size: 45px 45px;
                width: 40px;
                height: 40px;
            }
        }

        a {
            margin-top: 30px;
        }

        #form_password_first_help {
            font-size: 14px;
        }
    }

    sign-in-image {
        display: none;
        
        @media screen and(min-width: $desktop-bp) {
            display: flex;
            height: 420px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
//password icon
.eye-slash-duotone-icon, .eye-duotone-icon{
    min-width: 20px;
    height: 20px;
    margin-left: 10px;
    background-color:var(--black);
    cursor: pointer;
}

.eye-slash-duotone-icon.eye-duotone-icon{
    -webkit-mask: url(/assets/images/fontawesome/eye-duotone.svg) no-repeat center;
    mask: url(/assets/images/fontawesome/eye-duotone.svg) no-repeat center;
}
.eye-slash-duotone-icon{
    -webkit-mask: url(/assets/images/fontawesome/eye-slash-duotone.svg) no-repeat center;
    mask: url(/assets/images/fontawesome/eye-slash-duotone.svg) no-repeat center;
}

.eye-icon-container{
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 20px;
}