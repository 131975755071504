@import "assets/styles/scss/config/globals";

.comparator {
    padding: 30px 0;

    wrapper-switch {
        margin-bottom: 15px;
        @media screen and(max-width: $mobile-bp),
            screen and(min-width: $desktop-bp) {
            margin-bottom: 0;
        }
    }

    breadcrumb-block {
        margin-bottom: 40px !important;

        @media screen and(max-width: $mobile-bp) {
            display: none;
        }
    }

    h1 {
        margin-bottom: 20px !important;
        text-transform: none;
        text-align: left;
        font-size: 36px;


        @media screen and(max-width: $mobile-bp) {
            font-size: 28px;
        }
    }

    results-number {
        display: flex;
        align-items: center;
        font-size: 22px;
        margin-bottom: 10px;

        @media screen and(max-width: $mobile-bp) {
            margin-bottom: 20px;
        }

        @media screen and(min-width: $desktop-bp) {
            margin-bottom: 0;
        }

        b,
        strong {
            font-weight: normal;
            margin: 0 0 0 10px;
        }
    }

    sort-results {
        flex-direction: column;

        @media screen and(max-width: $mobile-bp) {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px !important;
        }

        @media screen and(min-width: $desktop-bp) {
            flex-direction: row;
        }

        >button,
        >a {
            font-family: var(--camptonmedium);
            font-size: 14px;
            font-weight: normal;
            text-transform: uppercase;
            color: rgba(44, 46, 53, 0.3);
        }
    }

    design-slideshow {
        display: flex;
        position: relative;

        @media screen and(max-width: $large-thr) {
            padding: 0 15px;
        }

        @media screen and(max-width: $mobile-bp) {
            padding: 0 25px;
        }

        .wrapper {
            width: 100%;
        }

        .swiper-design,
        .swiper-design-second {
            overflow: visible;
        }

        .swiper-button-lock {
            display: flex;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 170px;
            position: absolute;
            height: 80px;
            width: 30px;
            background-color: var(--black);

            &:after {
                font-size: 18px;
                color: #fff;
            }

            &.swiper-button-disabled {
                background: #00000026;
                opacity: 1;
            }
        }

        general-card {
            background: #fff;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 0;

            card-container {
                padding: 30px;
                margin-bottom: 30px;
                //TODO essayer en ligne : min-height: 420px;
                min-height: 400px;

                @media screen and(max-width: $mobile-bp) {
                    margin-bottom: 50px;
                }

                img {
                    height: 140px;
                    width: 100%;
                    margin-top: 45px;
                    object-fit: contain;

                    &.icon_top {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        object-fit: contain;
                        width: 55px;
                        height: 40px;
                        margin: 0;
                    }
                }

                .btn_small {
                    width: 100%;
                }

                title-product {
                    margin: 0 0 15px 0;
                    width: 100% !important;
                }

                .color-choice,
                color-choice {
                    margin: 0 0 20px 0;
                }
            }

            card-characteristics {
                display: flex;
                flex-direction: column;

                block-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-height: 60px;
                    height: auto;
                    padding: 5px 20px;
                    margin-bottom: 20px;
                    line-height: 1.2;
                    font-family: var(--camptonsemibold);
                    font-size: 18px;
                    font-weight: normal;
                    background: #F6F6F6;
                }

                ul {
                    padding-bottom: 45px;
                    padding-left: 10px;
                    margin-bottom: 30px;
                    list-style: none;
                    font-size: 18px;
                    font-family: var(--camptonbook);
                    font-weight: normal;

                    @media screen and(max-width: $mobile-bp) {
                        padding-bottom: 15px;
                        border-bottom: none;
                    }

                    li {
                        display: flex;
                        align-items: center;
                        line-height: 1.5;

                        span {
                            margin-right: 10px;
                            color: var(--grey);
                        }

                        img {
                            height: 20px;
                            width: auto;
                            margin: 0 10px 0 0;
                        }

                        &::before {
                            content: '\2022';
                            color: var(--black);
                            font-weight: bold;
                            display: inline-block;
                            width: 10px;
                            margin-left: -10px;
                        }
                    }
                }
            }

            card-frame {
                display: flex;
                flex-direction: column;

                a,
                button {
                    margin: 0 auto 30px auto;
                    font-family: var(--camptonmedium);
                    font-size: 14px;
                    font-weight: normal;
                    text-transform: uppercase;
                }

                size-wrapper {
                    height: 305px;
                    width: calc(100% - 50px);
                    margin: 15px auto 50px auto;
                    padding: 0;

                    @media screen and(max-width: $mobile-bp) {
                        display: block;
                    }

                    img {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }

                    global-glasses {
                        top: 3%;

                        @media screen and(min-width: 1000px) {
                            top: -3%;
                        }
                    }

                    spacing-glasses {
                        position: absolute;
                        display: inline-block;
                        font-family: var(--camptonmedium);
                        font-size: 14px;
                        text-transform: uppercase;
                        line-height: 1;
                        right: 43%;
                        top: 42%;

                        @media screen and(max-width: $mobile-bp) {
                            right: 47%;
                        }

                        @media screen and(max-width: 479px) {
                            top: 42%;
                            right: 42%;
                        }
                    }

                    right-glasse {
                        right: 10% !important;

                        @media screen and(max-width: $large-thr) {
                            right: 10% !important;
                            top: 35%;
                        }

                        @media screen and(max-width: $mobile-bp) {
                            right: 13% !important;
                        }

                        @media screen and(max-width: $mobile-bp) {
                            right: 27% !important;
                        }

                        @media screen and(max-width: 599px) {
                            right: 26% !important;
                        }

                        @media screen and(max-width: 499px) {
                            right: 14% !important;
                        }
                    }

                    left-glasse {
                        @media screen and(max-width: 1199px) {
                            top: 37%;
                        }

                        @media screen and(max-width: $mobile-bp) {
                            left: 17%;
                        }

                        @media screen and(max-width: $mobile-bp) {
                            left: 30%;
                        }

                        @media screen and(max-width: 599px) {
                            left: 27%;
                        }

                        @media screen and(max-width: 499px) {
                            left: 20%;
                        }
                    }

                    branch-glasse {
                        bottom: 3%;

                        @media screen and(min-width: 1000px) {
                            bottom: -3%;
                        }
                    }
                }
            }
        }

        .swiper-pagination {
            display: none;
            top: 395px;
            transform: translateX(-50%);
            left: 50%;

            @media screen and(max-width: $mobile-bp) {
                display: flex;
            }

            @media screen and(max-width: 374px) {
                top: 410px;
            }
        }

        .swiper-pagination-lock {
            display: none;
            @media screen and(max-width: $mobile-bp) {
                display: flex;
                position: absolute;
                bottom: 0;
            }
        }

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            margin-right: 5px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .swiper-pagination-bullet-active {
            background: var(--black);
        }
    }
}

comparator-add-remove {
    display: none;

    @media screen and(max-width: $mobile-bp) {
        z-index: 1000;
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .btn,
    .btn_3 {
        display: flex;
        align-items: center;
        padding: 15px;
        width: 50%;
    }

    .btn {
        background: #fff;
    }
}