@import "assets/styles/scss/config/globals";

main {
	flex: 1 0 auto;
	font-size: 18px;
	overflow: hidden;
	margin-top: 101px;
	@media screen and(min-width: $desktop-bp) {
		margin-top: 161px; // Sticky header under margin
	}
	@media screen and(max-width: $mobile-bp) {
		margin-top: 78px;
		margin-top: 86px;
	}
}

main > *:not(modal-wrapper){
	max-width: $desktop-max-width;
  	margin-inline: auto;
	padding-inline: 24px;
	@media screen and(min-width: $desktop-bp) {
	 	padding-inline: 0;
	}
	@media screen and(max-width: $mobile-bp) {
		padding-inline: 0;
	}
}