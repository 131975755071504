@import "assets/styles/scss/config/globals";

$background-mobile-vto: rgba(255, 255, 255, 0.75);

/**********************
    VTO modal container
********************* */
#VTO .card-modal-container {
    width: 100%;
    height: 100%;
    background-color: black;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and(min-width: $desktop-bp) {
        display: grid;
        grid-template-columns: 728px 264px;
        width: 992px;
        height: 670px;
     }

     .wrapper-video{
        width:100%;
        height: 100%;
        z-index: 0;
     }

     .tooltip-ipd{
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        z-index: 100;
        width: 75%;
        height: min-content;
    
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    
        font-family: var(--camptombook);
        font-size: 16px;
        padding: 25px;
        border-radius: 5px;
    
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        color: var(--black);
    
        background-color: var(--white);
    
        @media screen and (min-width: $desktop-bp) {
            width: 450px;
            top: 130px;
            left: 538px;
            transform: initial;
        }
    
        .tooltip-content-wrapper{
            display: flex;
            flex-direction: column;
            gap: 25px;
    
            @media screen and (min-width: $desktop-bp) {
                flex-direction: row;
            }
    
            img{
                width: 100px;
                @media screen and (min-width: $desktop-bp) {
                    width: initial;
                }
            }
    
            .tooltip-text-wrapper{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                
                p {
                    text-align: left;
                }
            }
    
            .tooltip-img-wrapper{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
    
                p{
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }
            }
    
        }
        
        .tooltip-triangle {
            position: absolute;
            bottom: -10px; 
            right: 20px; 
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid var(--white);
    
            display: none;
            @media screen and (min-width: $desktop-bp) {
                display: block;
            }
        }
    }
}
/**********************
    VTO modal title
********************* */
#VTO .wrapper-title{
    position: absolute;
    top: 5px; 
    left: 5px;
    width: auto;
    height: auto;
    padding: 10px;
    width: auto;
    height: auto;
    background: $background-mobile-vto;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    z-index: 1;

    .title {
        font-size: 18px;
        font-family: var(--camptonsemibold);
        color: var(--black);
        font-weight: normal;
        margin: initial;
        padding: initial;
        text-align: initial;
        line-height: initial;
        text-transform: initial;
        @media screen and(min-width: $desktop-bp) {
            font-size: 22px;
            position: initial;
            background: none;
        }
    }

    .frame-img {
        width: 135px;
        align-self: center;
        display: none;
        @media screen and(min-width: $desktop-bp) {
            display: initial;
        }
        margin-right: 15px
    }
}
/**********************
    VTO menu 
********************* */
.vto-menu {
    flex-direction: column;
    gap: 20px;
    flex: 1;
    background-color: none;
    width: 100%;
    height: 100%;
    padding: 50px 10px 10px 10px;
    @media screen and(min-width: $desktop-bp) {
        display: flex;
        width: 264px;
        height: 100%;
        background-color: white;
    }
    /**********************
        Filter title
    ********************* */
    h3 {
        font-size: 16px;
        font-family: var(--camptonsemibold);
        width: auto;
        @media screen and(min-width: $desktop-bp) {
            font-size: 22px;
            padding: 10px 10px; 
            background: var(--grey_light);
            border-radius: 5px;
            width: 100%;
        }
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        text-align: center;
    }

    /**********************
        All filters 
    ********************* */
    .filter-wrapper{
        position: absolute;
        bottom: 50px;
        left: 5px;
        width: calc(100vw - 10px);
        display: flex;
        justify-content: center;
        gap: 5px;
        @media screen and(min-width: $desktop-bp) {
            position: initial;
            display: flex;
            flex-direction: column;
            gap: 25px;
            margin-top: 5px;
            width: 100%;
        }
        /**********************
            color and shade filter
        ********************* */
        .filter-wrapper-component{
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            padding: 15px 20px;
            background: $background-mobile-vto;
            border-radius: 5px;
            transition: transform 0.3s ease;
            
            @media screen and(min-width: $desktop-bp) {
                justify-content: flex-start;
                align-items: center;
                border: none;
                padding: 0px 0px;
            }
            color-wrapper, .tab-shades-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                @media screen and(min-width: $desktop-bp) {
                    width: 100%;
                }
                
                color-choice, .tab-shades-icons{
                    display: flex;
                    align-items: center;
                    column-gap: 15px;
                    row-gap: 5px;
                    justify-content: center;
                    // todo: uncomment if need more space between colors
                    //justify-content: space-between;
                    @media screen and(min-width: $desktop-bp) {
                        justify-content: center;
                        column-gap: 10px;
                        row-gap: 5px;
                    }
                }
                top-label, bottom-label {
                    display: none;
                    @media screen and(min-width: $desktop-bp) {
                        display: initial;
                    }
                }
                color-content{
                    height: 28px; 
                    width: 28px;
                }        
                color-content.active {
                    position: relative;
                    @media screen and(min-width: $desktop-bp) {
                        // white checkbox for active color
                        &::before {
                            content: " ";
                            position: absolute;
                            background: url(/assets/images/icons/_icon_checkbox_white.svg);
                            top: -12px;
                            right: -6px;
                            background-size: 49px 49px;
                            width: 49px;
                            height: 49px;
                        }
                    }
                }
                .jaune.active,
                .blanc.active,
                .transparent.active,
                .argent.active,
                .beige.active {
                    @media screen and(min-width: $desktop-bp) {
                        &::before {
                            background: url(/assets/images/icons/_icon_checkbox.svg);
                            top: -12px;
                            right: -6px;
                            background-size: 49px 49px;
                            width: 49px;
                            height: 49px;
                        }
                    }
                }
                color-content:not(.active){
                    cursor: pointer;
                    display: none;
                    @media screen and(min-width: $desktop-bp) {
                        display: initial;
                    }
                }
            }

            .wrapper-ipd{
                display: flex;
                flex-direction: column;
                gap: 10px;

                p{
                    margin: 0;
                    padding: 0;
                }

                .adjust-ipd{
                    width: 100%;
                    align-items: center;

                    display: grid;
                    width: 100%;
                    grid-template-columns: 1fr 38px 1fr 38px 1fr;
                    justify-items: center;
                    gap: 5px;
                    
                    @media screen and(min-width: $desktop-bp) {
                        display: grid;
                        width: 100%;
                        grid-template-columns: 40px 38px 1fr 38px 40px;
                        justify-items: center;
                        gap: 5px
                    }
                
                    .btn-adjust{
                        width: 38px;
                        height: 38px;
                        border-radius: 5px;
                        border: 1px solid var(--black);

                        display: grid;
                        place-items: center;
    
                        svg{
                            width: 15px;
                            height: 15px;
                        }
                    }
                    p{
                        margin: 0;
                        padding: 0;
                    }
                    
                }
                
            }
            #icon-info{
                cursor: pointer;
                width: 20px;
                height: 20px;
                //display: none;
                @media screen and(min-width: $desktop-bp) {
                    display: block;
                }
            }
        }

        //css of filter-component on mobile
        .filter-wrapper-component.active{
            color-wrapper{
                width: 100%;
            }
            color-content:not(.active){
                display: initial;
            }
            color-content.active {
                // white checkbox for active color
                &::before {
                    content: " ";
                    position: absolute;
                    background: url(/assets/images/icons/_icon_checkbox_white.svg);
                    top: -12px;
                    right: -6px;
                    background-size: 49px 49px;
                    width: 49px;
                    height: 49px;
                }

            }
            // black checkbox for active color
            .jaune.active,
            .blanc.active,
            .transparent.active,
            .argent.active,
            .beige.active{
                &::before {
                background: url(/assets/images/icons/_icon_checkbox.svg);
                top: -12px;
                right: -6px;
                background-size: 49px 49px;
                width: 49px;
                height: 49px;
                }
            }
            top-label,  bottom-label {
                display: initial;
            }
            .tab-shade:not(.selected) {
                display: block;
            }
        }

        //css of filter-component on mobile and desktop
        .filter-wrapper-component:not(.unique):not(.active){
            cursor: pointer;
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
            @media screen and(min-width: $desktop-bp) {
                cursor: initial;
                box-shadow: none;
            }
            // click effect on mobile
            &:active{
                transform: scale(0.95);
                @media screen and(min-width: $desktop-bp) {
                    transform: none;
                }
            }
        }
        .filter-wrapper-component:not(.active){
            padding: 10px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @media screen and(min-width: $desktop-bp) {
                flex-direction: column;
                padding: 5px;
            }
            h3{
                @media screen and(min-width: $desktop-bp) {
                    margin-right: 0;
                }
                svg{
                    display: none;
                }
            }
        }
        /**********************
            shade filter
        ********************* */
        
        .tab-shades-wrapper{
            p {
                margin: 0;
                padding: 0;
            }
            .tab-shades-icons{
                flex-wrap: wrap;
                .break{
                    flex-basis: 100%;
                    height: 0;
                }
            }
            .tab-shade:not(.selected){
                svg{
                    display: none;
                }
            }
            .tab-shade img{
                z-index: 0;
            }
            .selected {
                position: relative;
            }
            .selected svg {
                z-index: 2;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
            }
            .tab-shade:not(.selected){
                cursor: pointer;
                display: none;
                @media screen and(min-width: $desktop-bp) {
                    display: initial;
                }
            }
        }
        // hide shade checkbox on mobile but display it on desktop
        .filter-wrapper-component:not(.active){
            .tab-shade.selected{
                svg {
                    display: none;
                    @media screen and(min-width: $desktop-bp) {
                        z-index: 2;
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
            .wrapper-ipd{
                width: 100%;
                display: none;
                @media screen and(min-width: $desktop-bp) {
                    display: flex;
                }
            }        
        }
    }
    // hide-xs = hide on mobile but not on desktop
    .filter-wrapper-component.hide-xs{
        display: none;
        @media screen and(min-width: $desktop-bp) {
            display: flex;
        }
    }
    /**********************
        Next button
    ********************* */
    .btn_small_3{
        margin-top: auto;
        position: absolute;
        bottom: 5px;
        left: 5px;
        margin-top: 10px;
        width: calc(100vw - 10px);
        @media screen and(min-width: $desktop-bp) {
            width: initial;
            margin-top: auto; 
            position: initial;
            bottom: initial;
            left: initial;
        }
    }
    //mobile next btn : obsolete
    .btn_small{
        position: absolute;
        bottom: 5px;
        left: 5px;
        width: calc(100vw - 10px);
        background: $background-mobile-vto;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
        &:hover{
            background: var(--black);
        }
    } 
}

/**********************
    close filter button
********************* */
.close-filter{
    position: absolute;
    display: initial;
    top: -18px;
    right: -20px;
    cursor: pointer;
    @media screen and(min-width: $desktop-bp) {
        display: none;
    }
}

.waiting-screen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: var(--grey_light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--camptonsemibold);
    font-size: 22px;
    @media screen and (min-width: $desktop-bp) {
        position: absolute;
        width: inherit;
        height: inherit;
    }
}

.toast{
    position: absolute;
    left: 10px;
    top: -65px;
    bottom: 250px;
    z-index: 100;
    width: 150px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--camptombook);
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    background: $background-mobile-vto;
    color: var(--black);
    @media screen and (min-width: $desktop-bp) {
        display: none;
    }

    .toast-triangle {
        position: absolute;
        bottom: -10px; 
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $background-mobile-vto;
    }
}

.toast-move-glasses{
    position: absolute;
    bottom: 10px;
    left: 25%;
    transform: translateX(-25%); 
    z-index: 100;
    height: min-content;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--camptombook);
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    background: $background-mobile-vto;
    color: var(--black);
    @media screen and (min-width: $desktop-bp) {
        display: flex;
    }
}
