.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: auto !important;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.bootstrap-select {
  cursor: pointer;
  display: flex;
  position: relative;
}

.bootstrap-select .btn {
  background: initial !important;
  border: 1px solid initial !important;
  color: initial !important;

  &:hover {
    background: initial !important;
    border: 1px solid initial !important;
    color: initial !important;
  }
}

.bootstrap-select select {
  display: none !important;
}

.bootstrap-select .dropdown-menu.show {
  height: auto;
  // taille max des selects (date inputs, countries, beneficiaries)
  max-height: 490px;
}

.bootstrap-select [aria-expanded="false"] ~ .dropdown-menu {
  display: none !important;
}

/* .bootstrap-select .dropdown-menu.show > .inner.show {
    overflow: hidden !important;
} */

.bootstrap-select .dropdown-menu {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bootstrap-select button {
  border: 1px solid rgba(44, 46, 53, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 30px 16px 20px;
  width: 100%;
  line-height: 1.2;

  @media screen and (max-width: 479px) {
    padding: 15px 20px 15px 5px;
  }

  &[aria-expanded="false"] {
    border: 1px solid rgba(44, 46, 53, 0.2);

    &:after {
      transform: rotate(0deg);
      transition: all 0.25s ease-out;
    }
  }

  &:after {
    content: " ";
    background: url("/assets/images/icons/_icon_filtre_chevron.svg") no-repeat;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    transition: all 0.25s ease-in-out;
    right: 0;
    position: absolute;
  }
}

.bootstrap-select .dropdown-menu {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 100%;
  overflow: auto;
  width: 100%;
  flex-direction: column;
  height: 0;
  transition: height 0.25s ease-out;
  background-color: white;
  z-index: 1;
}

.bootstrap-select .dropdown-menu.show {
  border: 1px solid rgba(44, 46, 53, 0.2);

  > div {
    overflow: hidden !important;
  }
}

.bootstrap-select .dropdown-menu.inner li {
  display: flex;
  font-weight: normal;
  font-family: var(--camptonbook);
  font-size: initial;
  text-transform: none;
  background-color: white;
  color: var(--black);

  a {
    padding: 16px 20px;
    width: 100%;
  }
}

.bootstrap-select .dropdown-menu.inner {
  border: none;
}

.bootstrap-select .dropdown-menu.inner li:hover {
  background-color: var(--grey_light);
}

.bootstrap-select .dropdown-menu.inner li.active,
.bootstrap-select .dropdown-menu.inner li.selected {
  background-color: var(--black);
  color: #fff;
}
