@import "assets/styles/scss/config/globals";
@import "assets/styles/scss/config/variables";

$hero-yellow: var(--banner-yellow);
$hero-text-dark: var(--banner-text-dark);
$hero-text-green: var(--banner-text-green);
$hero-btn-green: var(--banner-btn-green);

@mixin hero-text-base {
  margin: 0;
  line-height: 1;
}

// Breakpoint mixins
@mixin tablet {
    @media screen and (min-width: $mobile-bp) and (max-width: ($desktop-bp - 1)) {
      @content;
    }
  }
  
  @mixin desktop {
    @media screen and(min-width: $desktop-bp) {
      @content;
    }
  }

@mixin hero-description {
  @include hero-text-base;
  font-style: normal;
  
  @include tablet {
    font-size: 22px;
  }
  
  @media screen and (min-width: $desktop-bp) {
    font-size: 25px;
  }
}

hero-wrapper{
    max-width: none;
    width: 100vw;
    background-color: $hero-yellow;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @include tablet {
        height: 300px;
    }

    .hero-banner-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-inline: 32px;
        gap: 24px;
        margin-inline: 0;

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0;
            padding: 0;
        }

        @include desktop{
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .hero-banner-visual {

            picture{
                display: none;
            }

            // Visual mobile
            picture:nth-of-type(5){
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 0;
                width: 60%;
                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }
                

                @include tablet {
                    display: none;
                }
                @include desktop{
                    display: none;
                }
            }

            // Visual tablet
            picture:nth-of-type(4) {
                @include desktop{
                    display: none;
                }
            }

            @include tablet {
                
                overflow: hidden;
                position: relative;
                width: 100%;
                height: 100%;
                
                picture:nth-of-type(4) {
                    display: block;
                    margin: 0;
                    padding: 0;
                    border: none;
                    position: absolute;
                    top: 0;
                    right: 0;

                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: right center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: right center;
                        max-width: 653px;
                        max-height: 408px;
                        margin-left: auto;
                    }
                }
            }

            @include desktop{
                overflow: hidden;
                position: relative;
                display: flex;
                justify-content: flex-end;
                width: 50%;
                height: 100%;
                max-width: 653px;
                max-height: 408px;
                margin-left: -6rem;
                
                picture {
                    display: block;
                    margin: 0;
                    padding: 0;
                    border: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    opacity: 0;
                    animation: slideIn 15s infinite;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: right center;
                        max-width: 653px;
                        max-height: 408px;
                    }

                    &:nth-child(1) {
                        animation-delay: 0s;
                    }
                    &:nth-child(2) {
                        animation-delay: 5s;
                    }
                    &:nth-child(3) {
                        animation-delay: 10s;
                    }
                }

                @keyframes slideIn {
                    0% { 
                        opacity: 0;
                        transform: translateX(100%);
                    }
                    5% { 
                        opacity: 1;
                        transform: translateX(0);
                    }
                    30% { 
                        opacity: 1;
                        transform: translateX(0);
                    }
                    35% { 
                        opacity: 0;
                        transform: translateX(-100%);
                    }
                    100% { 
                        opacity: 0;
                        transform: translateX(-100%);
                    }
                }
            }
        }
    
        .hero-banner-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 16px;
            z-index: 1;

            @include tablet {
                margin-left: -50px;
            }

            .hero-banner-title {
                font-size: 32px;
                .hero-banner-title-desktop{
                    display: none;
                }
                .hero-banner-title-tablet{
                    display: none;
                }

                @include tablet {
                    font-size: 28px;
                    .hero-banner-title-desktop{
                        display: none;
                    }
                    .hero-banner-title-tablet{
                        display: block;
                    }
                    .hero-banner-title-mobile{
                        display: none;
                    }
                }
                @include desktop{
                    font-size: 45px;
                    .hero-banner-title-desktop{
                        display: block;
                    }
                    .hero-banner-title-tablet{
                        display: none;
                    }
                    .hero-banner-title-mobile{
                        display: none;
                    }
                }
                color: #fff;
                font-family: "Montserrat600";
                font-style: normal;
                font-weight: 600;
                margin: 0;
                letter-spacing: 1px;
                text-transform: uppercase;
                line-height: 1.1;
            }
            .hero-description1{
                @include hero-description;
                font-family: "Montserrat600";
                font-weight: 600;
                text-transform: uppercase;
                color: $hero-text-dark;
            }
            .hero-description2{
                @include hero-description;
                font-family: "Montserrat500";
                font-weight: 500;
                color: $hero-text-green;
            }
            .hero-banner-btn {
                // Variant green of by button.scss
                padding: 8px 16px;
                width: fit-content;
                height: fit-content;
                display: inline-block;
                margin-bottom: 0;
                border: 1px solid #1c922b;
                color: #fff !important;
                background: #1c922b;
                font-family: "Montserrat600";
                font-size: 14px;
                font-weight: normal;
                text-transform: uppercase;
                text-decoration: none !important;
                line-height: 1.2;
                text-align: center;
                cursor: pointer;
                user-select: none;
                transition: all 0.5s ease-in-out;
                appearance: none;
                position: relative;
                letter-spacing: 3px;
    
                &.is_disabled {
                    pointer-events: none;
                    background: rgb(28, 147, 43, 1) !important;
                    color: #fff !important;
                    border: none !important;
    
                    &:hover,
                    &:focus {
                        background: rgb(28, 147, 43, 1) !important;
                        color: #fff !important;
                        border: none !important;
                    }
                }
    
                &:hover,
                &:focus {
                    background: #fff;
                    border: 1px solid var(--black);
                    color: var(--black) !important;
                }
            }
        }
    }

}