@import "assets/styles/scss/config/globals";

.block-add-doctor,
.optical_folder,
.block-add-beneficiary {
    >ul {
        padding: 25px 30px;
        margin-bottom: 20px;
        background-color: white;

        @media screen and (max-width: $mobile-bp) {
            padding: 25px 20px;
        }

        &:last-of-type {
            margin-bottom: 40px;
        }

        li {
            >form {
                padding-top: 30px;
            }
        }
    }
    .hide-li{
        height: 0;
        overflow: hidden;
        transition: height 0.2s ease-out;
    }
    li.accordion_responsive {
        cursor: pointer;
        position: relative;

        &:after {
            content: "+";
            position: absolute;
            right: 0px;
            top: -5px;
            font-size: 32px;
            font-family: var(--camptonbook);
            font-weight: 400;
            color: var(--black);
        }
    }

    li.accordion_responsive.is_active {

        &:after {
            content: "-";
            position: absolute;
            right: 0px;
            top: -5px;
            font-size: 35px;
            font-family: var(--camptonbook);
            font-weight: 400;
            color: var(--black);
        }
    }

    optic-folder-error-wrapper {
        color: red;
        line-height: 1.2;
    }

    button-wrapper {
        display: flex;
        justify-content: flex-end;
        // TODO V2: à décommenter (supprimer un bénéficiaire)
        // justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        height: 100%;

        .delete {
            color: var(--red);
            text-transform: uppercase;
            font-family: var(--camptonbook);
            font-size: 14px;
            font-weight: 400;

            @media screen and (max-width: $mobile-bp) {
                padding-top: 30px;
            }
        }

        @media screen and (max-width: $mobile-bp) {
            flex-direction: column-reverse;
            padding-bottom: 15px;
        }
    }

    .account_owner {
        display: flex;
        font-family: var(--camptonsemibold);
        font-weight: 400;
        font-size: 18px;

        @media screen and (max-width: $mobile-bp) {
            flex-direction: column;
        }

        div {
            position: relative;

            &:first-child {
                color: var(--red);

                @media screen and (max-width: $mobile-bp) {
                    margin-bottom: 10px;
                }
            }

            &:last-of-type {
                padding-left: 20px;

                @media screen and (max-width: $mobile-bp) {
                    padding-left: 0;
                }

                &:before {
                    content: "-";
                    position: absolute;
                    left: 5px;
                    top: 0px;
                    font-size: 20px;
                    font-family: var(--camptonbook);
                    font-weight: 400;
                    color: var(--black);

                    @media screen and (max-width: $mobile-bp) {
                        display: none;
                    }
                }
            }
        }
    }

    h1 {
        margin-bottom: 20px;
    }

    introduction-wrapper {
        display: inline-block;
        margin-bottom: 35px;
        line-height: 1.4;
        font-family: var(--camptonbook);
        font-size: 18px;
        font-weight: 400;

        a {
            text-decoration: underline;
        }
    }

    .form_row {
        display: flex;
        flex-direction: row;
        padding-bottom: 25px;
        width: 100%;

        @media screen and (min-width: $desktop-bp) {
            width: 90%;
        }

        >div {
            display: flex;
            flex-direction: column;
            flex: 1 0 0;
            margin-right: 20px;

            @media screen and (max-width: $mobile-bp) {
                margin-right: 10px;
                width: calc((100% / 3) - 10px);
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        &.by_three {
            label {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 400;
                font-family: var(--camptonbook);
            }
        }

        &.by_two {
            @media screen and (max-width: $mobile-bp) {
                flex-direction: column;
            }

            label {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 400;
                font-family: var(--camptonbook);
            }

            >div {
                @media screen and (max-width: 1269px) {
                    width: calc(50% - 10px);
                }

                @media screen and (max-width: $mobile-bp) {
                    padding-left: 0;
                    width: 100%;
                    margin: 0 0 15px 0;
                }
            }
        }

        &:last-of-type {
            padding-bottom: 50px;

            @media screen and (max-width: $mobile-bp) {
                padding-bottom: 30px;
            }
        }

        sup {
            font-size: 100%;
        }

        .bootstrap-select button {
            border: 1px solid rgba(44, 46, 53, 0.2);
        }

        .bootstrap-select .dropdown-menu.inner li,
        .filter-option {
            font-size: 18px;
            font-family: var(--camptonbook);
            font-weight: 400;
            text-transform: none;
        }

        .bootstrap-select .dropdown-menu.inner {
            height: 250px;
        }

        input {
            border: 1px solid rgba(44, 46, 53, 0.2);
            padding: 16px 20px;
            font-family: var(--camptonbook);
            font-size: 18px;
            font-weight: 400;
        }

        input[type="radio"].custom_radio:checked~label::before {
            border: 2px solid #00000012;
        }

        input[type="radio"].custom_radio:checked~label::after {
            width: 16px;
            height: 16px;
            left: 7px;
        }

        &.civility {
            display: flex;
            flex-direction: column;

            div {

                &:first-of-type {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    >label {
                        display: flex;
                    }
                    
                    @media screen and (max-width: 460px) {
                        flex-direction: row;
                        align-items: flex-start;
                        >label {
                            &:first-of-type {
                                padding-right: 10px;
                            }
                        }

                        >div {
                            padding-left: 0 !important;
                        }
                    }

                    div {
                        padding-top: 5px;

                        &:first-of-type {
                            padding-left: 0px;
                        }

                        &:last-of-type {
                            padding-left: 40px;
                        }
                    }
                }
                &:last-of-type {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    >label {
                        display: flex;
                    }
                }
            }
        }
    }

    block-title {
        display: flex;
        padding-bottom: 15px;
    }

    >.btn_3 {
        display: block;
        margin: 0 auto;
        &#submit_newBeneficiary{
            margin-bottom: 120px;
        }
    }

    .form_column {
        display: flex;
        flex-direction: column;
        width: 90%;

        >div {
            display: flex;
            flex-direction: column;
            flex: 1 0 0;
            margin-bottom: 25px;

            label {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 400;
                font-family: var(--camptonbook);
            }
        }

        sup {
            font-size: 100%;
        }

        input {
            border: 1px solid rgba(44, 46, 53, 0.2);
            padding: 16px 20px;
            font-family: var(--camptonbook);
            font-size: 18px;
            font-weight: 400;
        }

        input[type="radio"].custom_radio:checked~label::before {
            border: 2px solid #00000012;
        }

        input[type="radio"].custom_radio:checked~label::after {
            width: 16px;
            height: 16px;
            left: 7px;
        }
    }
}

.block-add-doctor,
overlay-filter.optical_folder,
.block-add-beneficiary {
    padding-top: 55px;

    .form_column,
    .form_row {
        width: 100%;
    }

    form>.btn_3 {
        margin: 0 auto;
    }

    h2 {
        margin-bottom: 50px;
        font-family: var(--camptonsemibold);
        font-weight: normal;
        font-size: 22px;
        line-height: 1.2;
    }

    h3 {
        margin-bottom: 15px;
        line-height: 1.2;
        text-transform: uppercase;
        font-family: var(--camptonsemibold);
        font-size: 14px;
        font-weight: normal;
        color: var(--red);
    }

    head-filter img {
        height: 50px;
        width: 50px;
    }
}