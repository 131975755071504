//***************************
//***************************
// Variables
//***************************
//**************************

// CSS

:root {
	// Fonts
	--camptonbook: 'campton_book';
	--camptonmedium: 'campton_medium';
	--camptonsemibold: 'campton_semibold';
	--montserrat: 'montserrat';
	--montserratalternates: 'montserrat_alternates';

	// Colors
	--white: #FFFFFF;
	--green: #1C8A4A;
	--yellow: #F5C63A;
	--red: #D75931;
	--black: #2C2E35;
	--grey_anthracite: #212121;
	--grey_blue: #D2E1DE;
	--grey_blue_2: #EDF4F7;
	--grey_dark: #76777C;
	--grey: #ABABAE;
	--grey_light: #F6F6F6;
	--light_grey_blue: #D4E1DD;
	--light_icon_grey: #d9d1c8;
	--dark_grey_blue: #5EA0B4;
	--beige_dark: #CAC0B6;
	--beige: #D9D1C8;
	--grey_disabled: #F1F1F1;

	--banner-yellow: #fabe00;
	--banner-text-dark: #444444;
	--banner-text-green: #388238;
	--banner-btn-green: #1c922b;

	--silver: #D2D2D6;
	--beige2: #F9EDD6;
	--blue: #20417E;
	--caramel: #CC8000;
    --gold: #E0B119;
	--grey2: #9B9B9B;
	--yellow2: #FEF439;
	--brown: #681D03;
	--bordeaux: #6D071A;
	--orange: #FF8403;
    --pink: #E39CBD;
 	--red2: #E30A1C;
 	--purple: #9869D4;
	--transparent : #e6e6e6;
	--multicolore: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
}