@import "assets/styles/scss/config/globals";

input[type="checkbox"].custom_checkbox {
    display: none;
}

input[type="checkbox"].custom_checkbox~label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 40px;
    cursor: pointer;
    min-height: 30px;
    height: auto;
    font-size: 18px;

    @media screen and(max-width: $small-thr) {
        font-size: 16px;
    }
}

input[type="checkbox"].custom_checkbox~label::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid #00000012;
    border-radius: 4px;
}

input[type="checkbox"]:disabled~label {
    color: rgba(40, 40, 40, .4);
}

input[type="checkbox"].custom_checkbox:checked~label::before {
    border: 2px solid var(--black);
    background: url(/assets/images/icons/_icon_checkbox.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-size: 40px 40px;
    background-position: -10px;
    width: 30px;
    height: 31px;
}

/******************************************/

input[type="radio"].custom_radio {
    display: none;
}

input[type="radio"].custom_radio~label {
    position: relative;
    display: block;
    padding: 8px 0 0 40px;
    cursor: pointer;
    min-height: 30px;
    height: auto;
}

input[type="radio"].custom_radio~label::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid #00000012;
    border-radius: 50%;
}

input[type="radio"].custom_radio:checked~label::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    left: 7px;
    border-radius: 50%;
    background: var(--black);
}

input[type="radio"].custom_radio:checked~label::before {
    width: 30px;
    height: 30px;
    background-position: -10px;
}

.gender-wrapper
{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    div.form-row {       
        margin-bottom: 0 !important;
    
        label {
            margin-bottom: 10px;
            font-size: 16px;
            flex-direction: row !important; 
    
            @media screen and(max-width: $small-thr) {
                font-size: 14px;
            }
    
            sup {
                font-size: 16px;
    
                @media screen and(max-width: $small-thr) {
                    font-size: 14px;
                }
            }
        }
    
        .bootstrap-select .btn {
            font-family: var(--camptonbook);
        }
    
        .filter-option-inner-inner {
            color: var(--black);
            font-size: 18px;
            text-transform: none;
        }
    
        input[type='text'],
        input[type='password'],
        input[type='email'],
        input[type='tel'] {
            width: 400px;
            height: 55px;
            border: 1px solid rgba(44, 46, 53, 0.2);
            outline: none;
            font-family: var(--camptonbook);
            font-size: 21px;
            padding: 16px 20px;
    
            @media screen and(max-width: $small-thr) {
                width: 95%;
            }
        }
    
        .form-button {
            padding: 20px 55px;
            display: flex;
            width: fit-content;
            justify-content: center;
            margin: 0 auto;
        }
    
        &.radio-row {
            flex-direction: row;
        }
    }
}

div.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    label {
        margin-bottom: 10px;
        font-size: 16px;
        flex-direction: row !important; 

        @media screen and(max-width: $small-thr) {
            font-size: 14px;
        }

        sup {
            font-size: 16px;

            @media screen and(max-width: $small-thr) {
                font-size: 14px;
            }
        }
    }

    .bootstrap-select .btn {
        font-family: var(--camptonbook);
    }

    .filter-option-inner-inner {
        color: var(--black);
        font-size: 18px;
        text-transform: none;
    }

    input[type='text'],
    input[type='password'],
    input[type='tel'] {
        width: 400px;
        height: 55px;
        border: 1px solid rgba(44, 46, 53, 0.2);
        outline: none;
        font-family: var(--camptonbook);
        font-size: 21px;
        padding: 16px 20px;

        @media screen and(max-width: $small-thr) {
            width: 95%;
        }
    }

    input[type='email'] {
        width: 400px;
        height: 55px;
        border: 1px solid rgba(44, 46, 53, 0.2);
        outline: none;
        font-family: var(--camptonbook);
        font-size: 21px;
        padding: 16px 20px;

        @media screen and(max-width: $small-thr) {
            width: 100% !important;
        }
    }

    .new-account-email {
        display: none;
        width: 400px;
        height: 55px;
        border: 1px solid rgba(44, 46, 53, 0.2);
        outline: none;
        font-family: var(--camptonbook);
        font-size: 21px;
        padding: 16px 20px;

        @media screen and(max-width: $small-thr) {
            width: 100% !important;
        }

        @media screen and(min-width: $desktop-bp) {
            display: inherit;
        }
    }

    .form-button {
        padding: 20px 55px;
        display: flex;
        width: fit-content;
        justify-content: center;
        margin: 0 auto;
    }

    &.radio-row {
        flex-direction: row;
    }
}

div.error-message-wrapper {
    margin: 28px auto auto 0px;
    position: absolute;
}

ul.error-message {
    margin-bottom: 15px;
}

ul.error-message,
span.error-message,
.error-message {
    font-size: 12px;
    color: #d75931;
    margin-top: 5px;

    sup {
        font-size: 16px;
    }
}

.error-message-red {
    font-size: 12px;
    color: red;
    margin-top: 5px;
    margin-left: 40px;

    sup {
        font-size: 16px;
    }

    @media screen and(max-width: $mobile-bp) {
        margin-left: 0;
    }
}

.success_message {
    font-size: 12px;
    color: #155724;
    margin-top: 5px;

    sup {
        font-size: 16px;
    }
}

.alert_message {
    font-size: 12px;
    color: #ABABAE;
    margin-top: 5px;

    sup {
        font-size: 16px;
    }
}

overlay-filter form {
    min-height: auto;
    height: auto;
}

.padding_bottom-0 {
    padding-bottom: 0 !important;
}

#submit_newBeneficiary {
    margin-top: 25px;
    margin-bottom: 120px;
}

input[type="radio"].border-error~label::before {
    border: 1px solid var(--red) !important;
}

.border-error {
    border: 1px solid var(--red) !important;
}

@media screen and(max-width: $mobile-bp) {

    dashboard-wrapper dashboard-main #beneficiary_form card-wrapper wrapper-description {
        margin-right: 0;
    }
}