@import "assets/styles/scss/config/globals";

cookies-headband {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1000;
	display: none;
	align-items: center;
	justify-content: space-between;
	line-height: 1.2;
	padding: 1rem 3rem;
	background: rgba(44, 46, 53, 0.7);

    @media screen and(max-width: $mobile-bp) {
		flex-direction: column;
	}

	button {
		white-space: nowrap !important;
		line-height: 1;
	}

	.disclaimer {
		margin-bottom: 0;
		margin-right: 1.5rem;
		font-size: 18px;
		color: #fff;

        @media screen and(max-width: $mobile-bp) {
			font-size: 14px;
			margin-right: 0;
			margin-bottom: 1.5rem;
		}
	}
}
