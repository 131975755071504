@import "assets/styles/scss/config/variables";
@import "assets/styles/scss/config/globals";

// Variables
$banner-yellow: var(--banner-yellow);
$banner-text-dark: var(--banner-text-dark);

// Breakpoint mixins
@mixin tablet {
  @media screen and (min-width: $mobile-bp) and (max-width: ($desktop-bp - 1)) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-bp) {
    @content;
  }
}

// Text reset mixin (simplified)
@mixin text-reset {
    text-align: initial;
    text-transform: initial;
    font-style: initial;
    line-height: initial;
    letter-spacing: initial;
    text-decoration: initial;
    text-shadow: initial;
    text-indent: initial;
    text-overflow: initial;
    white-space: initial;
    word-spacing: initial;
    word-break: initial;
    word-wrap: initial;
}

.banner {
    
    max-width: none;
    width: 100vw;
    background-color: $banner-yellow;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 0px;
    padding: 0px;
    margin-bottom: 32px;

    @include tablet {
        height: 400px;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 32px;
        padding: 0px 150px 0px 32px;
        margin-bottom: 80px;
    }

    @include desktop {
        height: 400px;
        flex-direction: row;
        gap: 32px;
        padding: 0px 150px 0px 32px;
        margin-bottom: 80px;
    }

    .banner-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: $banner-text-dark;
        max-width: none;
        width: 100vw;
        padding: 32px 32px;

        @include tablet {
            width: 50%;
            background-color: transparent;
            padding: 0px;
        }

        @include desktop {
            width: 40%;
            background-color: transparent;
            max-width: auto;
            padding: 0px;
        }
    
        h1 {
            @include text-reset;
            font-family: "Montserrat500";
            font-style: normal;
            font-weight: 500;
            color: $banner-yellow;
            font-size: 22px;
            line-height: 1.1;
            margin: 0;
            padding: 0;

            @include tablet {
                color: white;
                font-size: 40px;
            }

            @include desktop {
                color: white;
                font-size: 48px;
            }
          }
          
        h2 {
        @include text-reset;
        font-family: "Montserrat300";
        font-style: normal;
        font-weight: 300;       
        color: white;
        font-size: 20px;
        margin: 0;
        padding: 0;
        text-transform: none;
        text-align: none;

        @include tablet {
            color: $banner-text-dark;
            font-size: 24px;
            font-family: "Montserrat500";
            font-weight: 500;
        }

        @include desktop {
            color: $banner-text-dark;
            font-size: 30px;
            font-family: "Montserrat500";
            font-weight: 500;
        }
        }
        
        p {
        @include text-reset;
        display: none;

        font-family: "Montserrat400";
        font-style: normal;
        font-weight: 400;
        color: $banner-text-dark;
        font-size: 24px;
        margin: 0;
        padding: 0;
        text-transform: none;
        text-align: none;

        @include tablet {
            display: block;
            font-size: 16px;
        }

        @include desktop {
            display: block;
            font-size: 16px;
        }
        
        }
    }
    
    .banner-image {
        overflow: hidden;
        position: relative;
        height: 100%;
        max-width: 653px;
        max-height: 408px;

        @include tablet {
            margin-right: -50rem;
            object-fit: cover;
        }

        @include desktop {
            margin-right: -30rem;
            object-fit: cover;
        }
    }
    
}
