@import "assets/styles/scss/config/globals";

footer-like,
footer {
	max-width:100%;
	margin-top: 20px;
	margin-inline: auto;
	font-size: 1.6rem;
	font-family: var(--camptonbook);
	font-weight: normal;
	@media screen and(min-width: $desktop-bp) {
		max-width: $desktop-max-width;
	}
}
footer {
	padding-inline: 24px;
	@media screen and(min-width: $desktop-bp) {
	 	padding-inline: 0;
	}
	@media screen and(max-width: $mobile-bp) {
		padding-inline: 0;
	}
}

benefit-separator{
	height: 40px;
	width: 1px;
	@media screen and(min-width: $desktop-bp) {
		height: 104px;
	}
	@media screen and(max-width: $mobile-bp) {
		height: 0;
		width: 0;
		padding-top: 16px;
	}
}
footer-labels, 
footer-contact, 
footer-terms {
	display: flex;
	background-color: var(--black);
	> .footer-contact-wrapper,
	> .labels-wrapper,
	> .terms-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		@media screen and(max-width: $mobile-bp) {
			align-items: flex-start;
		}	
	}
}

footer-labels {
	min-height: 152px;
	height: auto;
	> .labels-wrapper {
		padding: 24px;
		justify-content: space-around;
		@media screen and(max-width: $mobile-bp) {
			flex-direction: column;
		}
	}
label-block{
	text-align: center;
	max-width: 144px;
	@media screen and(min-width: $desktop-bp) {
		max-width: 256px;
	}
	@media screen and(max-width: $mobile-bp) {
		max-width: 100%;
	}
}
	label-block div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		font-size: 14px;
		line-height: 16.3px;
		letter-spacing: -0.02em;
		text-align: center;
		color: var(--white);
		@media screen and(min-width: $desktop-bp) {
			font-size: 16px;
			line-height: 21.86px;
		}
		@media screen and(max-width: $mobile-bp) {
			flex-direction: row;
			position: relative;
			margin-top: 0;
			>span:first-child {
				width: 90px;
			}
			margin-top: 20px;
		}

		&:hover{
			span{
				opacity: 0.5;
			}
		}
		picture{
			margin-left: 0;
		}
		
		img {
			cursor: pointer;
			height: 60px;
			width: auto;
			object-fit: cover;
			@media screen and(max-width: $mobile-bp) {
				width: auto;
				height: 60px;
				left: 0;
			}
		}

		b,
		strong {
			font-family: var(--camptonsemibold);
			font-weight: normal;
		}

		p {
			width: 112px;
			display: flex;
			flex-direction: column;
			align-items: center;
			line-height: 1.2;
			cursor: pointer;
			span{
				font-family: var(--camptonbook);
			}
			@media screen and(min-width: $desktop-bp) {
				width: 170px;
			}
			@media screen and(max-width: $mobile-bp) {
				margin-left: 20px;
				margin-right: 0;
				align-items: flex-start;
				width: 100%;
			}
		}
	}
}

footer-contact {
	position: relative;
	flex-direction: column;
	background: var(--grey_light);
	> .footer-contact-wrapper {
		flex-direction: column;
		padding-top: 30px;
		@media screen and(min-width: $desktop-bp) {
			flex-direction: row;
			padding-top: 45px;
		}
	}

	reviews-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		padding: 0 24px;
		text-align: center;

		@media screen and(min-width: $desktop-bp) {
			padding: 0 24px;
			width: calc(100% / 3);
			align-items: inherit;
			text-align: inherit;
		}

		follow-content {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 40px;
			font-family: var(--camptonsemibold);

			@media screen and(max-width: $mobile-bp) {
				display: none;
			}

			a {
				margin-right: 10px;
				width: 40px;

				&:first-of-type {
					margin-left: 20px;
				}
			}

			img {
				object-fit: contain;
			}
		}

		> a {
			width: 150px;
			margin-bottom: 20px;

			@media screen and(max-width: $mobile-bp) {
				width: 110px;
			}
		}

		p {
			color: #8c8c8c;
		}

		reviews-content {
			display: flex;
			align-items: center;
			font-family: var(--camptonsemibold);
			color: var(--black);
			cursor: pointer;

			@media screen and(max-width: $mobile-bp) {
				display: none;
			}
			
			img {
				width: 65px;
				margin-right: 15px;
			}
		}
	}

	.right-side-reviews {
		@media screen and(min-width: $desktop-bp) {
			padding-right: 0;
			padding-left: 15px;
		}
	}

	.franchise {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 30px;
		min-height: 50px;
		padding-left: 30px;
		font-family: var(--camptonsemibold);
		font-size: 1.6rem;
		background: var(--yellow);
		color:var(--black);
		//width: calc(100% + 60px);
		//margin-left: -30px; SENZOOOOOOOO >:C

		@media screen and(min-width: $desktop-bp) {
			width: 100%;
			padding-right: 0px;
			margin-left: auto;
		}

		&:after {
			content: ' ';
			background: url('/assets/images/icons/_icon_filtre_chevron.svg') no-repeat;
			background-size: 30px 30px;
			width: 30px;
			height: 30px;
			transform: rotate(-90deg);
		}
	}
	
	ul.links {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding-left: 0;
		
		@media screen and(min-width: $desktop-bp) {
			width: calc(100% / 3);
			padding-left: 45px;
		}
		
		li {
			font-family: var(--camptonsemibold);
			margin-bottom: 10px;

			@media screen and(max-width: $mobile-bp) {
				display: none;
			}

			&:last-of-type {
				@media screen and(max-width: $mobile-bp) {
					display: block;
				}
			}

			.btn {
				margin-top: 10px;

				@media screen and(max-width: $mobile-bp) {
					margin-bottom: 20px;
				}
			}
		}
	}

	.contact-franchise-button-desktop {
		@media screen and(min-width: $desktop-bp){
			display: contents;
			width: 100%;
		}

		> a{
			display: flex;
			justify-content: flex-end;
			padding-right: 10px;
			@media screen and(min-width: $desktop-bp) {
				padding-right: 0;
			}
		}
	}
	
	contact-wrapper {
		width: 100%;
		padding: 0 24px;
		@media screen and(min-width: $desktop-bp) {
			width: calc(100% / 3);
			padding: 0;
		}
		
		contact-title {
			display: block;
			text-align: center;
			margin-bottom: 10px;
			line-height: 1.1;
			width: 100%;
			font-family: var(--camptonsemibold);
			font-size: 2.4rem;

			@media screen and(min-width: $desktop-bp) {
				display: flex;
				margin-bottom: 15px;
			}

			@media screen and(max-width: $mobile-bp) {
				font-size: 2.2rem;
			}
		}

		contact-global {
			display: flex;
			justify-content: center;

			@media screen and(min-width: $desktop-bp) {
				justify-content: space-between;
			}

			@media screen and(max-width: 350px) {
				flex-direction: column;
				align-items: center;
			}
		}
		
		contact-content {
			display: flex;
			align-items: center;
			font-family: var(--camptonsemibold);
			font-size: 1.6rem;

			a {
				display: flex;
				align-items: center;
			}

			img {
				margin-right: 10px;

				@media screen and(max-width: $mobile-bp) {
					width: 30px;
				}
			}

			&:last-of-type {
				white-space: nowrap;

				img{
					margin-left: 15px;

					@media screen and(min-width: $desktop-bp) {
						margin-left: 0;
					}
				}

				@media screen and(max-width: 350px)  {
					margin-left: 0;
					margin-top: 10px;
				}
			}
		}

		.contact-right {
			@media screen and(min-width: $desktop-bp) {
				margin-right: 20px;
			}
		}

		> p {
			clear: both;
			font-size: 1.6rem;
			color: #8c8c8c;
			text-align: center;

			@media screen and(min-width: $desktop-bp) {
				text-align: inherit;
			}

			b,
			strong {
				font-family: var(--camptonsemibold);
				font-size: 1.6rem;
				font-weight: normal;
				color: var(--black);
			}
		}
	}
}

footer-terms {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	line-height: 1;
	font-family: var(--camptonmedium);
	font-size: 1.4rem;
	color: var(--grey);
	background: var(--white);
	padding-inline: 24px;

	@media screen and(min-width: $desktop-bp){
		margin-top: 0px;
	}

	.terms-wrapper {
		display: flex;
		justify-content: space-between;

		@media screen and(max-width: $mobile-bp) {
			flex-direction: column;
		}
	}
	
	.legal_terms {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		@media screen and(max-width: $mobile-bp) {
			margin-bottom: 15px;
		}
		
		li {
			display: flex;
			align-items: center;

			&:after {
				content: "";
				display: block;
				width: 5px;
				height: 1px;
				background-color: var(--grey);
				margin: 0 5px;
			}

			&:last-of-type {
				&:after {
					display: none;
				}
			}
		}
	}

	.links_terms {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		li {
			display: flex;
			align-items: center;

			&:after {
				content: "";
				display: block;
				width: 1px;
				height: 10px;
				background-color: var(--grey);
				margin: 0 5px;
			}

			&:last-of-type {
				&:after {
					display: none;
				}
			}
		}
	}
}

