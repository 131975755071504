@import "assets/styles/scss/config/globals";

.js_overflow {
  overflow: hidden;
}

more-content {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 110px !important;

  button.btn_3 {
    @media screen and (min-width: 376px) {
      padding: 15px 70px;
    }
  }
}

sort-results {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px !important;

  @media screen and (max-width: $mobile-bp) {
    display: flex;
    flex-direction: column-reverse;
  }

  results-number {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: normal;
    font-family: var(--camptonbook);

    @media screen and (max-width: $mobile-bp) {
      font-size: 22px;
    }

    b,
    strong {
      margin-right: 10px;
      font-family: var(--camptonsemibold);
    }
  }

  sort-by {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    font-family: var(--camptonmedium);
    text-transform: uppercase;
    color: rgba(44, 46, 53, 0.5);

    .sort-by-label {
      text-align: right;
      margin-right: 5px;
    }

    beautified-select {
      margin-left: 10px;
    }

    .bootstrap-select .dropdown-menu.inner li {
      text-transform: uppercase;
      font-size: 14px;
    }

    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: 200px !important;
    }

    .bootstrap-select>.dropdown-menu.show,
    .bootstrap-select button {
      border: 1px solid var(--black);

      &[aria-expanded="false"] {
        border: 1px solid var(--black);
      }
    }

    @media screen and (max-width: $mobile-bp) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 40px;
      z-index: 10;
    }

    //taille de la liste "trier par"
    .bootstrap-select .dropdown-menu.show {
      height: auto;
      max-height: 220px;
      top: -1px;
    }
  }
}

tags-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px !important;

  @media screen and (max-width: $mobile-bp) {
    display: none;
  }

  tag-choice {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 30px 5px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    font-family: var(--camptonbook);
    font-size: 14px;
    color: var(--black);
    background: var(--grey_light);

    &::after {
      content: " x";
      position: absolute;
      right: 10px;
      font-size: 16px;
    }
  }
}

.erase-button-wrapper {
  padding: 0 25px;
  text-align: right;
  margin-bottom: 10px;

  .erase {
    flex: 0 1 120px;
    margin-bottom: 10px;
    margin-left: auto;
    text-decoration: underline;
    font-size: 14px;
    color: rgba(44, 46, 53, 0.4);
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-self: center;
  padding: 4px 30px;
  max-height: 50px;
  text-transform: uppercase;
  font-family: var(--camptonmedium);
  font-size: 14px;
  background: var(--black);
  color: #fff;

  @media screen and (max-width: $mobile-bp) {
    width: 100%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }

  img {
    width: 45px;
  }
}

.listing_products {
  .bootstrap-select .dropdown-menu.inner {
    overflow: hidden;
  }

  cms-fifty-image .btn_2 {
    @media screen and (min-width: 376px) {
      padding: 15px 70px;
    }
  }

  cms-fifty-image {
    margin-bottom: 20px !important;
  }

  .cards_wrapper {
    &:last-of-type {
      margin-bottom: 35px;
    }
  }

  nav-tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    line-height: 1.2;
    font-family: var(--camptonbook);
    font-size: 22px;

    @media screen and (max-width: $mobile-bp) {
      margin-bottom: 20px !important;

      >span {
        display: none;
      }
    }
  }

  nav-tabs .nav {
    display: flex;

    @media screen and (max-width: $mobile-bp) {
      display: none;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      padding: 15px 30px;
      max-height: 50px;
      margin-right: 20px;
      text-transform: uppercase;
      font-family: var(--camptonmedium);
      font-size: 14px;
      border: 2px solid rgba(44, 46, 53, 0.4);
      color: rgba(44, 46, 53, 0.4);

      &:first-of-type {
        margin-left: 15px;
      }
    }

    li.active_tab {
      position: relative;
      border: 2px solid var(--black);
      color: var(--black);

      &:after {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        background: url(/assets/images/icons/_icon_checkbox_white.svg),
          var(--black) no-repeat;
        background-size: auto;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .general_card {
    title-product {
      width: 100%;
    }

    img {
      width: 100%;
      object-fit: contain;
      height: auto;
      max-height: 168px;
      background-position: center;
    }

    img.icon_top {
      width: 55px;
      margin-top: 0;
      object-fit: contain;
    }

    .btn_small {
      margin-top: auto;
    }
  }
}

wrapper-results {
  display: none;

  &.show_results {
    display: block;
    padding: 0;
  }

  empty-listing {
    display: flex;
    text-align: center;
    justify-content: space-around;
    font-size: 24px;
    margin: 120px 40px 120px 40px;

    a {
      text-decoration: underline;

      &:hover {
        font-weight: bold;
      }
    }
  }
}

.category_responsive {
  display: none;

  @media screen and (max-width: $mobile-bp) {
    display: flex;
  }
}

/********************************************************************************/
wrapper-switch {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  font-family: var(--camptonmedium);

  @media screen and(max-width: $mobile-bp) {
    display: none;
  }

  span {
    color: rgba(44, 46, 53, 0.3);

    &:first-child {
      margin-right: 5px;
      text-align: right;
    }

    &:last-of-type {
      margin-left: 5px;
      margin-right: 5px;
    }

    &.active {
      color: var(--black);
    }
  }
}

.button_switch {
  height: 40px;
  position: relative;
  min-width: 165px;

  @media screen and (max-width: $mobile-bp) {
    display: none;
  }

  label {
    position: relative;
    pointer-events: none;
  }

  .img-face {
    margin-top: 2px;
  }

  .lbl_off,
  .lbl_on {
    /* cursor: pointer; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    line-height: 1;
    position: absolute;
    top: 7px;
    transition: opacity 0.25s ease-out 0.1s;
    text-transform: uppercase;
  }

  .lbl_on {
    color: #fff;
    opacity: 0;
  }

  .switch {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 0;
    font-size: 1em;
    left: 0;
    line-height: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 0;

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      border-radius: 26px;
      background: var(--black);
      height: 40px;
      left: -4px;
      top: -4px;
      transition: background-color 0.25s ease-out 0.1s;
      width: 165px;
      cursor: pointer;
    }

    &:after {
      border-radius: 26px;
      background: #fff;
      height: 32px;
      transform: translate(0, 0);
      transition: transform 0.25s ease-out 0.1s;
      width: 80px;
    }

    &:checked {
      &:after {
        transform: translate(77px, 0);
      }

      &~.lbl_off {
        opacity: 0;
      }

      &~.lbl_on {
        opacity: 1;
      }
    }
  }
}

/*****************************************/
.range {
  display: flex;
  flex-direction: column;

  range-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    input {
      width: 75px;
      height: 50px;
      padding: 0 1.5rem;
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      font-family: var(--camptonbook);
      border: 1px solid #cdcecf;
    }
  }

  input-range {
    display: flex;
    position: relative;
    width: 100%;
    height: 33px;
    margin-bottom: 40px;

    input-rail {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background-color: var(--black);
      border-radius: 3px;
    }

    input-cursor {
      position: absolute;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      background-color: var(--black);
      cursor: pointer;
      box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.1);
    }
  }
}

/*****************************************/

/********************************/
.overlay {
  overlay-background {
    visibility: hidden;
    opacity: 0;
  }
}

overlay-filter {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  z-index: 101;
  width: 450px;
  left: 100%;
  top: 0;
  bottom: 0;
  padding: 20px 40px 40px 40px;
  max-width: 100%;
  min-height: 100%;
  transition: 0.5s;
  background-color: #fff;
  box-shadow: none;

  @media screen and (max-width: $mobile-bp) {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }

  @media screen and (max-width: 460px) {
    width: 100% !important;
  }

  .menu {
    .has_choices {
      font-family: var(--camptonmedium);
      font-size: 14px;
      text-transform: uppercase;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 45px;
      padding-right: 40px;
      border-bottom: 1px solid rgba(44, 46, 53, 0.2);

      &:after {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        background: url(/assets/images/icons/_icon_filtre_chevron.svg) no-repeat;
        background-size: auto;
        background-size: 40px 40px;
        width: 40px;
        height: 40px;
        transform: rotate(0deg);
        transition: all 0.25s ease-in-out;
      }

      &.is_active {
        border-bottom: 0;

        &:after {
          transform: rotate(180deg);
          transition: all 0.25s ease-out;
        }
      }
    }

    .has_choices.is_active~.is_submenu {
      border-bottom: 1px solid rgba(44, 46, 53, 0.2);
    }

    .is_submenu.nav {
      display: none;

      @media screen and (max-width: $mobile-bp) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 15px 30px;
          margin-right: 20px;
          margin-bottom: 20px;
          text-transform: uppercase;
          font-family: var(--camptonmedium);
          font-size: 14px !important;
          border: 2px solid rgba(44, 46, 53, 0.4);
          color: rgba(44, 46, 53, 0.4);
          width: 100%;

          &:not(:first-child) {
            flex: 1;
          }
        }

        li.active_tab {
          position: relative;
          border: 2px solid var(--black);
          color: var(--black);

          &:after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            background: url(/assets/images/icons/_icon_checkbox_white.svg),
              var(--black) no-repeat;
            background-size: auto;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .is_submenu {
      display: flex;
      flex-direction: column;

      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;

      li {
        font-size: 16px;

        label {
          display: flex;
          align-items: center;
          margin: 10px 0;
          padding-left: 45px;
        }

        img {
          height: 25px;
          width: 65px;
          margin-right: 25px;
        }

        &:last-of-type {
          label {
            margin-bottom: 20px;
          }
        }
      }

      &.color_filter {
        flex-direction: row;
        flex-wrap: wrap;

        li {
          width: 50%;

          // add black checkbox on colors filter
          // blanc
          input[type="checkbox"].custom_checkbox:checked~label.blanc::before,
          input[type="checkbox"].custom_checkbox:checked~label.bianco::before,
          input[type="checkbox"].custom_checkbox:checked~label.blanco::before,
          input[type="checkbox"].custom_checkbox:checked~label.white::before,
          // transparent
          input[type="checkbox"].custom_checkbox:checked~label.transparent::before,
          input[type="checkbox"].custom_checkbox:checked~label.trasparente::before,
          input[type="checkbox"].custom_checkbox:checked~label.transparente::before,
          // argent
          input[type="checkbox"].custom_checkbox:checked~label.argent::before,
          input[type="checkbox"].custom_checkbox:checked~label.argento::before,
          input[type="checkbox"].custom_checkbox:checked~label.plata::before,
          input[type="checkbox"].custom_checkbox:checked~label.silver::before,
          // beige
          input[type="checkbox"].custom_checkbox:checked~label.beige::before,
          // jaune
          input[type="checkbox"].custom_checkbox:checked~label.jaune::before,
          input[type="checkbox"].custom_checkbox:checked~label.giallo::before,
          input[type="checkbox"].custom_checkbox:checked~label.amarillo::before,
          input[type="checkbox"].custom_checkbox:checked~label.yellow::before {
            background: url(/assets/images/icons/_icon_checkbox.svg);
            background-size: 40px 40px;
            background-position: -8px;
          }

          // add white checkbox on other colors filter
          input[type="checkbox"].custom_checkbox:checked~label::before {
            background: url(/assets/images/icons/_icon_checkbox_white.svg);
            background-size: 35px 35px;
            background-position: -5px;
            z-index: 99;
          }

          label {
            min-height: 36px;
            height: auto;
            padding-left: 45px;

            &::before {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            &::after {
              content: "";
              position: absolute;
              width: 24px;
              height: 24px;
              left: 6px;
              border-radius: 50%;
            }

            &.argent,
            &.argento,
            &.plata,
            &.silver {
              &::after {
                background: var(--silver);
              }
            }

            &.beige {
              &::after {
                background: var(--beige2);
              }
            }

            &.blanc,
            &.bianco,
            &.blanco,
            &.white {
              &::after {
                background: var(--white);
              }
            }

            &.bleu,
            &.blu,
            &.azul,
            &.blue {
              &::after {
                background: var(--blue);
              }
            }

            &.bordeaux,
            &.bordo,
            &.burdeos,
            &.burgundy {
              &::after {
                background: var(--bordeaux);
              }
            }

            &.brun,
            &.marrone,
            &.marron,
            &.brown {
              &::after {
                background: var(--brown);
              }
            }

            &.caramel,
            &.caramello,
            &.caramelo {
              &::after {
                background: var(--caramel);
              }
            }

            &.dore,
            &.oro,
            &.dorado,
            &.gold {
              &::after {
                background: var(--yellow);
              }
            }

            &.gris,
            &.grigio,
            &.gris,
            &.grey {
              &::after {
                background: var(--grey2);
              }
            }

            &.jaune,
            &.giallo,
            &.amarillo,
            &.yellow {
              &::after {
                background: var(--yellow2);
              }
            }

            &.multicolore,
            &.multicolore,
            &.multicolor,
            &.multicolored {
              &::after {
                background: var(--multicolore);
              }
            }

            &.noir,
            &.nero,
            &.negro,
            &.black {
              &::after {
                background: var(--black);
              }
            }

            &.orange,
            &.arancione,
            &.naranja {
              &::after {
                background: var(--orange);
              }
            }

            &.rose,
            &.rosa,
            &.rosa,
            &.pink {
              &::after {
                background: var(--pink);
              }
            }

            &.rouge,
            &.rosso,
            &.rojo,
            &.red {
              &::after {
                background: var(--red2);
              }
            }

            &.transparent,
            &.trasparente,
            &.transparente {
              &::after {
                background: var(--transparent);
              }
            }

            &.vert,
            &.verde,
            &.verde,
            &.green {
              &::after {
                background: var(--green);
              }
            }

            &.violet,
            &.viola,
            &.purpura,
            &.purple {
              &::after {
                background: var(--purple);
              }
            }

            &.tortoiseshell {
              &::after {
                background: url(/assets/images/tortoi.png) no-repeat;
                background-size: 26px 26px;
                width: 26px;
                height: 26px;
                left: 5px;
              }
            }

            &.color-shadow {
              &::after {
                box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%),
                  0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
              }
            }
          }
        }
      }
    }
  }

  head-filter {
    display: flex;
    align-items: center;

    h2 {
      margin-bottom: 40px;
      font-family: var(--camptonmedium);
      font-size: 18px;
      font-weight: normal;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background: var(--black);

      img {
        width: 35px;
        height: 35px;
        transform: rotate(0);
        transition: transform 0.2s ease-out;

        &:hover {
          transform: rotate(180deg);
        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 25px;

    wrapper-buttons {
      margin-top: auto;
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 14px;
      font-family: var(--camptonmedium);
      text-transform: uppercase;

      .erase {
        color: rgba(40, 40, 40, 0.4);

        &:hover {
          color: var(--black);
        }
      }
    }

    .filter-buttons {
      margin-bottom: 100px;
    }
  }
}

overlay-filter.js_hide_order,
overlay-filter.js_hide_shop {
  overflow: auto;
}

.js_order.overlay,
.js_cart.overlay {
  overlay-background {
    width: 100%;
    height: 100vh;
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    background-color: rgba(44, 46, 53, 0.3);
    transition: opacity 0.25s linear;
  }

  overlay-filter {
    transform: translateX(0);
    box-shadow: 0px 0px 30px #38474f1a;
  }

  overlay-filter.js_hide_order,
  overlay-filter.js_hide_shop {
    transform: translateX(-450px);

    @media screen and (max-width: 450px) {
      transform: translateX(-100%) !important;
    }
  }
}

/***************************************/

.lens-width-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 30px;

  li {
    width: 33%;

    label {
      padding-left: 35px !important;
    }
  }
}

.doc-count {
  font-size: 15px;
}

.more-filters {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding-right: 10px;
  cursor: pointer;
  color: var(--grey);

  img {
    height: 17px !important;
    width: 17px !important;
    margin-right: 0 !important;
  }
}