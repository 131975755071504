@import "assets/styles/scss/config/globals";

.cms:not(.old-design){
  max-width: none;
}

back-wrapper {
  display: flex;
  margin-bottom: 25px !important;
  margin-top: 5px !important;

  a {
    position: relative !important;
    padding-left: 15px !important;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--camptonmedium);
    font-size: 14px;
    font-weight: normal;

    &:after {
      content: " ";
      background: url(/assets/images/icons/_icon_filtre_chevron.svg) no-repeat;
      background-size: auto;
      background-size: 30px 30px;
      transform: rotate(90deg);
      width: 30px;
      height: 30px;
      left: -10px;
      position: absolute;
    }
  }
}

cms-wysiwyg {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 40px auto !important;

  b,
  strong,
  p b,
  p strong {
    font-weight: bold !important;
  }

  @media screen and(min-width: $desktop-bp) {
    width: 75%;
  }

  @media screen and(max-width: $mobile-bp) {
    margin-bottom: 40px !important;
  }
}

.wysiwyg,
cms-wysiwyg {
  h2 {
    margin-bottom: 20px;
    line-height: 1.2;
    font-size: 28px;
    font-family: var(--camptonsemibold);
    font-weight: normal;

    @media screen and(max-width: $mobile-bp) {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }

  h3 {
    margin-bottom: 10px;
    line-height: 1.2;
    font-size: 22px;
    font-family: var(--camptonsemibold);
    font-weight: normal;

    @media screen and(max-width: $mobile-bp) {
      font-size: 18px;
    }
  }

  h4 {
    margin-bottom: 10px;
    line-height: 1.2;
    font-size: 20px;
    font-family: var(--camptonsemibold);
    font-weight: normal;

    @media screen and(max-width: $mobile-bp) {
      font-size: 18px;
    }
  }

  ul {
    margin-bottom: 15px;
    padding-left: 40px;
    list-style: none;
    font-size: 18px;
    font-family: var(--camptonbook);
    font-weight: normal;

    li {
      line-height: 1.4;

      &::before {
        content: "\2022";
        color: var(--red);
        font-weight: bold;
        display: inline-block;
        width: 15px;
        margin-left: -10px;
      }
    }
  }

  ol {
    margin-bottom: 15px;
    padding-left: 40px;
    list-style-type: none;
    position: relative;
    font-size: 18px;
    font-family: var(--camptonbook);
    font-weight: normal;
    counter-reset: listitem;

    li {
      line-height: 1.4;

      &::before {
        counter-increment: listitem;
        content: counters(listitem, "") "-";
        color: var(--red);
        font-weight: bold;
        display: inline-block;
        width: 25px;
        margin-left: -10px;
      }
    }
  }

  a {
    color: var(--red);
    text-decoration: underline;
    font-family: var(--camptonsemibold);
    font-weight: normal;
  }

  p,
  div,
  span {
    margin-bottom: 15px;
    line-height: 1.4;
    font-size: 18px;
    font-family: var(--camptonbook);
    font-weight: normal;
  }
}

breadcrumb-block {
  display: none;
  align-items: baseline;
  margin-bottom: 50px !important;
  line-height: 1.2;
  font-size: 14px;
  font-family: var(--camptonsemibold);
  font-weight: normal;
  position: relative;

  a {
    display: flex;
    align-items: baseline;
    font-family: var(--camptonbook);
    font-weight: normal;

    &::after {
      content: "/";
      margin: 0 5px;
    }
  }
}

.breadcrumb-block-listing {
  position: absolute !important;
  left: 16px;
  top: 10px;
  @media screen and (max-width: $mobile-bp) {
    display: none;
  }
}

cms-cardoverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
  position: relative;
  min-height: 535px;
  height: auto;
  padding: 15px;

  @media screen and(max-width: $mobile-bp) {
    min-height: 395px;
    padding: 10px;
    margin-bottom: 50px;
  }

  .wysiwyg {
    p,
    span,
    div {
      display: block;
      margin-bottom: 20px;
      font-size: 18px;
      font-family: var(--camptonbook);
      font-weight: normal;
      line-height: 1.2;
      text-align: center;

      @media screen and(max-width: $mobile-bp) {
        display: none;
      }
    }

    h2 {
      margin-bottom: 5px;
      font-size: 2.8rem;
      font-family: var(--camptonsemibold);
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.2;

      @media screen and(max-width: $mobile-bp) {
        font-size: 22px;
        margin-bottom: 5px;
      }
    }

    h3 {
      margin-bottom: 15px;
      font-size: 2.2rem;
      font-family: var(--camptonbook);
      font-weight: normal;
      text-align: center;
      line-height: 1.2;

      @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
      }
    }

    h4 {
      margin-bottom: 10px;
      font-size: 2.2rem;
      font-family: var(--camptonmedium);
      font-weight: normal;
      text-align: center;
      line-height: 1.2;

      @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
      }
    }
  }

  > picture {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  > .super-wrapper {
    width: 100%;

    .wrapper {
      display: inline-flex;
    }
  }

  card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 410px;
    height: auto;
    width: 500px;
    padding: 30px !important;
    z-index: 8;
    background: var(--beige);

    @media screen and(max-width: $mobile-bp) {
      width: auto;
      min-height: 0;
    }

    position-content {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-family: var(--camptonmedium);
      font-weight: normal;
      line-height: 1.2;

      img {
        width: 40px;
        margin-right: 5px;
      }
    }

    input-group {
      display: flex;
      margin-bottom: 15px;
      width: 100%;

      input {
        height: 50px;
        width: 100%;
        padding: 15px;
        border: 0;
        font-family: var(--camptonbook);
        font-size: 18px;
        background: #fff;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        height: 50px;
        width: 50px;
        background: var(--black);
      }
    }
  }
}

cms-cardoverlay-black {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
  position: relative;
  min-height: 465px;
  height: auto;
  padding: 60px 15px;

  @media screen and(max-width: $mobile-bp) {
    min-height: 395px;
    padding: 10px;
    margin-bottom: 50px;
  }

  .wysiwyg {
    color: var(--white);

    p,
    span,
    div {
      display: block;
      margin-bottom: 20px;
      font-size: 18px;
      font-family: var(--camptonbook);
      font-weight: normal;
      line-height: 1.2;
      text-align: center;

      @media screen and(max-width: $mobile-bp) {
        display: none;
      }
    }

    h2 {
      margin-bottom: 5px;
      font-size: 2.8rem;
      font-family: var(--camptonsemibold);
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.2;

      @media screen and(max-width: $mobile-bp) {
        font-size: 22px;
        margin-bottom: 5px;
      }
    }

    h3 {
      margin-bottom: 15px;
      font-size: 2.2rem;
      font-family: var(--camptonbook);
      font-weight: normal;
      text-align: center;
      line-height: 1.2;

      @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
      }
    }

    h4 {
      margin-bottom: 10px;
      font-size: 2.2rem;
      font-family: var(--camptonmedium);
      font-weight: normal;
      text-align: center;
      line-height: 1.2;

      @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
      }
    }
  }

  > picture {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  > .wrapper {
    width: 100%;
  }

  card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 345px;
    height: auto;
    width: 500px;
    padding: 30px !important;
    z-index: 8;
    background: var(--black);

    @media screen and(max-width: $mobile-bp) {
      width: auto;
      min-height: 0;
    }

    position-content {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-family: var(--camptonmedium);
      font-weight: normal;
      line-height: 1.2;

      img {
        width: 40px;
        margin-right: 5px;
      }
    }

    input-group {
      display: flex;
      margin-bottom: 15px;
      width: 100%;

      input {
        height: 50px;
        width: 100%;
        padding: 15px;
        border: 0;
        font-family: var(--camptonbook);
        font-size: 18px;
        background: #fff;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        height: 50px;
        width: 50px;
        background: var(--black);
      }
    }
  }
}

cms-duocolumns {
  text-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px) !important;

    &:first-of-type {
      margin-right: 15px;
    }

    &:last-of-type {
      margin-left: 15px;
      margin-right: 0;
    }

    @media screen and(max-width: $mobile-bp) {
      width: 100% !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

cms-duocolumns,
cms-multicolumns {
  display: flex;
  width: 100%;
  margin-bottom: 70px !important;

  @media screen and(max-width: $mobile-bp) {
    flex-direction: column;
    margin-bottom: 50px !important;

    .accordion_responsive {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 5px 40px 5px 20px;
      margin-bottom: 10px;
      font-size: 18px;
      background: #f6f6f6;

      &:after {
        content: " ";
        position: absolute;
        top: 10%;
        right: 0;
        background: url(/assets/images/icons/_icon_filtre_chevron.svg) no-repeat;
        background-size: auto;
        background-size: 40px 40px;
        width: 40px;
        height: 40px;
        transform: rotate(0deg);
        transition: all 0.25s ease-in-out;
      }
    }

    .accordion_responsive.is_active {
      &:after {
        transform: rotate(180deg);
      }
      margin-bottom: 0;
    }

    .accordion_responsive.is_active + .content_text {
      margin-bottom: 10px;
    }
  }

  text-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 60px;

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      margin-right: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    h2,
    h3 {
      margin-bottom: 15px;
      line-height: 1.2;
      font-size: 2.2rem;
      font-family: var(--camptonmedium);
      font-weight: normal;

      @media screen and(max-width: $mobile-bp) {
        cursor: pointer;
      }
    }

    .content_text {
      font-size: 1.6rem;
      line-height: 1.6;

      @media screen and(max-width: $mobile-bp) {
        max-height: 0;
        padding: 0 20px;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        background: #f6f6f6;
      }
    }
  }
}

cms-fifty-image {
  display: flex;
  min-height: 400px;
  height: auto;
  margin-bottom: 100px !important;

  @media screen and(max-width: $mobile-bp) {
    flex-direction: column;
    margin-bottom: 45px !important;
    min-height: 0;

    p {
      display: none;
    }
  }

  picture {
    width: 50%;

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      height: 180px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  wrapper-text {
    padding: 80px 80px 70px 80px;
    width: 50%;
    display: flex;
    flex-direction: column;
    color: var(--white);
    background: var(--black);

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      padding: 30px;
    }

    h3 {
      font-family: var(--camptonsemibook) !important;
      margin-bottom: 20px;
    }

    .btn_2 {
      margin-top: auto;
      margin-right: auto;
    }
  }
}

cms-headband {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  min-height: 345px;
  height: auto;
  padding: 15px;

  h1 {
    min-width: 100px;
  }

  &::before {
    // content: "";
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7;
  }

  @media screen and (max-width: $mobile-bp) {
    min-height: 120px;
  }

  wrapper-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 8;

    @media screen and (max-width: $mobile-bp) {
      width: 100%;
    }

    h1 {
      margin-bottom: 20px;
      padding: 0;
      font-size: 36px;
      text-transform: initial;

      @media screen and (max-width: $mobile-bp) {
        font-size: 28px;
        text-align: left;
      }
    }

    .wysiwyg {
      width: 50%;
      line-height: 1.4;

      @media screen and (max-width: $mobile-bp) {
        display: none;
      }
    }
  }

  > picture {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

cms-headband-global {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
  position: relative;
  min-height: 200px;
  height: auto;
  padding: 30px 25px;

  &::before {
    // content: "";
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7;
  }

  @media screen and (max-width: $mobile-bp) {
    min-height: 100px;
    padding: 15px;
    margin-bottom: 50px;
  }

  wrapper-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    min-height: 140px;
    height: 100%;
    z-index: 8;

    @media screen and (max-width: $mobile-bp) {
      justify-content: center;
      width: 100%;
      min-height: 70px;
      padding: 0 !important;
    }

    h1 {
      padding: 0;
      width: 100%;
      margin-bottom: 0;
      font-size: 36px;
      text-transform: initial;
      text-align: left;

      @media screen and (max-width: $mobile-bp) {
        font-size: 28px;
        text-align: left;
      }
    }

    breadcrumb-block {
      width: 100%;
      margin-bottom: 10px !important;

      @media screen and (max-width: $mobile-bp) {
        display: none;
      }
    }
  }

  > picture {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

cms-full {
  display: flex;
  width: 100%;
  margin: 0 auto 40px auto !important;

  @media screen and (min-width: $desktop-bp) {
    width: 75%;
  }

  @media screen and (max-width: $mobile-bp) {
    flex-direction: column;
  }

  two-third {
    width: calc((100% / 3) * 3);
    padding-right: 15px;

    @media screen and (max-width: $mobile-bp) {
      padding-right: 0;
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 20px;
      line-height: 1.2;
      font-size: 28px;
      font-family: var(--camptonsemibold);
      font-weight: normal;

      @media screen and(max-width: $mobile-bp) {
        font-size: 22px;
        margin-bottom: 5px;
      }
    }

    h3 {
      margin-bottom: 10px;
      line-height: 1.2;
      font-size: 22px;
      font-family: var(--camptonsemibold);
      font-weight: normal;

      @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
      }
    }

    h4 {
      margin-bottom: 10px;
      line-height: 1.2;
      font-size: 20px;
      font-family: var(--camptonsemibold);
      font-weight: normal;

      @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
      }
    }

    ul {
      margin-bottom: 15px;
      padding-left: 10px;
      list-style: none;
      font-size: 18px;
      font-family: var(--camptonbook);
      font-weight: normal;

      li {
        line-height: 1.4;

        &::before {
          content: "\2022";
          color: var(--red);
          font-weight: bold;
          display: inline-block;
          width: 15px;
          margin-left: -10px;
        }
      }
    }

    ol {
      margin-bottom: 15px;
      padding-left: 10px;
      list-style-type: none;
      position: relative;
      font-size: 18px;
      font-family: var(--camptonbook);
      font-weight: normal;
      counter-reset: listitem;

      li {
        line-height: 1.4;

        &::before {
          counter-increment: listitem;
          content: counters(listitem, "") "-";
          color: var(--red);
          font-weight: bold;
          display: inline-block;
          width: 25px;
          margin-left: -10px;
        }
      }
    }

    a {
      font-family: var(--camptonbook);
      font-weight: normal;
    }

    p,
    div,
    span {
      margin-bottom: 15px;
      line-height: 1.4;
      font-size: 18px;
      font-family: var(--camptonbook);
      font-weight: normal;
      color: var(--black);
    }
  }

  one-third {
    padding-left: 15px;
    width: calc(100% / 3);
    height: 310px;

    @media screen and (max-width: $mobile-bp) {
      padding-left: 0;
      width: 100%;
    }

    picture {
      width: 100%;
      height: 100%;

      img {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
      }
    }
  }
}

cms-double-images {
  display: flex;
  justify-content: space-between;
  height: 430px;
  margin-bottom: 100px !important;

  @media screen and(max-width: $mobile-bp) {
    flex-direction: column;
    margin-bottom: 50px !important;
    height: auto;

    p {
      display: none;
    }
  }

  picture {
    width: calc(50% - 15px);

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      height: 230px;
    }

    &:first-of-type {
      @media screen and(max-width: $mobile-bp) {
        margin-bottom: 30px;
      }
    }

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

cms-triplet-blocks {
  display: flex;
  width: 100%;
  margin-bottom: 100px !important;

  @media screen and(max-width: $mobile-bp) {
    flex-direction: column;
    margin-bottom: 50px !important;
  }

  text-wrapper {
    display: flex;
    flex-direction: column;
    width: calc((100% / 3) - 10px);
    margin-right: 30px;
    cursor: pointer;

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    picture {
      height: 260px;
      margin-bottom: 25px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @media screen and(max-width: $mobile-bp) {
        height: 210px;
        margin-bottom: 20px;
      }
    }

    h2,
    h3 {
      margin-bottom: 15px;
      line-height: 1.2;
      font-size: 2.2rem;
      font-family: var(--camptonmedium);
      font-weight: normal;

      @media screen and(max-width: $mobile-bp) {
        cursor: pointer;
      }
    }

    .content_text {
      font-size: 1.6rem;
      line-height: 1.6;
    }
  }
}

cms-main-image {
  display: flex;
  height: 500px;
  margin-bottom: 100px !important;

  @media screen and(max-width: $mobile-bp) {
    height: 180px;
    margin-bottom: 50px !important;
  }

  picture {
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.cms-left-image {
  display: flex;
  padding: 0;
  margin-bottom: 0;

  .left_image_mobile{
    display: none;
  }

  @media screen and(max-width: $mobile-bp) {
    padding: 0 25px;
    .left_image_mobile{
      display: block;
    }
    .left_image_desktop{
      display: none;
    }
  }

  text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: calc(50% - 15px);
    min-height: 430px;
    height: auto;
    padding-left: 32px;
    

    p, ul{
      font-size: 14px;
    }

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      min-height: 0;
      margin-bottom: 20px;
      padding-left: 0;
      p, ul{
        font-size: 18px;
      }
    }

    @media screen and(min-width: $desktop-bp) {
      p, ul{
        font-size: 18px;
      }
  }

    h2{
      padding: 0;
      font-family: var(--camptonmedium);
      text-transform: none;
      font-size: 24px;
      text-align: start;
      
      @media screen and(max-width: $mobile-bp) {
          font-size: 32px;
      }
      
      @media screen and(min-width: $desktop-bp) {
          font-size: 42px;
      }
    }
  }

  picture, .left_image_mobile, .left_image_desktop {
    width: calc(50% + 15px);
    height: 430px;

    @media screen and(max-width: $mobile-bp) {
      height: 235px;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

cms-left-image-height,
cms-right-image-height {
  display: flex;
  margin-bottom: 90px !important;

  @media screen and(max-width: $mobile-bp) {
    margin-bottom: 50px !important;
  }

  text-content {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    min-height: 430px;
    height: auto;

    .btn_3 {
      margin-right: auto;
      position: relative;
      padding-right: 35px;
      margin-top: 20px;

      &:after {
        content: " ";
        background: url(/assets/images/icons/_icon_filtre_chevron_white.svg)
          no-repeat;
        background-size: auto;
        background-size: 40px 40px;
        transform: rotate(-90deg);
        width: 40px;
        height: 40px;
        right: 5px;
        top: 5px;
        position: absolute;
      }

      &:hover {
        &:after {
          content: " ";
          background: url(/assets/images/icons/_icon_filtre_chevron.svg)
            no-repeat;
          background-size: auto;
          background-size: 40px 40px;
          transform: rotate(-90deg);
          width: 40px;
          height: 40px;
          right: 5px;
          top: 5px;
          position: absolute;
        }
      }
    }

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      min-height: 0;
      margin-bottom: 20px;
    }
  }

  picture {
    width: calc(50% + 15px);
    min-height: 700px;
    height: 700px;

    @media screen and(max-width: $mobile-bp) {
      height: 385px;
      min-height: 0;
      width: 100%;
    }

    img {
      height: 100% !important;
      width: 100% !important;
      object-fit: cover;
    }
  }

  @media screen and(max-width: $mobile-bp) {
    margin-bottom: 50px;
  }
}

cms-left-video,
cms-left-image-height {
  @media screen and(max-width: $mobile-bp) {
    flex-direction: column-reverse;
  }

  text-content {
    margin-left: 0;
    padding-left: 60px;

    @media screen and(max-width: $mobile-bp) {
      padding-left: 0;
    }
  }

  img {
    margin-right: 0;
  }
}

cms-right-image-height,
cms-right-image {
  @media screen and(max-width: $mobile-bp) {
    flex-direction: column;
  }

  text-content {
    margin-right: 0;
    padding-right: 60px;

    @media screen and(max-width: $mobile-bp) {
      padding-right: 0;
    }
  }

  img {
    margin-left: 0;
  }
}

cms-cardoverlay-center {
  min-height: 420px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 70px !important;
  position: relative;
  padding: 15px;

  .blocks_description {
    background: var(--grey_light);
  }

  card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 310px;
    height: auto;
    width: 555px;
    padding: 30px 45px !important;
    z-index: 8;
    background: var(--black);

    @media screen and(max-width: $mobile-bp) {
      width: auto;
      min-height: 0;
    }
  }

  @media screen and(max-width: $mobile-bp) {
    min-height: 395px;
    margin-bottom: 50px;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .wysiwyg {
    color: #fff;

    p,
    span,
    div {
      display: block;
      margin-bottom: 30px;
      font-size: 22px;
      font-family: var(--camptonbook);
      font-weight: normal;
      line-height: 1.2;
      text-align: center;

      @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
      }
    }

    h2 {
      margin-bottom: 20px;
      font-size: 28px;
      font-family: var(--camptonsemibold);
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.2;

      @media screen and(max-width: $mobile-bp) {
        font-size: 22px;
        margin-bottom: 5px;
      }
    }
  }

  > picture {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

video-full {
  display: flex;
  height: 685px;
  margin-bottom: 60px !important;
  position: relative;
  overflow: hidden;

  &:after {
    content: " ";
    background-color: rgba(44, 46, 53, 0.4);
    // width: calc(100% - 30px);
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
  }

  @media screen and(min-width: $desktop-max-width) {
    max-width: 1240px !important;
  }

  @media screen and (min-width: $desktop-bp) and (max-width: $desktop-max-width) {
    max-width: 962px !important;
  }

  @media screen and(min-width: $desktop-bp) {
    width: 100%;
  }

  @media screen and(max-width: $mobile-bp) {
    height: 200px;
    width: 100%;
    max-width: calc(480px - 50px) !important;
    padding: 0 !important;
  }

  @media screen and(max-width: $small-thr) {
    width: calc(100% - 50px);
    max-width: 480px !important;
  }

  picture {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

video-full modal-wrapper.modal_basic card-container,
cms-left-video modal-wrapper.modal_basic card-container {
  height: 500px;
  padding: 15px;

  @media screen and(max-width: $mobile-bp) {
    height: 300px;
  }

  block-wrap,
  .video_modal,
  iframe,
  .pimcore_video,
  .pimcore_editable_video {
    height: 100%;
  }
}

.btn_player {
  position: relative;
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 115px 0 0 115px;
  z-index: 10;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 3px 15px #00000029;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-42%, -50%);
    border-style: solid;
    border-width: 23px 0 23px 36px;
    border-color: transparent transparent transparent #fdc51e;
    transition: all 0.3s ease;
  }
}

cms-left-video {
  display: flex;
  margin-bottom: 90px !important;

  @media screen and(max-width: $mobile-bp) {
    margin-bottom: 50px !important;
  }

  text-content {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    min-height: 430px;
    height: auto;

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
      min-height: 0;
      margin-bottom: 20px;
    }
  }

  > div {
    position: relative;
    width: calc(50% + 15px);
    height: 430px;
    overflow: hidden;

    &:after {
      content: " ";
      background-color: rgba(44, 46, 53, 0.4);
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
    }

    @media screen and(max-width: $mobile-bp) {
      height: 235px;
      width: 100%;
    }

    img {
      height: 100% !important;
      width: 100% !important;
      object-fit: cover;
    }
  }

  @media screen and(max-width: $mobile-bp) {
    margin-bottom: 50px;
  }

  .btn_player {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 115px 0 0 115px;
    z-index: 10;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 3px 15px #00000029;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-42%, -50%);
      border-style: solid;
      border-width: 23px 0 23px 36px;
      border-color: transparent transparent transparent #fdc51e;
      transition: all 0.3s ease;
    }
  }
}

table-wrapper {
  display: flex;
  flex-direction: column;

  table-description {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto 25px auto;

    table-title {
      margin-bottom: 15px;
      font-family: var(--camptonsemibold);
      font-size: 22px;
      font-weight: normal;
    }

    @media screen and(max-width: $mobile-bp) {
      width: 100%;
    }
  }
}

.table_health {
  outline: 2px solid rgba(44, 46, 53, 0.2);
  outline-offset: 20px;
  width: calc(100% - 44px);
  margin: 22px auto 72px auto;

  @media screen and (max-width: $mobile-bp),
    screen and (min-width: $desktop-bp) {
    width: calc(75% - 44px);
  }

  .thead {
    display: flex;
    background-color: var(--black);
    margin-bottom: 10px;

    @media screen and(max-width: $mobile-bp) {
      margin-bottom: 0;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      padding: 25px 15px;
      line-height: 1.2;
      vertical-align: middle;
      text-align: center;
      font-family: var(--camptonbook);
      font-size: 14px;
      font-weight: normal;
      color: rgb(255, 255, 255, 0.7);
    }
  }

  .tbody {
    display: flex;

    li {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li {
      vertical-align: middle;
      padding: 5px 10px;
      text-align: center;
      line-height: 1.2;
      font-family: var(--camptonbook);
      font-size: 16px;
      font-weight: normal;
    }
  }

  @media screen and(max-width: $mobile-bp) {
    width: 100%;
    outline: 0px !important;
    outline-offset: 0px !important;
    margin: 0 0 40px 0;
    border: 2px solid rgba(44, 46, 53, 0.2);

    .thead {
      display: none;
    }

    .tbody {
      display: block;

      &:nth-child(even) {
        background-color: var(--black);

        li {
          color: #fff !important;
        }

        li:before {
          color: rgb(255, 255, 255, 0.7) !important;
          width: 65%;
          float: left;
        }

        li:after {
          content: "";
          display: block;
          margin: 0 auto;
          width: calc(100% - 30px);
          padding-top: 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.7);
          position: absolute;
          bottom: 0;
        }
      }

      &:nth-child(odd) {
        li {
          color: var(--black) !important;
        }

        li:before {
          color: #6b6c71 !important;
          width: 65%;
          float: left;
        }

        li:after {
          content: "";
          display: block;
          margin: 0 auto;
          width: calc(100% - 30px);
          padding-top: 10px;
          position: absolute;
          bottom: 0;
        }
      }
    }

    .tbody li {
      display: flex;
      align-items: center;
      min-height: 50px;
      text-align: left;
      padding: 10px 15px !important;
      width: 100%;
      position: relative;

      p {
        flex: 1 0 35%;
      }
    }

    .tbody li:before {
      content: attr(data-label) " : ";
      font-weight: normal;
      font-size: 14px;
      color: #6b6c71 !important;
      text-align: left;
      width: 65%;
    }

    .tbody li:after {
      content: "";
      display: block;
      margin: 0 auto;
      width: calc(100% - 30px);
      padding-top: 10px;
      border-bottom: 1px solid rgba(44, 46, 53, 0.2);
      position: absolute;
      bottom: 0;
    }

    .tbody li:last-child:after {
      border-bottom: 0px !important;
    }
  }
}
