@import "assets/styles/scss/config/globals";

.cms-with-menu {
    display: flex;
    position: relative;
    max-width: $desktop-max-width;
    margin: 0 auto;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    
    .cms-areablock{
        min-height : auto;
    }
    
    .mag-optique-wrapper {
        width :100%;
        margin-bottom: 25px;

        .mag-optique-menu {
            border-top: 1px solid var(--grey_light);
            border-left: none;
            padding: 20px;

            h3 {
                margin-top: 15px;
            }

            ul{
                height: max-content;
                overflow-y: hidden;

                &::-webkit-scrollbar-thumb {
                    background-color: none;
                }
                padding-left: 25px;
                list-style: disc;

                li{
                    text-align: left;
                    list-style-type: disc;
                    margin: 5px 0;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media screen and(min-width: $desktop-bp) {
            flex-direction: row-reverse;
            justify-content: center;
            align-items: flex-start;

        .cms-areablock{
            min-height: 55vh;
            cms-full {
                width:100%
            }
        }
    
        .mag-optique-wrapper {
            width :30%;
            margin : 0;
            
            .mag-optique-menu {
                border-top: none;
                border-left: 1px solid var(--grey_light);
    
                h3 {
                    padding-left: 25px;
                    font-family: campton_semibold;
                    margin-bottom: 15px;
                }
    
                ul{
                    margin-left: 10px;
                    padding : 0 25px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    height: 45vh;
                    scrollbar-color: var(--grey_blue) var(--white);
    
                    &::-webkit-scrollbar {
                        width: 7px;
                        border-radius: 10px;
                        border:1px solid var(--grey_blue);
                    }
    
                    &::-webkit-scrollbar-thumb {
                        background-color: var(--grey_blue);
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}