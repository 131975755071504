@import "assets/styles/scss/config/globals";

.contact {
    h2 {
        margin-bottom: 25px;
        font-family: var(--camptonsemibold);
        font-size: 28px;
        font-weight: normal;
        line-height: 1.2;

        @media screen and(max-width: $mobile-bp) {
            font-size: 22px;
        }
    }

    cms-headband-global {
        margin-bottom: 0;
    }
}

.card_contact {
    display: flex;
    flex-wrap: wrap; 

    @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
    }

    li {
        display: flex;
        flex-direction: column;
        padding: 40px 25px;
        width: calc((100% / 3) - 20px);
        margin-right: 30px;
        text-align: center;
        box-shadow: 0 3px 10px #0000001A;

        @media screen and(max-width: $mobile-bp) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }

        &:last-of-type {
            margin-right: 0;
        }

        > a {
            text-decoration: none;
            margin-top: auto;
        }
    }
}

.contact_bottom,
.contact_top {
    padding: 65px 0;
}

.contact_top {
    @media screen and(max-width: $mobile-bp) {
        padding: 50px 0 30px 0;
    }
}

.contact_bottom {
    background: var(--grey_light);

    @media screen and(max-width: $mobile-bp) {
        padding:  30px 0 50px 0;
    }

    >.wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .contact_desc {
        display: flex;
        flex-direction: column;
        line-height: 1.5;
        font-family: var(--camptonbook);
        font-size: 16px;

        &:nth-child(-n+3){
            width: calc(50% - 15px) !important;
            margin-bottom: 45px;
            margin-right: 5px;
        }

        &:nth-child(odd){
            margin-right: 15px !important;
        }

        &:nth-child(even){
            margin-right: 0 !important;
        }
        
        &:last-of-type {
            width: 50% !important;
            margin-bottom: 45px;
        }

        @media screen and(min-width: $desktop-bp) {
            &:nth-child(-n+3){
                width: calc((100% / 3) - 5px);
                margin-bottom: 45px;
                margin-right: 5px;
            }

            &:nth-child(3){
                margin-right: 0;
            }

            &:last-of-type {
                width: 100%;
                margin-bottom: 0;
            }
        }

        > ul {
            padding-left: 15px;
            list-style: none;
            font-size: 16px;
            font-family: var(--camptonbook);
            font-weight: normal;
            
            li {
                &::before {
                    content: '\2022';
                    color: #DCD6D1;
                    font-weight: normal;
                    display: inline-block;
                    width: 10px;
                    margin-left: -10px;
                }
            }
        }

        > li {
            font-family: var(--camptonsemibold);
            font-size: 22px;
            line-height: 1.2;
            margin-bottom: 20px;
        }
    }

    @media screen and(max-width: $mobile-bp) {
        .contact_desc:nth-child(-n+3),
        .contact_desc:nth-child(odd),
        .contact_desc:nth-child(even),
        .contact_desc {
            width: 100% !important;
            margin-bottom: 10px !important;
            margin-right: 0 !important;
        }

        .contact_desc > ul {
            max-height: 0;
            padding: 0 20px;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            background: #FFF;
        }

        .contact_desc > li {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            min-height: 50px;
            padding: 5px 40px 5px 20px;
            margin-bottom: 0px;
            font-size: 18px;
            background: #FFF;

            &:after {
                content: ' ';
                position: absolute;
                top: 10%;
                right: 0;
                background: url(/assets/images/icons/_icon_filtre_chevron.svg) no-repeat;
                background-size: auto;
                background-size: 40px 40px;
                width: 40px;
                height: 40px;
                transform: rotate(0deg);
                transition: all 0.25s ease-in-out;
            }
        }
        
        .contact_desc .is_active {
            &:after {
                transform: rotate(180deg);
            }

            margin-bottom: 0;
        }

        .accordion_responsive.is_active + .content_text {
            margin-bottom: 10px;
        }
    }
}

contact-desc {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    line-height: 1.7;

    @media screen and(max-width: $mobile-bp) {
        font-size: 18px;
        font-weight: normal;
        line-height: 1.4;
    }
    
    b,
    strong {
        color: var(--red);
        font-size: 22px;
        font-family: var(--camptonsemibold);
        font-weight: normal;

        @media screen and(max-width: $mobile-bp) {
            font-size: 18px;
        }

        a {
            white-space: nowrap;
        }
    }
    
    img {
        height: 60px;
        width: 60px;
        margin-right: 20px;

        @media screen and(max-width: $mobile-bp) {
            width: 45px;
            height: 45px;
        }
    }
}