@import "assets/styles/scss/config/globals";

.connexion-wrapper
{
    display: flex;
    justify-content: space-around;
    min-height: 100px;
    margin-top: 50px;
    margin-bottom: 100px;
    flex-wrap: wrap;
    @media screen and(max-width: $small-thr) {
        font-size: 14px;
    }
    .sign-in-holder
    {
        border-right: 0;
        @media screen and(min-width: $desktop-bp) {
            border-right:1px solid rgba(44, 46, 53, 0.2);
        }
        form
        {
            position: relative;
            border-bottom:1px solid rgba(44, 46, 53, 0.2);
            margin-bottom: 20px;
            padding-bottom: 20px;
            @media screen and(min-width: $desktop-bp) {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .or {
                position: absolute;
                width: 100%;
                bottom: -10px;
                color: #D75931;
                font-weight: bold;
                display: flex;
                justify-content: center;

                div {
                    text-align: center;
                    padding: 0px 10px;
                    background-color: white;
                    font-size: 16px !important;
                }

                @media screen and(min-width: $desktop-bp) {
                    display: none;
                }
            }
        }
    }
    &>*
    {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        @media screen and(min-width: $desktop-bp) {
            width: 50%;
        }
        form
        {
            @media screen and(max-width: $small-thr) {
                width: 100%;
            }
        }
    }

    .block-title
    {
        text-transform: uppercase;
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 35px;
        font-weight: 600;
        font-size: 16px;
        color: #D75931;
        line-height: 20px;
        @media screen and(max-width: $small-thr) {
            font-size: 14px;
        }
    }

    .block-title-new-account
    {
        text-transform: uppercase;
        display: none;
        width: 100%;
        justify-content: center;
        margin-bottom: 35px;
        font-weight: 600;
        font-size: 16px;
        color: #D75931;
        line-height: 20px;
        @media screen and(max-width: $small-thr) {
            font-size: 14px;
        }

        @media screen and(min-width: $desktop-bp) {
            display: flex;
        }
    }

    div {
        text-align: center;
        a.password-forget
        {
            text-decoration: underline;
            color: #2c2e35;
            font-weight: 400;
            font-size: 16px;
            text-align: center;

            &:hover {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }

    div.inscription-btn
    {
        margin-top: 0;
        @media screen and(min-width: $desktop-bp) {
            margin-top: 105px;
        }
    }
}