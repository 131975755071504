.range_wrapper {
    background: #fff;
    position: relative;
    margin-bottom: 20px;

    range-container {
        display: block;
        position: relative;
        width: 100%;
        height: 120px;

        input[type="range"] {
            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                height: 2px;
            }
            
            &::-moz-range-track {
                -moz-appearance: none;
                height: 2px;
            }
            
            &::-ms-track {
                appearance: none;
                height: 2px;
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 32px;
                width: 32px;
                margin-top: -30px;
                border-radius: 50%;
                box-shadow: 0px 0px 10px #00000012;
                cursor: pointer;
                pointer-events: auto;
                background: var(--black);
            } 

            &::-moz-range-thumb {
                -webkit-appearance: none;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                border: 2px solid var(--black);
                box-shadow: 0px 0px 10px #00000012;
                cursor: pointer;
                pointer-events: auto;
                background:var(--black);
            }
            
            &::-ms-thumb {
                appearance: none;
                height: 32px;
                width: 32px;
                cursor: pointer;
                border-radius: 50%;
                pointer-events: auto;
                background:var(--black);
            }

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            outline: none;
            position: absolute;
            margin: 82px auto auto auto;
            top: -6px;
            bottom: 0px;
            background: transparent;
            pointer-events: none;
        }
    
        .slider_track {
            width: 100%;
            height: 2px;
            position: absolute;
            margin: 82px auto auto auto;
            top: 0;
            bottom: 0;
        }

        .values {
            display: flex;
            justify-content: space-between;
            font-family: var(--camptonbook);
            font-weight: normal;
            font-size: 16px;
            
            input[type="text"] {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 50px;
                width: 70px;
                border: 1px solid #CDCECF;
				overflow: hidden;
                font-family: var(--camptonbook);
                font-weight: normal;
                font-size: 16px;
                &:hover, 
                &:focus {
                    border-color: var(--black);
                }
            }
        }
    }
}

.graduations-container {
    position: relative;
}

.graduation {
    position: absolute;
    bottom: -80px; /* distance of graduations with slider */
    font-size: 15px;
    .doc-count{
        font-size: 15px;
    }
}

.vertical-line {
    position: absolute;
    bottom: -42px; /* distance of vertical line with slider */
    width: 2px; 
    height: 8px;
    background-color: var(--black); 
}
