@import "assets/styles/scss/config/globals";

sticky-compare {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 70px;
    z-index: 100;
    background: #fff;
    box-shadow: 0px 3px 20px #00000012;

    @media screen and(max-width: $large-thr) {
        flex-direction: column;
    }

    @media screen and(max-width: $mobile-bp) {
        padding: 0;
    }

    select-wrapper {
        display: flex;
        flex-direction: column;
        font-family: var(--camptonbook);
        font-size: 14px;

        @media screen and(max-width: $mobile-bp) {
            display: none;
        }

        @media screen and(max-width: $large-thr) {
            margin-bottom: 15px;
        }
    }

    choices-wrapper {
        display: flex;
        margin-top: 5px;

        @media screen and(max-width: $mobile-bp) {
            flex-wrap: wrap;
        }
    }

    buttons-wrapper {
        display: flex;

        >.btn_3,
        > .btn {
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media screen and(max-width: $large-thr) {
            display: flex;
            justify-content: center;
        }
    }

    tag-choice {
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 75px;
        height: 35px;
        margin-right: 25px;

        &:last-of-type {
            margin-right: 0;
        }

        img {
            height: calc(100% - 10px);
            width: calc(100% - 10px);
            object-fit: contain;
        }

        &::after {
            position: absolute;
            font-size: 18px;
            top: 0;
            right: 0;
            content:'x';
            color: var(--grey);
        }
    }
}