@import "assets/styles/scss/config/globals";


modal-wrapper.modal_dialog.modal_choices .close,
modal-wrapper.modal_dialog.modal_choices .close:hover {
  right: 35px;

  @media screen and(max-width: $mobile-bp) {
    top: 0;
    right: 5px;
  }
}
#previousBtn{
    display: none;
    position: absolute;
    top: 10px;
    left: 15px;
    width: 25px;
    fill:var(--white);
}
@media screen and(max-width: $mobile-bp) {
  .wrapper-title{
    position: fixed;
    top:0;
    left: 0;
    height: 100px;
    width: 100%;
    z-index: 2;
    .block-title{
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100px
    }
    #previousBtn{
      display: block;
    }
  }
}
.modal_choices {
  display: flex;
  flex-direction: column;

  .wrapper-choices {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    @media screen and(min-width: $desktop-bp) {
      flex-direction: row;
    }

    @media screen and(max-width: $mobile-bp) {
      margin-top: 100px;
    }
    background-choices {
      position: absolute;
      background: var(--grey_light);
    }

    .image-wrapper {
      img {
        width: 150px;
      }

      @media screen and(max-width: $mobile-bp) {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }

    .image-special-wrapper {
      width: 100px;

      img {
        width: 150px;
        height: 110px;
        object-fit: contain;
      }
    }

    .tooltip-wrapper {
      position: relative;
      display: inline-block;
      font-size: 14px;

      .tooltip-container {
        visibility: hidden;
        width: 100px;
        color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 6px;
        background-color: #555;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        top: -58px;
        left: -11px;
      }

      .tooltip-container::after {
        content: " ";
        position: absolute;
        top: 100%;
        /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    }

    .tooltip-wrapper:hover .tooltip-container {
      visibility: visible;
    }

    .range-products {
      &.last_step {
        justify-content: flex-start;

        @media screen and(max-width: $mobile-bp) {
          padding-top: 25px;
        }

        .single_vision {
          block-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 125px !important;
            height: auto !important;


            @media screen and(max-width: $mobile-bp) {
              margin: 15px 0 !important;
            }

            img {
              max-height: 200px;
              width: 150px !important;

              @media screen and(max-width: $mobile-bp) {
                object-fit: scale-down !important;
              }
            }
          }

          .lens-range {
            width: 243px !important;
          }

          .wrapper-label .wrapper-desc {
            min-height: 0;
          }
        }
      }

      &.treatment {
        .single_vision .wrapper-desc {
          min-height: 0;
        }

        .single_vision block-image {
          width: 243px !important;
          height: auto !important;
        }

        ul input[type="radio"].custom_radio ~ label {
          align-items: center !important;
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 30px 30px 30px 50px;
    
      width: 100%;
      background: #ffffff;

      @media screen and(max-width: $mobile-bp) {
        padding: 25px 25px 75px 25px;
      }

      @media screen and(min-width: $desktop-bp) {
        width: 65%;
      }

      @media screen and(min-width: $large-thr) {
        padding-left: 100px;
      }

      range-description {
        display: flex;
        flex-direction: column;
        line-height: 1.2;
        margin-bottom: 15px;
        font-family: var(--camptonbook);
        font-weight: normal;
        font-size: 18px;

        @media screen and(max-width: $mobile-bp) {
          display: none;
        }

        b,
        strong {
          margin-bottom: 8px;
          font-family: var(--camptonsemibold);
          font-weight: normal;
          font-size: 18px;
        }
      }

      .title-responsive {
        display: none;

        @media screen and(max-width: $mobile-bp) {
          display: block;
          width: 100%;
          text-align: center;
          font-family: var(--camptonsemibold);
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          margin-bottom: 15px;
        }
      }

      h2 {
        padding: 0;
        margin-bottom: 5px;
        font-family: var(--camptonsemibold);
        font-size: 22px;
        font-weight: normal;
        line-height: 1.2;
        text-align: left;
        text-transform: none;
      }

      h3,
      h4 {
        margin-bottom: 30px;
        font-family: var(--camptonbook);
        font-size: 16px;
        font-weight: normal;
        line-height: 1.2;
        color: rgba(44, 46, 53, 0.65);

        @media screen and(max-width: $mobile-bp) {
          margin-bottom: 20px;
        }
      }

      .single_vision {
        reduction-block {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 60px 80px 0 0;
          border-color: #fdc51e transparent transparent transparent;

          reduction-text {
            overflow: hidden;
            max-width: 45px;
            max-height: 20px;
            position: absolute;
            bottom: 35px;
            left: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: var(--camptonmedium);
            font-weight: normal;
            font-size: 7px;
            text-transform: uppercase;
            color: var(--black);

            b,
            strong {
              font-family: var(--camptonsemibold);
              font-weight: normal;
              font-size: 13px;
            }
          }
        }

        li label {
          align-items: center;
        }

        .wrapper-desc {
          display: flex;
          flex-direction: column;
          width: 100%;
          min-height: 115px;
          height: auto;
          line-height: 1.2;

          @media screen and(max-width: $mobile-bp) {
            flex-direction: column;
            text-align: center;

            .price {
              padding: 0;
              width: 100%;
              margin: 25px 0 0 0;
              white-space: nowrap;

              @media screen and(max-width: $mobile-bp) {
                text-align: center;
              }
            }
          }

          .detailed-desc {
            margin-top: 6px;
            text-align: left;

            div {
              margin-top: 6px;
            }
          }

          .wysiwyg {
            font-size: 16px;
            font-weight: normal;

            @media screen and(max-width: $mobile-bp) {
              text-align: left;
            }

            b,
            strong {
              font-family: var(--camptonmedium);
              font-weight: 400 !important;
            }
          }
        }

        .wrapper-top {
          display: flex;
          justify-content: space-between;
          width: 100%;

          @media screen and(max-width: $mobile-bp) {
            order: 3;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .col-1{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
        }

        block-image {
          height: 115px !important;
          width: 170px !important;
          margin-right: 30px;

          @media screen and(max-width: $mobile-bp) {
            width: 100% !important;
            margin: 0 0 15px 0 !important;
          }

          img {
            height: 100% !important;
            width: 100% !important;
          }
        }

        &.thin {
          block-image {
            height: 130px !important;
            width: 60px !important;
            margin-right: 30px;
            margin-left: 15px;

            @media screen and(max-width: $mobile-bp) {
              width: 100% !important;
              margin: 15px 0 !important;
            }

            img {
              object-fit: contain !important;
              height: 100% !important;
              width: 100% !important;
            }
          }
        }

        .three {
          display: flex;
          margin-bottom: 0;
          // flex-direction: column;

          @media screen and(max-width: $mobile-bp) {
            order: -1;
          }

          li {
            display: flex;
            flex-wrap: wrap;
            font-family: var(--camptonbook);
            font-size: 16px;

            &.special_alignment {
              @media screen and (min-width: 345px) and (max-width: 479px) {
                .feature-block {
                  &:nth-child(2n) {
                    position: absolute;
                    right: 20px;
                  }
                }
              }
            }

            .feature-block {
              display: flex;
              align-items: center;
              margin: 0 5px;
              width: calc((100% / 3) - 10px);
              margin-bottom: 3px;
              min-width: 160px;
                .checkbox-icon{
                    background: url(/assets/images/icons/_icon_checkbox_black.svg);
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    height: 11px;
                    width: 15px;
                    margin-right: 5px;
                  }

              div {
                margin-top: 5px;
                text-align: left;
              }

              @media screen and (min-width: 365px) and (max-width: $small-thr) {
                &:nth-child(even) {
                  margin-left: auto;
                }

                &:nth-child(odd) {
                  margin-right: auto;
                }
              }

              @media screen and(max-width: $mobile-bp) {
                width: auto;
                margin-bottom: 15px;
              }

              img {
                width: 15px !important;
                margin-right: 5px;
              }
            }

            .rating-block {
              display: flex;
              align-content: center;
              flex-direction: column;
              margin-right: 15px;
              // width: calc((100% / 3) - 10px);

              div {
                margin-top: 5px;
                text-align: left;

                ul {
                  margin-bottom: 10px;
                  display: flex;

                  li {
                    width: 14px;
                    height: 14px;
                    margin-right: 3px;

                    img {
                      width: 8px;
                      height: 100%;
                    }
                  }
                  .star {
                    background: url(/assets/images/icons/_star.svg);
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                  }
                  .star-half {
                    background: url(/assets/images/icons/_star-half.svg);
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    width: 8px;
                    height: 15px;
                    margin: 0;
                    padding: 0;
                    border: none;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    z-index: 3;
                  }

                  .star-empty {
                    background: url(/assets/images/icons/_star-empty.svg);
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    position: relative;
                  }
                }
              }
            }

            &:first-child {
              @media screen and(max-width: $mobile-bp) {
                margin-bottom: 0;
              }
            }

            &:last-of-type {
              @media screen and(max-width: $mobile-bp) {
                // flex-direction: column;
              }
            }

            .stars-wrapper {
              display: flex;
              flex-direction: column;
              margin: 0 5px 5px 5px;
              width: calc((100% / 3) - 10px);

              @media screen and(max-width: $mobile-bp) {
                align-items: flex-start;
                width: auto;
                margin-bottom: 15px;
              }

              &:last-of-type {
                margin-bottom: 0;
              }

              .card-rate {
                margin-top: 5px;
              }

              .rate-star {
                width: 15px !important;
                height: 15px !important;
                display: inline-block;

                .half-star {
                  height: 15px;
                  width: 15px;
                  position: relative;
                  display: inline-block;

                  img {
                    &:last-of-type {
                      position: absolute;
                      left: -5px;
                      bottom: 0px;
                    }
                  }
                }

                img {
                  width: 15px !important;
                  height: 15px !important;
                  vertical-align: inherit;
                }
              }
            }
          }
        }

        .four {
          margin-top: 20px;
        }
        .five {
          margin: 0px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        .one {
          background-color: #d75931;
          border-radius: 5px;
          margin: 5px 10px 0 0;
          padding: 6px;
          font-size: 10px;
          color: white;
          font-weight: bold;
        }
      }

      .container-step{
        display: grid;
        grid-template-columns: 4fr 6fr;
        gap: 10px;
        align-items: start;
        margin-bottom: 15px;

        .bbgr-info-mobile {
          display: none;
        }

        @media screen and(max-width: $mobile-bp){
          display: flex;
          flex-direction: column;

          .bbgr-info-desktop {
            display: none;
          }
            
          .bbgr-info-mobile {
            display: flex;
          }
        }
      }

      ul {
        margin-bottom: 50px;

        @media screen and(max-width: $mobile-bp) {
          margin-bottom: 15px;
        }

        li {
          margin-bottom: 15px;
          position: relative;

          &:last-of-type {
            margin-bottom: 0;
          }

          .wrapper-label {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and(max-width: $mobile-bp) {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            .title {
              font-family: var(--camptonsemibold);
              font-size: 18px;
              font-weight: normal;
              line-height: 1.2;
              margin-bottom: 10px;

              @media screen and(max-width: $mobile-bp) {
                display: none;
              }

              span {
                color: #76777c;
              }
            }

            .wysiwyg {
              font-size: 16px;
              font-family: var(--camptonbook);
              font-weight: normal;
              line-height: 1.2;
            }

            .price {
              padding: 0 20px 0 10px;
              font-family: var(--camptonsemibold);
              font-size: 16px;
              font-weight: normal;
              line-height: 1.2;
              white-space: nowrap;
            }
          }

          .thinning-percentage {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 90px;
            height: 70px;
            border-left: 45px solid #f3c60b;
            border-top: 35px solid #f3c60b;
            border-right: 45px solid transparent;
            border-bottom: 35px solid transparent;
            .thinning-percentage-txt {
              position: absolute;
              top: -35px;
              left: -44px;
              width: 48px;
              height: 32px;
            }
            .thinning-percentage-upper-txt {
              width: 100%;
              height: 70%;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
              line-height: 24px;
            }
            .thinning-percentage-lower-txt {
              width: 100%;
              height: 30%;
              text-align: center;
              font-size: 9px;
              font-weight: bold;
            }
          }
        }

        .thinning {
          position: relative;
        }

        /*******************************************/
        input[type="radio"].custom_radio {
          display: none;
        }

        input[type="radio"].custom_radio ~ label {
          position: relative;
          display: block;
          display: flex;
          align-items: center;
          padding: 25px 30px;
          cursor: pointer;
          height: auto;
          border: 2px solid rgba(44, 46, 53, 0.1);
          transition: border 0.4s;

          &:hover {
            border: 2px solid rgba(30, 31, 36, 0.37);
          }

          @media screen and(max-width: $mobile-bp) {
            padding: 20px;
            display: flex;
            flex-direction: column;
          }

          .wrapper-image {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;

            @media screen and(max-width: $mobile-bp) {
              margin-right: 0;
              margin-bottom: 15px;
            }
            &.image-wrapper{
              height: 150px !important;
            }

            &.image-wrapper-gamme {
              img {
              min-width: 243px;
              height: 150px;
              object-fit: contain;
              }
            }

            img {
              max-height: 150px;
              max-width: 110px;
              object-fit: contain;
              &.focale_midistance {
                width: 65px;
              }
            }
          }
          .property-wysiwyg{
           @media screen and(max-width: $mobile-bp) {
              margin-top: 25px;
            }
          }
        }

        input[type="radio"].custom_radio ~ label::before {
          display: none;
        }

        input[type="radio"].custom_radio ~ label.lens-replacement-label::after {
          display: none;
        }

        input[type="radio"].custom_radio:checked
          ~ label:not(.lens-replacement-label)::after,
        input[type="radio"].custom_radio:checked ~ label.is-selected::after {
          content: " ";
          position: absolute;
          top: 0;
          right: 0;
          left: auto;
          transform: none;
          background: url(/assets/images/icons/_icon_checkbox_white.svg),
            var(--black) no-repeat;
          background-size: auto;
          background-size: 35px 35px;
          width: 35px;
          height: 35px;
          border-radius: 0;
        }

        input[type="radio"].custom_radio ~ label.is-selected::after {
          display: flex;
        }

        input[type="radio"].custom_radio:checked
          ~ label:not(.lens-replacement-label),
        input[type="radio"].custom_radio:checked ~ label.is-selected {
          display: flex;
          align-items: center;
          border: 2px solid var(--black);
        }

        input[type="radio"].custom_radio:checked
          ~ label:not(.lens-replacement-label)::before,
        input[type="radio"].custom_radio:checked ~ label.is-selected::before {
          border: 2px solid var(--black);
          width: 30px;
          height: 30px;
          background-position: -10px;
        }

        /*******************************************/
      }

      .wrapper-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        @media screen and(max-width: $mobile-bp) {
          flex-direction: column-reverse;
          margin-bottom: 40px;

          .btn_2 {
            padding-left: 30px;
            width: 100%;
          }

          .btn_3 .btn_4 .btn {
            width: 100%;
            margin-left: inherit !important;
          }
        }

        .btn_2 {
          color: var(--black) !important;
          padding-left: 0;

          @media screen and(max-width: $mobile-bp) {
            padding-left: 30px;
          }
        }

        button,
        div,
        p {
          &:last-of-type {
            margin-left: auto;

            @media screen and(max-width: $mobile-bp) {
              margin-left: inherit;
            }
          }
        }

        .disabled {
          cursor: not-allowed !important;
          background: #bfc0c2;
          border: 1px solid #bfc0c2 !important;

          &:hover {
            color: #fff !important;
            border: 1px solid #bfc0c2 !important;
            background: #bfc0c2 !important;
          }
        }
      }

      .legal-wrapper {
        display: flex;
        width: 100%;
        margin-top: auto;

        > svg {
          height: 41px;
          width: 41px;
          margin-right: 25px;
        }

        .legal-desc {
          display: flex;
          flex-direction: column;

          > b,
          > strong {
            font-size: 16px;
            font-family: var(--camptonsemibold);
            font-weight: normal;
            line-height: 1.2;
            margin-bottom: 5px;
          }

          > div {
            font-size: 14px;
            font-family: var(--camptonbook);
            font-weight: normal;
            line-height: 1.2;

            b,
            strong {
              font-family: var(--camptonsemibold);
              font-weight: normal;
            }
          }
        }
      }
    }

    .summary-product {
      display: flex;
      flex-direction: column;
      padding: 30px 50px 0 55px;
      width: 100%;
      background: var(--grey_light);

      @media screen and(max-width: $mobile-bp) {
        padding: 25px;

        .title-block,
        > img,
        .summary-subtitle-wrapper {
          display: none;
        }
      }

      @media screen and(min-width: $desktop-bp) {
        width: 35%;
      }

      &.frametype {
        .summary-wrapper {
          margin-top: auto;

          .title-block {
            display: flex;
            flex-direction: column;
            font-size: 28px;
            margin-bottom: 25px;

            div {
              font-size: 22px;
              font-family: var(--camptonbook);
              font-weight: normal;
            }
          }

          .summary-subtitle-wrapper {
            line-height: 1.4;
          }
        }
      }

      img {
        object-fit: contain;
        height: 160px;
        width: 100%;
        margin-bottom: 20px;
      }

      .summary-subtitle-wrapper {
        display: flex;
        flex-direction: column;

        @media screen and(max-width: $mobile-bp) {
          display: none;
        }

        .name-block {
          margin-bottom: 5px;
          font-family: var(--camptonbook);
          font-size: 14px;
          font-weight: normal;
          text-transform: uppercase;
          line-height: 1.1;
          color: var(--grey);
        }

        .subtitle-block {
          font-family: var(--camptonsemibold);
          font-size: 22px;
          font-weight: normal;
          line-height: 1.2;
          margin-bottom: 35px;
        }

        ul {
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            border-top: 1px solid rgba(44, 46, 53, 0.2);
            padding: 20px 0;
            font-size: 16px;
            font-weight: normal;

            .type_title {
              padding-right: 5px;
              min-width: 165px;
              font-family: var(--camptonmedium);
              color: #76777c;
            }

            .type_desc {
              display: flex;
              align-items: center;
              font-family: var(--camptonbook);
            }
          }
        }
      }

      .title-block {
        font-family: var(--camptonsemibold);
        font-size: 22px;
        font-weight: normal;
        line-height: 1.2;
        margin-bottom: 50px;
      }

      .total-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-left: -55px;
        padding: 25px 50px;
        width: calc(100% + 100px);
        background: var(--black);
        line-height: 1.2;
        font-family: var(--camptonbook);
        font-size: 16px;
        font-weight: normal;
        color: var(--grey);
        position: sticky;
        bottom: 0;

        @media screen and(max-width: $mobile-bp) {
          position: fixed;
          padding: 10px 25px;
          margin-left: 0;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .price {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          line-height: 1.2;
          font-family: var(--camptonbook);
          font-size: 22px;
          font-weight: normal;
          color: #ffffff;

          b,
          strong {
            line-height: 1.2;
            font-family: var(--camptonsemibold);
            font-size: 22px;
            font-weight: normal;
          }
        }
      }
    }
  }
}
table.summary {
  padding: 5px;
  margin-bottom: 50px;
  @media screen and(max-width: $mobile-bp) {
    margin-bottom: 75px;
  }

  tr {
    border-top: 1px solid var(--grey);
    td {
      padding: 10px 5px;

      &:first-child {
        color: var(--grey);
        white-space: nowrap;
      }
    }
    @media screen and(max-width: $mobile-bp) {
      &:first-child {
        border-top: none;
      }
    }
  }
}

.block-lens-selector {
  @media screen and(max-width: 460px) {
    padding: 15px 15px !important;
  }
}

.choices_glasses {
  display: flex;

  @media screen and(max-width: 460px) {
    justify-content: center;
    flex-wrap: wrap;
  }

  li {
    font-family: var(--camptonsemibold);
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.2;
    color: var(--grey_dark);

    @media screen and(max-width: $mobile-bp) {
      font-size: 14px;
    }

    > a {
      display: flex;
      align-items: center;

      .description {
        display: none;

        @media screen and(min-width: $large-thr) {
          display: inherit;
        }
      }
    }
    > a:not(.one-step){
      &:after {
        content: "-";
        font-family: var(--camptonbook);
        font-size: 30px;
        margin: 0 10px;
      }
    }

    &:nth-child(3) {
      @media screen and(max-width: 460px) {
        display: flex;
        align-items: center;

        > a {
          &:after {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      @media screen and(max-width: $mobile-bp) {
        display: flex;
      }

      > a {
        &:after {
          display: none;
        }
      }
    }

    &.is_active {
      color: #ffffff;

      > a .description {
        @media screen and(max-width: $large-thr) {
          display: block;
        }

        @media screen and(max-width: $mobile-bp) {
          display: none;
        }
      }

      .rounded {
        border: 1px solid #fff;

        @media screen and(max-width: $large-thr) {
          margin-right: 10px;
        }

        @media screen and(max-width: $mobile-bp) {
          margin-right: 0;
        }
      }
    }

    &.is_selected {
      color: #ffffff;

      > a .description {
        @media screen and(max-width: $large-thr) {
          display: none;
        }
      }

      .rounded {
        background: #ffffff;
        border: 1px solid #fff;
        position: relative;

        @media screen and(max-width: $large-thr) {
          margin-right: 0;
        }

        &:after {
          position: absolute;
          content: " ";
          top: -5px;
          right: 0px;
          background: url(/assets/images/icons/_icon_checkbox.svg), no-repeat;
          background-size: 40px 40px;
          width: 40px;
          height: 40px;

          @media screen and(max-width: $mobile-bp) {
            top: -7px;
            right: -4px;
          }
        }
      }
    }

    .rounded {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding: 10px;
      border: 1px solid var(--grey_dark);
      height: 35px;
      width: 35px;
      border-radius: 50%;

      @media screen and(max-width: $mobile-bp) {
        height: 28px;
        width: 28px;
      }

      @media screen and(max-width: $large-thr) {
        margin-right: 0;
      }
    }
  }

  .row-transition {
    display: none;

    @media screen and(max-width: 460px) {
      width: 100%;
      height: 5px; 
      display: block
    }
  }
}

.modal_frame block-wrapper {
  padding-left: 100px;
  padding-right: 80px;

  @media (max-width: $large-thr) {
    padding-left: 50px;
  }

  @media (max-width: $mobile-bp) {
    padding-left: 25px;
    padding-right: 25px;
  }

  ul li a {
    width: 100%;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}