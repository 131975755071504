@import "assets/styles/scss/config/globals";

.registered_order {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.2;

  @media screen and(max-width: $mobile-bp) {
    margin-top: 40px;
    padding: 0;
  }

  h1 {
    margin-bottom: 15px;
    font-family: var(--camptonsemibold);
    font-weight: normal;
    font-size: 28px;

    @media screen and(max-width: $mobile-bp) {
      font-size: 22px;
      text-align: center;
    }
  }

  div,
  p,
  span {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.4;
    font-family: var(--camptonbook);
    font-weight: normal;
    font-size: 18px;

    @media screen and(max-width: $mobile-bp) {
      font-size: 16px;
    }

    b,
    strong {
      font-family: var(--camptonsemibold);
      font-weight: normal;
      font-size: 18px;

      @media screen and(max-width: $mobile-bp) {
        font-size: 16px;
      }
    }
  }

  .legal_terms {
    margin-left: 20px;

    input[type="checkbox"].custom_checkbox ~ label {
      padding-left: 50px !important;
      line-height: 1.4;
    }

    input[type="checkbox"].custom_checkbox ~ label::before {
      border: 2px solid rgba(44, 46, 53, 0.2);
      top: 0 !important;
      transform: translateY(0) !important;
    }

    @media screen and(max-width: $mobile-bp) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .connexion-wrapper {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .connexion-wrapper div.inscription-btn {
    margin-top: 55px;
  }
}

.registered_order.wrapper {
  margin-top: 50px;
  margin-bottom: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.2;

  @media screen and(max-width: $mobile-bp) {
    margin-top: 40px;
    padding: 0;
  }

  h1 {
    margin-bottom: 15px;
    font-family: var(--camptonsemibold);
    font-weight: normal;
    font-size: 28px;

    @media screen and(max-width: $mobile-bp) {
      font-size: 22px;
      text-align: center;
    }
  }

  div,
  p,
  span {
    display: block;
    text-align: center;
    margin-bottom: 50px;
    line-height: 1.4;
    font-family: var(--camptonbook);
    font-weight: normal;
    font-size: 18px;

    @media screen and(max-width: $mobile-bp) {
      font-size: 16px;
    }

    b,
    strong {
      font-family: var(--camptonsemibold);
      font-weight: normal;
      font-size: 18px;

      @media screen and(max-width: $mobile-bp) {
        font-size: 16px;
      }
    }
  }
}

.legal_terms {
  margin-left: 19px;

  input[type="checkbox"].custom_checkbox ~ label {
    padding-left: 50px !important;
    line-height: 1.4;
  }

  input[type="checkbox"].custom_checkbox ~ label::before {
    border: 2px solid rgba(44, 46, 53, 0.2);
    top: 0 !important;
    transform: translateY(0) !important;
  }

  @media screen and(max-width: $mobile-bp) {
    margin-left: 19px;
    margin-right: 0;
  }
}

// Overrides the margin inside select li elements
.dropdown-menu {
  li {
    margin-bottom: 0 !important;
  }
}

.form-prescription,
.form_order {
  margin-bottom: 50px;
  font-size: 18px;
  font-family: var(--camptonbook);
  line-height: 1.4;

  &:nth-child(3) {
    margin-bottom: 0px;

    @media screen and(min-width: $desktop-bp) {
      margin-bottom: 50px;
    }
  }

  li {
    margin-bottom: 25px;

    input[type="radio"].custom_radio ~ label {
      width: 100%;
    }

    input[type="radio"].custom_radio ~ label::after,
    input[type="radio"].custom_radio ~ label::before {
      top: 15px;
    }

    label {
      padding-top: 0 !important;

      b,
      strong {
        font-family: var(--camptonsemibold);
        font-size: 18px;
        font-weight: normal;
        line-height: 1.2;
        color: #078b47;
      }
    }

    .former-order-prescription-select {
      max-width: 400px;

      div.dropdown.bootstrap-select {
        width: 100% !important;
      }
    }

    .former-order-prescriptions-table {
      margin-top: 20px;
      font-size: 14px;

      .right-border {
        border-right: 1px solid var(--grey_light);
      }

      .align-left {
        text-align: left !important;
      }

      .darker-bg {
        background-color: var(--grey_light);
      }
    }

    .desktop-table {
      display: table;
      @media screen and(max-width: $mobile-bp) {
        display: none;
      }

      th {
        padding: 10px 20px 10px 20px;
      }

      td {
        padding: 10px 20px 10px 20px;
        text-align: center;
        border-bottom: 1px solid var(--grey_light);
      }
    }

    .mobile-table {
      display: none;
      @media screen and(max-width: $mobile-bp) {
        display: table;
      }

      th {
        text-align: center;
        padding: 10px 20px 10px 20px;
        border-right: 1px solid var(--grey_light);
      }

      td {
        padding: 10px 20px 10px 20px;
        text-align: center;
        border-right: 1px solid var(--grey_light);
      }

      .td-title {
        padding: 10px 15px 10px 15px;
        text-align: left;
      }

      .bottom-border {
        border-bottom: 1px solid var(--grey_light);
      }
    }

    .prescriptor {
      margin-top: 20px;
      font-size: 17px;
    }
  }
}

.orders + .overlay {
  overlay-filter {
    padding-top: 55px;

    h2 {
      margin-bottom: 55px;
      font-family: var(--camptonsemibold);
      font-weight: normal;
      font-size: 22px;
      line-height: 1.2;
    }

    h3 {
      margin-bottom: 15px;
      line-height: 1.2;
      text-transform: uppercase;
      font-family: var(--camptonsemibold);
      font-size: 14px;
      font-weight: normal;
      color: var(--red);
    }

    head-filter img {
      height: 50px;
      width: 50px;
    }

    form {
      .form-row {
        margin-bottom: 25px;

        input {
          width: 100%;
        }
      }

      .form-row.inscription-radio-row {
        margin-bottom: 10px;
      }

      block-title {
        display: flex;
        margin-bottom: 15px;
        font-size: 18px;
      }

      .by-3 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 45px;

        > div {
          width: calc((100% / 3) - 15px);
        }

        .bootstrap-select > button {
          height: 55px;
          border: 1px solid rgba(44, 46, 53, 0.2);
          font-size: 18px;
          font-family: var(--camptonbook);
          font-weight: normal;
          padding: 16px 20px;
        }

        .bootstrap-select > .dropdown-menu.show {
          border: 1px solid rgba(44, 46, 53, 0.2);
          top: 0px !important;
        }

        .bootstrap-select .dropdown-menu.inner li {
          padding: 16px 20px;
        }
      }
    }

    @media screen and(max-width: $mobile-bp) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
.bootstrap-select ul.dropdown-menu.inner.show{
  max-height: 330px !important;
}

.choice_step {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media screen and(min-width: $desktop-bp) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  li {
    position: relative;
    margin-right: 50px;
    font-family: var(--camptonbook);
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.2;
    color: rgba(44, 46, 53, 0.5);

    @media screen and(max-width: $mobile-bp) {
      margin-right: 20px;

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      &::after {
        content: "- ";
        position: absolute;
        top: 50%;
        right: -17px;
        font-family: var(--camptonbook);
        font-size: 25px;
        font-weight: normal;
        transform: translateY(-50%);
      }
    }

    > a {
      display: flex;
      align-items: center;

      .description {
        @media screen and(max-width: $large-thr) {
          display: none;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;

      @media screen and(max-width: $mobile-bp) {
        display: flex;
      }

      > a {
        &:after {
          display: none;
        }
      }
    }

    &.is_active {
      color: var(--black);
      font-family: var(--camptonsemibold);

      > a .description {
        @media screen and(max-width: $large-thr) {
          display: block;
        }

        @media screen and(max-width: $mobile-bp) {
          display: none;
        }
      }

      .rounded {
        border: 1px solid var(--black);

        @media screen and(max-width: $large-thr) {
          margin-right: 10px;
        }

        @media screen and(max-width: $mobile-bp) {
          margin-right: 0;
        }
      }
    }

    &.is_selected {
      color: var(--black);

      > a .description {
        @media screen and(max-width: $large-thr) {
          display: none;
        }
      }

      .rounded {
        background: var(--black);
        border: 1px solid var(--black);
        position: relative;

        @media screen and(max-width: $large-thr) {
          margin-right: 0;
        }

        &:after {
          position: absolute;
          content: " ";
          top: -5px;
          right: 0px;
          background: url(/assets/images/icons/_icon_checkbox_white.svg),
            no-repeat;
          background-size: 40px 40px;
          width: 40px;
          height: 40px;
        }
      }
    }

    .rounded {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding: 10px;
      border: 1px solid var(--grey_dark);
      height: 35px;
      width: 35px;
      font-family: var(--camptonsemibold);
      font-size: 18px;
      border-radius: 50%;

      @media screen and(max-width: $large-thr) {
        margin-right: 0;
      }
    }
  }
}

dashboard-wrapper {
  display: flex;
  flex-direction: column;

  @media screen and(min-width: $desktop-bp) {
    flex-direction: row;
  }

  dashboard-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-bottom: 30px;

    input {
      &:disabled {
        background-color: var(--grey_disabled);
      }
    }

    div.cb-panel {
      display: none;

      @media screen and(max-width: $mobile-bp) {
        .adyen-checkout__card__exp-cvc.adyen-checkout__field-wrapper {
          flex-direction: column;

          > div {
            margin: 0 0 15px 0;
            width: 100%;
          }
        }
      }

      > div {
        margin-top: 15px;
      }

      .adyen-checkout__label {
        padding: 20px 40px !important;
        flex-direction: column;

        .adyen-checkout__label__text {
          padding: 2px 0px 0px 0px;
          margin-bottom: 10px;
          font-size: 16px;
        }

        .adyen-checkout__input-wrapper > span {
          border-radius: 0;
        }
      }

      .return-to-payment-list {
        margin-top: 40px;
        margin-bottom: 50px;
        text-decoration: underline;
        &:hover{
          font-weight: bold;
        }

        @media screen and(max-width: $mobile-bp) {
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
    }

    li.hidden-error {
      display: none;
    }

    .error-alert {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      border: 2px solid #b63e5a;
      color: #b63e5a;

      .error-exclamation-icon {
        min-width: 20px;
        height: 20px;
        margin-left: 10px;
        background-color: #b63e5a;
        -webkit-mask: url(/assets/images/fontawesome/triangle-exclamation.svg)
          no-repeat center;
        mask: url(/assets/images/fontawesome/triangle-exclamation.svg) no-repeat
          center;
      }

      .error-text {
        margin-left: 20px;
        font-weight: bold;
      }
    }

    div.payment-button-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 30px;

      #paypal_btn {
        width: 250px;
      }

      @media screen and(min-width: $desktop-bp) {
        padding-bottom: 50px;
      }
    }
    .loading-paypal{
      .loading-message{
        padding: 10px 15px;
        margin: 0 auto 30px auto;
        text-align: center;
      }
      .text-red{
        padding-top: 0;
        font-family:var(--camptonsemibold);
        color: var(--red);
      }
      img{
        height: 35px;
      }
    }

    @media screen and(min-width: $desktop-bp) {
      padding: 0 30px 0 15px;
      margin-bottom: 0;
      width: 75%;
    }

    @media screen and (min-width: $desktop-bp) and (max-width: $large-thr) {
      width: 70%;
    }

    @media screen and(max-width: $mobile-bp) {
      margin-bottom: 50px;
    }

    form {
      h2 {
        margin-bottom: 20px;
        color: rgb(200, 97, 61);
        text-transform: uppercase;
      }

      .prescription-textarea {
        width: 100%;
        min-height: 150px;
      }

      .prescription-validation {
        margin-bottom: 30px;
        margin-top: 20px;
        text-align: center;
      }
    }

    > a {
      margin: 0 auto;
    }

    card-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 30px 0;
      padding: 40px 0;
      border: 1px solid #dededf;

      @media screen and (min-width: $desktop-bp) {
        margin: 0 30px 50px 15px;
      }

      @media screen and (max-width: $mobile-bp) {
        flex-direction: column;
        padding: 40px 15px;
      }

      @media screen and (min-width: $desktop-bp) and (max-width: 1193px) { // 1193? Why such a very specific width?
        margin: 0 0 50px 0;
        padding: 40px 15px;
      }

      wrapper-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 30px;
        width: 43%;
        font-family: var(--camptonsemibold);
        font-weight: normal;
        font-size: 18px;

        @media screen and (max-width: $mobile-bp) {
          width: 100%;
          padding-right: 0;
          margin-bottom: 35px;
        }

        img {
          margin-bottom: 15px;
          object-fit: contain;
          width: 240px;

          @media screen and (max-width: $small-thr) {
            width: 150px;
          }
        }
      }

      wrapper-description {
        width: calc(57% - 75px);
        margin-right: 30px;

        @media screen and (min-width: $desktop-bp) and (max-width: 1193px) {
          margin-right: 15px;
        }

        @media screen and (max-width: $mobile-bp) {
          width: 100%;
        }

        beautified-select {
          margin-top: 20px;

          @media screen and (max-width: $mobile-bp) {
            margin-left: 15px;
            margin-right: 15px;
          }
        }

        beautiful-select {
          margin-bottom: 20px;

          beautiful-title {
            justify-content: flex-start !important;
          }

          beautified-select beautiful-title.active {
            border-bottom: 1px solid rgba(44, 46, 53, 0.2) !important;
          }

          beautified-select beautiful-title.active,
          beautiful-list.unfolded,
          beautiful-title {
            border: 1px solid rgba(44, 46, 53, 0.2) !important;
          }
        }

        .btn_5,
        button {
          width: 100%;

          @media screen and (max-width: $mobile-bp) {
            padding: 15px;
          }

          span {
            position: relative;

            &::before {
              content: "+";
              position: absolute;
              left: -20px;
              right: auto;
              top: -5px;
              font-size: 23px;
              font-family: var(--camptonbook);
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  dashboard-aside {
    display: flex;
    flex-direction: column;
    width: 100%;
    .dashboard-bold-price {
      min-width: max-content;
      font-family: var(--camptonmedium);
      margin-left: 20px;
    }
    .w-max-content{
      width: max-content
    }
    @media screen and (min-width: $desktop-bp) and (max-width: 1199px) {
      width: 35%;
      margin-right: 15px;
    }

    @media screen and(min-width: $desktop-bp) {
      width: 35%;
    }

    > a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      margin-bottom: 10px;
      font-family: var(--camptonmedium);
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
      color: rgba(44, 46, 53, 0.35);

      @media screen and(max-width: $mobile-bp) {
        margin-bottom: 20px;
      }

      &::before {
        content: " ";
        position: absolute;
        top: -3px;
        right: auto;
        left: -5px;
        background: url(/assets/images/icons/_icon_chevron_slider_grey.svg);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
      }
    }

    ul {
      padding: 30px;
      margin-bottom: 20px;
      border: 2px solid #dededf;

      li {
        //"Total" in black
        &:last-child {
          .line {
            font-size: 16px;
            font-weight: normal;
            color: var(--black);
          }
        }

        &:last-of-type {
          border: none;
          padding: 0;
          margin: 0;

          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--camptonmedium);
            font-size: 14px;
            font-weight: normal;
            text-transform: uppercase;
            text-decoration: underline;
          }
        }

        .title {
          margin-bottom: 5px;
          font-family: var(--camptonmedium);
          font-size: 16px;
          font-weight: normal;
          line-height: 1.2;
        }

        .voucher_details {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.5;
          font-family: var(--camptonbook);
          color: var(--grey_dark);

          padding-bottom: 15px;
          margin-bottom: 20px;
          border-bottom: 1px solid #dededf;
        }

        .voucher_line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          font-family: var(--camptonmedium);
          font-size: 16px;
          font-weight: normal;
          line-height: 1.2;
          
          span {
            color: #2c2e35;
          }
        }

        .line-margins-override {
          padding-bottom: 0px !important;
          margin-bottom: 0px !important;
        }

        .inner-warranty-line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          font-size: 14px;
          font-weight: normal;
          font-family: var(--camptonbook);
          color: var(--grey_dark);

          span {
            &:last-of-type {
              font-size: 16px;
              color: var(--black);
              font-family: var(--camptonmedium);
            }
          }
        }

        .end-line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          font-size: 14px;
          font-weight: normal;
          font-family: var(--camptonbook);
          color: var(--grey_dark);
          margin-bottom: 20px;
          border-bottom: 1px solid #dededf;
        }

        .line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          font-size: 14px;
          font-weight: normal;
          font-family: var(--camptonbook);
          color: var(--grey_dark);

          &:last-of-type {
            padding-bottom: 15px;
            margin-bottom: 20px;
            border-bottom: 1px solid #dededf;
          }

          &.subtotal {
            font-size: 18px;
            font-weight: normal;
            font-family: var(--camptonbook);
            color: var(--black);
            border-bottom: none;

            span {
              &:last-of-type {
                font-family: var(--camptonsemibold);
              }
            }
          }

          span:not(:first-of-type) {
              font-size: 16px;
              color: var(--black);
          }
          .price-item span:last-of-type {
              font-size: 14px;
              color: var(--grey_dark);
              font-family: var(--camptonbook);
              white-space: nowrap;
            }
            .shipping-info {
              color: var(--black);
            }
        }

        .second-pair-line {
          font-family: var(--camptonmedium);
          font-size: 16px;
          font-weight: normal;
          color: var(--black);
        }

        .accordion {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .accordion_responsive {
            display: flex;
            position: relative;
            cursor: pointer;
            font-family: var(--camptonmedium);
            font-size: 16px;
            font-weight: normal;
            line-height: 1.2;
            color: var(--black);

            &::after {
              content: " ";
              position: absolute;
              top: -10px;
              right: -14px;
              left: auto;
              background: url(/assets/images/icons/icon_listing_chevron.svg);
              background-repeat: no-repeat;
              background-size: 40px 40px;
              width: 40px;
              height: 40px;
              transform: rotate(0deg);
              transition: all 0.25s ease-in-out;
            }

            &.is_active {
              &:after {
                transform: rotate(180deg);
              }
            }
          }

          .dashboard-accordion-responsive {
            display: flex;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            font-family: var(--camptonmedium);
            font-size: 16px;
            font-weight: normal;
            line-height: 1.2;
            color: var(--black);

            &:hover {
              font-weight: bold;
            }


            &.is_active {
              &:after {
                transform: rotate(180deg);
              }
            }
          }

          .dashboard-accordion-responsive + .content_text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            line-height: 1.4;
            font-size: 14px;
            font-weight: normal;
            font-family: var(--camptonbook);
            color: var(--grey_dark);
          }
          .price-item {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              color: var(--grey_dark);   
          }
          .dashboard-accordion-responsive .chevron {
              width: 20px;
              height: 20px;
              background: url(/assets/images/icons/icon_listing_chevron.svg) no-repeat center center;
              background-size: 40px 40px;
              transition: transform 0.25s ease-in-out;
          }
          .dashboard-accordion-responsive.is_active .chevron {
              transform: rotate(180deg);
          }
          .chevron {
            position: absolute;
            top: 0;
            right: -20px;
          }

          .accordion_responsive + .content_text {
            display: flex;
            justify-content: space-between;
            line-height: 1.4;
            font-size: 14px;
            font-weight: normal;
            font-family: var(--camptonbook);
            color: var(--grey_dark);
          }

          .content_text {
            max-height: 0;
            padding: 5px 0 0 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
          }
        }

        .return-to-cart {
          display: flex;
          justify-content: center;
          
          a {
            text-decoration: underline;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }

    advices-block {
      padding: 20px;

      advices-desc {
        margin-left: 15px;
      }
    }

    .return-to-listing {
      cursor: default !important;
        div {
        &:hover {
          cursor: pointer;
          font-weight: bold;
          color: var(--grey_dark);
        }
      }
    }
  }
}

beneficiary-box {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 0 0 30px 0;
  background: var(--grey_light);
  font-family: var(--camptonsemibold);
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;

  @media screen and(min-width: $desktop-bp) {
    margin-right: 30px;
    margin-left: 15px;
  }

  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
    margin-right: 10px;
  }
}

/*****************************************/
.form-prescription .radio_choice.is_active ~ upload-box,
.form_order .radio_choice.is_active ~ upload-box {
  padding: 50px 10px;
  margin-top: 20px;
  border: 1px dashed #d9d1c8;

  &.file_change {
    border: 1px solid #d9d1c8;
  }
}

.form-prescription .radio_choice.is_active ~ global-uno,
.form-prescription .radio_choice.is_active ~ global-duo,
.form-prescription .radio_choice.is_active ~ global-four,
.form_order .radio_choice.is_active ~ global-uno,
.form_order .radio_choice.is_active ~ global-duo,
.form_order .radio_choice.is_active ~ global-four {
  height: auto;
  overflow: initial;
}

upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  margin-left: 40px;

  @media screen and(max-width: $mobile-bp) {
    margin-left: 0;
  }

  &.file_change {
    .upload_file {
      cursor: pointer;
      display: block;
      position: absolute;
      right: 50px;
      z-index: 999;
    }

    select-file {
      display: none;
    }

    file-name {
      cursor: text;
      display: flex;
      align-items: center;
      position: absolute;
      left: 50px;
      padding-right: 65px;
      font-size: 16px;
      font-family: var(--camptonbook);
      font-weight: normal;

      &::before {
        content: " ";
        margin-right: 25px;
        background: url(/assets/images/icons/_icon_pdf.svg);
        background-repeat: no-repeat;
        background-size: 30px 30px;
        width: 30px;
        height: 30px;
      }
    }
  }

  input[type="file"] {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .upload_file {
    display: none;
  }

  select-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: 1;
    font-size: 14px;
    font-family: var(--camptonbook);
    font-weight: normal;
    color: #76777c;

    b,
    strong {
      margin-bottom: 5px;
      font-family: var(--camptonsemibold);
      font-size: 16px;
      font-weight: normal;
      color: #d75931;
    }
  }

  file-name {
    display: none;
  }
}

global-uno {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  h3 {
    margin-bottom: 15px;
    font-family: var(--camptonmedium);
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
  }

  wrapper-uno {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 40px;
    
    @media screen and(max-width: $mobile-bp) {
      flex-wrap: wrap;
      margin-left: 0;
    }
  }
}

global-duo,
global-four {
  display: block;
  margin-top: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  h3 {
    margin-left: 40px;
    margin-bottom: 15px;
    font-family: var(--camptonmedium);
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;

    @media screen and(max-width: $mobile-bp) {
      margin-left: 0;
    }
  }

  wrapper-four {
    display: flex;
    margin-left: 40px;
    &:last-of-type {
      > div {
        margin-bottom: 0 !important;
      }
    }

    @media screen and(max-width: $mobile-bp) {
      flex-wrap: wrap;
      margin-left: 0;
      &:last-of-type {
        > div {
          margin-bottom: 30px !important;
        }
      }
    }

    beautified-select {
      font-size: 14px !important;
    }

    label {
      line-height: 1.2;
      font-size: 14px !important;
    }

    > div {
      width: calc(100% / 4);
      margin-right: 30px;
      margin-bottom: 30px !important;

      @media screen and(max-width: $mobile-bp) {
        width: calc(50% - 5px);
        margin-right: 10px;
        margin-bottom: 20px !important;

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  wrapper-duo {
    display: flex;
    margin-left: 40px;
    &:last-of-type {
      > div {
        margin-bottom: 0 !important;
      }
    }

    @media screen and(max-width: $mobile-bp) {
      flex-wrap: wrap;
      margin-left: 0;
      &:last-of-type {
        > div {
          margin-bottom: 20px !important;
        }
      }
    }

    .fake-select {
      border: 1px solid rgba(44, 46, 53, 0.2);
      padding: 16px 30px 16px 20px;
      cursor: inherit !important;
      background-color: var(--grey_disabled);
    }

    beautified-select {
      font-size: 14px !important;
    }

    label {
      line-height: 1.2;
      font-size: 14px !important;
    }

    > div {
      width: calc(100% / 4);
      margin-right: 30px;
      margin-bottom: 30px !important;

      @media screen and(max-width: $mobile-bp) {
        width: calc(50% - 5px);
        margin-right: 10px;
        margin-bottom: 20px !important;

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

/*******************************************************************************************/

.orders.home_trial
  .shipping
  .shipping_block:first-child
  wrapper-image:first-child
  img {
  width: 100%;
}

.shipping {
  margin: 0;
  padding-left: 0;

  @media screen and(min-width: $desktop-bp) {
    margin: 0 30px 0 15px;
  }

  .shipping_block {
    padding-bottom: 20px;

    .same-address-checkbox {
      display: flex !important;
      padding: 20px 40px 20px 50px !important;
      border: 0px !important;
      margin-bottom: 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .store-title {
      color: #dc704e;
      text-transform: uppercase;
      padding-left: 25px;
      font-weight: bold;
    }

    .service-store {
      display: flex;
      margin-top: 14px;

      form {
        label {
          display: flex !important;
          border: 0px !important;
          padding: 20px 20px 20px 30px !important;
        }

        .store-selection {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          sup {
            font-size: 100% !important;
          }

          .dropdown {
            width: 70% !important;
          }

          .bootstrap-select .dropdown-menu.show {
            height: 310px;
            min-height: 310px;
            max-height: 310px;
          }

          button {
            margin-left: 40px;
          }

          .store-address {
            font-family: var(--camptonbook);
            display: flex;
            flex-wrap: wrap;
            text-transform: uppercase;
            width: 70%;
            color: var(--grey_dark) !important;
            margin-top: 20px;
            margin-left: 40px;
            padding: 30px 15px;
            background-color: #f6f6f6;
            line-height: 1.2;
            font-size: 18px;


            strong {
              font-family: var(--camptonsemibold);
              margin-bottom: 5px;
            }
          }
        }

        .store-validation {
          display: flex;
          justify-content: center;
          padding-top: 30px;
          padding-bottom: 40px;
        }
      }
    }

    input {
      width: auto;
    }

    input[type="radio"].custom_radio ~ label::before {
      left: 20px;
      border: 2px solid rgba(44, 46, 53, 0.2);
      
      @media screen and(max-width: $mobile-bp) {
        top: 40px;
      }
    }

    input[type="radio"].custom_radio:checked ~ label::after {
      left: 27px;
      width: 16px;
      height: 16px;

      @media screen and(max-width: $mobile-bp) {
        top: 32px;
        transform: translateY(0);
      }
    }

    input[type="radio"].custom_radio.is_active ~ label {
      border-right: 1px solid var(--black);
      border-left: 1px solid var(--black);
      border-top: 1px solid var(--black);
      border-bottom: none;
    }

    label {
      display: flex !important;
      border: 2px solid #dededf;
      border-bottom: 1px solid #dededf;
      padding: 20px 40px 20px 80px !important;
      transition: border 0.4s;

      &:hover {
        border: 2px solid rgba(30, 31, 36, 0.37);
        border-bottom: 1px solid rgba(30, 31, 36, 0.37);
      }

      @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
        padding: 20px 15px 20px 70px !important;
      }

      &.radio_choice.is_active {
        border-top: 2px solid var(--black);
        border-left: 2px solid var(--black);
        border-right: 2px solid var(--black);
        border-bottom: none;
      }
      
      &.radio_choice.is_active ~ wrapper-accordion {
        height: auto;
        max-height: none !important;
        border-bottom: 2px solid var(--black);
        border-left: 2px solid var(--black);
        border-right: 2px solid var(--black);
        transition: border 0.4s;
      }

      &.radio_choice.is_active ~ form,
      &.radio_choice.is_active ~ form wrapper-accordion {
        height: auto;
        max-height: none !important;
      }

      &.radio_choice.is_active ~ form,
      &.radio_choice.is_active ~ .payment_description {
        border-bottom: 2px solid var(--black);
        border-left: 2px solid var(--black);
        border-right: 2px solid var(--black);
        // transition: border 0.4s;
      }

      wrapper-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        flex: 0 0 55px;
        margin-right: 20px;

        @media screen and(max-width: $mobile-bp) {
          justify-content: flex-start;
        }

        img {
          object-fit: contain;
        }
      }

      wrapper-description {
        width: 70%;

        @media screen and(max-width: $mobile-bp) {
          width: 100%;
        }

        div {
          margin-bottom: 5px;
          line-height: 1.2;
          font-family: var(--camptonmedium);
          font-weight: normal;
          font-size: 18px;
        }
      }

      .price {
        display: flex;
        align-items: center;
        margin-left: auto;
        line-height: 1.2;
        font-family: var(--camptonsemibold);
        font-size: 16px;
        font-weight: normal;
        white-space: nowrap;

        @media screen and(max-width: $mobile-bp) {
          margin-left: 0px;
          margin-top: 15px;
        }
      }
    }

    wrapper-accordion {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.1s ease-out;
      border-top: none;

      h2 {
        margin-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        line-height: 1.2;
        text-transform: uppercase;
        font-family: var(--camptonsemibold);
        font-size: 14px;
        font-weight: normal;
        color: var(--red);

        @media screen and(min-width: $desktop-bp) {
          width: 80%;
        }
      }

      form {
        width: 100%;
        
        @media screen and(min-width: $desktop-bp) {
          width: calc(70% + 155px);
        }
      }

      wrapper-input {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
        width: 80%;

        > div {
          margin-bottom: 5px;
          font-size: 14px;

          sup {
            font-size: 100%;
          }
        }

        input-group form {
          display: flex;

          input[type="search"] {
            border-radius: 0;
            border: 1px solid rgba(44, 46, 53, 0.2);
            height: 50px;
            width: 100%;
            padding: 15px;
            font-family: var(--camptonbook);
            font-size: 18px;
            background: #fff;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 50px;
            height: 50px;
            width: 50px;
            background: var(--black);
          }
        }
      }
    }
    .overflow-visible {
      overflow: visible;
      margin-top: -15px;
    }
  }
}

@mixin shipping-wrapper-description {
  wrapper-description {
    font-family: var(--camptonmedium);
    font-size: 18px;
    color: var(--grey_dark);

    .subtitle {
      font-family: var(--camptonmedium);
      font-size: 18px;
      color: var(--black);
    }
  }
}

.shipping.relay {
  @include shipping-wrapper-description;
}

.shipping.payment {
  input[type="radio"].custom_radio:checked ~ label {
    border: 2px solid var(--black);
    border-bottom: none;
  }

  .shipping_block input[type="radio"].custom_radio ~ label::after {
    top: 27px;
    transform: translateY(0);
  }

  .shipping_block input[type="radio"].custom_radio ~ label::before {
    top: 20px;
    transform: translateY(0);
  }

  label {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  @include shipping-wrapper-description;

  wrapper-image {
    margin-left: 20px;
    margin-right: 10px !important;
    flex: 0 0 90px !important;
    width: 90px !important;

    @media screen and(max-width: $mobile-bp) {
      flex: auto !important;
      margin-left: 0;
      margin-right: 0;
      margin-top: 5px;
    }
  }

  .payment_description {
    padding: 0 150px 20px 80px;

    @media screen and(max-width: $mobile-bp) {
      padding: 20px 15px 20px 70px;
    }
  }
}

.tooltip_wrapper {
  position: relative;
  display: flex-end;

  &:hover .tooltip_text {
    visibility: visible;
    opacity: 1;
    background-color: white;
  }

  .tooltip_text {
    display: none;

    @media screen and (min-width: $desktop-bp) {    
      display: block;
      visibility: hidden;
      width: 350px;
      color: var(--black);
      padding: 14px;
      position: absolute;
      z-index: 1;
      right: -350px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 14px;
      font-family: var(--camptonbook);
      line-height: 1.2;
      text-align: left;
      border: solid 1px lightgray;
      box-shadow: 10px 10px 10px gray;
    }

    @media screen and (min-width: $desktop-bp) and (max-width: 1299px) {
      width: 290px;
      right: -320px;
    }
    b,
    strong {
      display: block;
      margin-bottom: 3px;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 0px;
      left: 4px;
      top: 50%;
      border: 10px solid transparent;
      border-left: solid 0px;
      border-right: 10px solid lightgray;
      transform: translate(calc(-100% - 5px), -50%);
    }
  }
}

.default-hidden {
  display: none;
}

.form_shipping {
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: 80% !important;

  .pickup-point-address-text {
    line-height: 1.2;
    margin-bottom: 10px;
  }

  // input[type="radio"] ~ label::after {
  //     top: 0 !important;
  // }

  // input[type="radio"] ~ label::before {
  //     top: 8px !important;
  // }
  .inscription-radio-row {
    @media screen and(max-width: $mobile-bp) {
      input[type="radio"] ~ label::after {
        top: 0 !important;
      }

      input[type="radio"] ~ label::before {
        top: 8px !important;
      }

      font-size: 16px;
    }

    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;
    }

    label {
      border: none !important;
      padding: 0 !important;
    }
  }

  @media screen and(max-width: $mobile-bp) {
    width: 100% !important;
  }

  > .btn_3,
  > button {
    display: block;
    margin: auto;
    margin-bottom: 15px !important;
  }

  input[type="text"] {
    width: auto !important;
    background-color: var(--grey_disabled);
    cursor: not-allowed;
    font-size: 18px !important;
  }

  input.not-readonly {
    background-color: transparent !important;
    cursor: inherit !important;
  }

  .by-2 {
    display: flex;
    flex-wrap: wrap;

    .form-row {
      width: calc(50% - 10px);

      @media screen and(max-width: $mobile-bp) {
        width: 100%;
      }

      &:first-child {
        margin-right: 10px;

        @media screen and(max-width: $mobile-bp) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-left: 10px;

        @media screen and(max-width: $mobile-bp) {
          margin-left: 0;
        }
      }
    }

    label {
      border: none !important;
      padding: 0 !important;

      @media screen and(max-width: $mobile-bp) {
        flex-direction: row !important;
      }
    }
  }

  .by-3 {
    display: flex;
    flex-wrap: wrap;

    .form-row {
      width: calc((100% / 3) - 15px);

      @media screen and(max-width: $mobile-bp) {
        width: 100%;
      }

      &:first-child {
        margin-right: 20px;

        @media screen and(max-width: $mobile-bp) {
          margin-left: 0;
        }
      }

      &:nth-child(2) {
        margin-right: 20px;

        @media screen and(max-width: $mobile-bp) {
          margin-right: 0;
        }
      }
    }

    label {
      border: none !important;
      padding: 0 !important;

      @media screen and(max-width: $mobile-bp) {
        flex-direction: row !important;
      }
    }
  }

  input[type="radio"].custom_radio ~ label {
    display: flex;
    padding: 0 0 0 40px !important;
    align-items: center;
    margin-left: 40px;
    border: none;

    &::before {
      left: 0 !important;
    }

    &::after {
      left: 7px !important;
    }
  }

  .billing {
    margin-bottom: 30px;

    label {
      margin-left: 0 !important;

      @media screen and(max-width: $mobile-bp) {
        align-items: flex-start !important;
      }
    }
  }
}

.listing_shop {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and(min-width: $desktop-bp) {
    width: 80%;
  }

  li {
    display: flex;
    padding: 30px;
    margin-bottom: 15px;
    background: #f6f6f6;
    width: 100%;

    @media screen and(max-width: $mobile-bp) {
      padding: 30px 15px;
      flex-direction: column;
    }

    &.is_selected {
      border: 1px solid var(--black);

      wrapper-choice .btn {
        background-color: var(--black);
        color: #fff !important;
      }
    }

    wrapper-choice {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media screen and(max-width: $mobile-bp) {
        width: 100%;
        justify-content: center;
      }
    }

    wrapper-description {
      width: 50%;

      @media screen and(max-width: $mobile-bp) {
        width: 100%;
        margin-bottom: 20px;
      }

      h3 {
        margin-bottom: 5px;
        line-height: 1.2;
        font-size: 18px;
        font-family: var(--camptonsemibold);
        font-weight: normal;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      > div,
      > p {
        margin-bottom: 5px;
        line-height: 1.2;
        font-size: 18px;
        font-family: var(--camptonbook);
        font-weight: normal;
        text-transform: lowercase;
      }

      a {
        line-height: 1.2;
        font-size: 14px;
        font-family: var(--camptonmedium);
        font-weight: normal;
        text-transform: uppercase;
        text-decoration: underline;
        color: #76777c;
      }

      .accordion_responsive {
        display: flex;
        position: relative;
        margin-top: 10px;
        text-transform: uppercase;
        text-decoration: underline;
        cursor: pointer;
        font-family: var(--camptonmedium);
        font-size: 14px;
        font-weight: normal;
        line-height: 1.2;
        color: #76777c;

        &:last-of-type {
          margin-top: 0;
        }

        &::after {
          content: " ";
          position: absolute;
          top: -10px;
          right: auto;
          left: 65px;
          background: url(/assets/images/icons/icon_listing_chevron.svg);
          background-repeat: no-repeat;
          background-size: 40px 40px;
          width: 40px;
          height: 40px;
          transform: rotate(0deg);
          transition: all 0.25s ease-in-out;
        }

        &.is_active {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      .accordion_responsive + .content_text {
        display: flex;
        justify-content: space-between;
        line-height: 1.4;
        font-size: 14px;
        font-weight: normal;
        font-family: var(--camptonbook);
        color: var(--grey_dark);
      }

      .content_text {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        max-height: 0;
        padding: 5px 0 0 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;

        li {
          line-height: 1;
          margin-bottom: 5px;
          border-bottom: 0;
          padding: 0;

          @media screen and(max-width: $mobile-bp) {
            flex-direction: row;
          }

          b {
            position: relative;
            width: 20px;
            white-space: nowrap;
            overflow: hidden;
            letter-spacing: 12px;
            text-transform: capitalize;

            &:after {
              content: " :";
              position: absolute;
              top: 0;
              right: -10px;
            }
          }
        }
      }
    }
  }
}

// .radio_choice ~ form wrapper-accordion .btn_3 {
//   display: none;
// }

.radio_choice.is_active ~ form wrapper-accordion [data-filled] ~ .btn_3 {
  display: block;
  margin: 0 auto 50px auto;
}

mandatory-fields {
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px !important;
  font-size: 14px;
  color: rgba(44, 46, 53, 0.5);

  sup {
    font-size: 100%;
  }
}

form mandatory-fields {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.shipping.relay {
  margin: 0;

  wrapper-accordion {
    > button,
    > .btn_3 {
      margin-bottom: 20px !important;
    }

    mandatory-fields {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  card-shops .btn_3 {
    margin-top: 10px;
  }
}

overlay-filter .delivery_error {
  font-size: 16px;
  font-family: var(--camptonsemibold);
  line-height: 1.3;
  text-align: center;
}

overlay-filter.relay_list {
  flex-direction: row-reverse;
  width: auto;
  padding: 0 !important;

  @media screen and (max-width: $mobile-bp) {
    width: 900px;
    flex-direction: column;
  }

  list-shops {
    width: 375px;
    background: #f6f6f6;
    box-shadow: 0px 3px 20px #00000029;
    overflow: auto;

    @media screen and (min-width: $desktop-bp){
      width: 450px;
    }

    @media screen and (max-width: $mobile-bp) {
      width: 100%;

      head-filter {
        h2 {
          padding: 50px 50px 0 30px !important;
          margin-bottom: 0 !important;
        }
      }
    }
    
    ul &:first-of-type{
      @media screen and (max-width: $mobile-bp) {
        height: 45vh;
      }
    }
    ul {
      padding: 40px 30px;
      margin-bottom: 10px;

      li {
        border-bottom: 1px solid rgba(44, 46, 53, 0.4);
        margin-bottom: 20px;

        &:last-of-type {
          border-bottom: none;
        }

        input[type="radio"] ~ label::before {
          background: #f6f6f6;
          border: 2px solid rgba(44, 46, 53, 0.2);
          top: 0;
          left: 0 !important;
          transform: translateY(0);
        }

        input[type="radio"]:checked ~ label::before {
          border: 2px solid rgba(44, 46, 53, 0.2);
        }

        input[type="radio"].custom_radio:checked ~ label::after {
          left: 7px !important;
          top: 15px;
          width: 16px;
          height: 16px;
        }

        input[type="radio"] ~ label {
          display: flex;
          padding: 0 0 0 45px !important;
        }

        label {
          display: flex;
          padding-top: 0 !important;
          border: none !important;

          @media screen and(max-width: $mobile-bp) {
            flex-direction: column;
          }

          wrapper-description {
            width: calc(100% - 65px);
            display: flex;
            flex-direction: column;
            font-size: 16px;

            .desc {
              text-transform: lowercase;

              &:first-child {
                line-height: 1.3;
                margin-bottom: 5px;
                font-family: var(--camptonsemibold);
                font-weight: normal;
                font-size: 16px;

                &::first-letter {
                  text-transform: uppercase;
                }
              }

              &:last-of-type {
                line-height: 1.3;
                font-family: var(--camptonbook);
                font-weight: normal;
              }
            }

            .accordion_responsive {
              display: flex;
              position: relative;
              margin: 10px 0 5px 0;
              text-transform: uppercase;
              text-decoration: underline;
              cursor: pointer;
              font-family: var(--camptonmedium) !important;
              font-size: 14px;
              font-weight: normal;
              line-height: 1.2;
              color: #76777c;

              &:nth-of-type(2n) {
                margin-top: 0;
                font-family: var(--camptonmedium);

                &::after {
                  left: 70px;
                }
              }

              &::after {
                content: " ";
                position: absolute;
                top: -10px;
                right: auto;
                left: 65px;
                background: url(/assets/images/icons/icon_listing_chevron.svg);
                background-repeat: no-repeat;
                background-size: 40px 40px;
                width: 40px;
                height: 40px;
                transform: rotate(0deg);
                transition: all 0.25s ease-in-out;
              }

              &.is_active {
                &:after {
                  transform: rotate(180deg);
                }
              }
            }

            .accordion_responsive + .content_text {
              display: flex;
              justify-content: space-between;
              line-height: 1.4;
              font-size: 14px;
              font-weight: normal;
              font-family: var(--camptonbook);
              color: var(--grey_dark);
            }

            .content_text {
              display: flex;
              flex-direction: column;
              text-decoration: none;
              max-height: 0;
              padding: 5px 0 0 0;
              overflow: hidden;
              transition: max-height 0.2s ease-out;

              li {
                display: flex;
                line-height: 1;
                margin-bottom: 5px;
                border-bottom: 0;
                padding-bottom: 0;

                b {
                  position: relative;
                  width: 20px;
                  white-space: nowrap;
                  overflow: hidden;
                  letter-spacing: 12px;
                  text-transform: capitalize;

                  &:after {
                    content: " :";
                    position: absolute;
                    top: 0;
                    right: -10px;
                  }
                }
              }
            }
            .to-upper-case {
              text-transform: uppercase;
            }
            .pick-point-address{
              font-size: 15px;
            }
          }

          .distance {
            width: 55px;
            flex: 0 0 55px;
            margin-left: 10px;
            line-height: 1.2;
            font-family: var(--camptonsemibold) !important;
            font-weight: normal;
            font-size: 16px !important;
            white-space: nowrap;

            @media screen and(max-width: $mobile-bp) {
              flex: auto;
              margin-left: 0;
              margin-bottom: 15px;
            }
          }
        }

        .accordion_responsive {
          font-size: 14px !important;
        }
      }
    }
  }

  card-shops {
    width: 375px;
    padding: 60px 40px;
    background: #fff;
    overflow: auto;

    head-filter {
      position: relative;

      button {
        right: -40px;
        top: -60px;
      }
    }
    
    @media screen and (min-width: $desktop-bp){
      width: 450px;
    }

    @media screen and(max-width: $mobile-bp) {
      padding: 0;
      width: 100%;
      overflow: hidden;

      #map {
        height: 270px !important;
        width: 100%;
      }
    }

    h2 {
      margin-bottom: 20px !important;

      @media screen and(max-width: $mobile-bp) {
        padding: 30px 50px 5px 30px;
        margin-bottom: 10px !important;
      }
    }

    input-group {
      display: flex;
      margin-bottom: 25px;

      @media screen and(max-width: $mobile-bp) {
        padding: 0px 30px;
        margin-bottom: 10px;
      }

      input[type="search"] {
        border-radius: 0;
        border: 1px solid #2828280d;
        height: 50px;
        width: 100%;
        padding: 15px;
        font-family: var(--camptonbook);
        font-size: 18px;
        background: #f7f7f7;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 50px;
        height: 50px;
        width: 50px;
        background: var(--black);
      }
    }

    > img {
      margin-bottom: 35px;
      width: 100%;
      height: 570px;
      object-fit: cover;
    }

    > button,
    > .btn_3 {
      margin: 0 auto;
      display: block;
    }
  }
}

overlay-filter.relay_list.js_hide_order,
overlay-filter.relay_list.js_hide_shop {
  transform: translateX(-750px) !important;

  @media screen and (max-width: $mobile-bp) {
    transform: translateX(-100%) !important;
  }

  @media screen and (min-width: $desktop-bp){
    transform: translateX(-900px) !important;
  }
}

ul.listing_shop#listing_shop {
  label {
    width: 100% !important;
    padding: 0 !important;
    border: 0;
  }
}

overlay-filter.relay_list
  list-shops
  ul
  input[type="radio"].custom_radio:checked
  ~ label::after {
  @media screen and (max-width: $mobile-bp) {
    top: 47px !important;
  }
}

input-group.responsive_choice {
  display: none;

  @media screen and (max-width: $mobile-bp) {
    display: flex;
    margin-top: 25px;
    padding: 0 30px;

    input[type="search"] {
      border-radius: 0;
      border: 1px solid #2828280d;
      height: 50px;
      width: 100%;
      padding: 15px;
      font-family: var(--camptonbook);
      font-size: 16px;
      background: #ffffff;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 50px;
      height: 50px;
      width: 50px;
      background: var(--black);
    }
  }
}

@media screen and (max-width: $mobile-bp) {
  #map_btn {
    display: none;
  }
}
