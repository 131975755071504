@import "assets/styles/scss/config/globals";

.cards_wrapper {
    display: flex;
    flex-wrap: wrap;

    .general_card {
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 20px;

        @media screen and (max-width: $average-thr) {
            width: 100%;
            margin-right: 0;
        }

        @media screen and (min-width: $desktop-bp) {
            width: calc((100% / 3) - 14px);
        }

        &:nth-child(even) {
            margin-right: 0;
            @media screen and (min-width: $desktop-bp) {
                margin-right: 20px;
            }
        }

        &:nth-child(3n+3) {
            @media screen and (max-width: $average-thr),
                screen and (min-width: $desktop-bp) {
                margin-right: 0;
            }
        }

        &.beige_card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: var(--beige);

            a {
                width: auto;
                margin-bottom: 10px;
            }

            &:nth-child(odd) {
                img {
                    width: auto;
                    height: 80px;
                }
            }

            img {
                width: 80px;
                height: 80px;
                object-position: contain;
                margin: 10px 0;
            }

            h2 {
                margin-bottom: 15px;
                font-family: var(--camptonsemibold);
                font-size: 28px;
                font-weight: normal;
                line-height: 1.3;
                text-transform: uppercase;
                text-align: center;
            }

            .wysiwyg {
                margin-bottom: 15px;
                font-family: var(--camptonbook);
                font-weight: normal;
                line-height: 1.4;
                text-align: center;

                b,
                strong {
                    font-weight: normal;
                    font-family: var(--camptonsemibold);
                }
            }
        }
    }
}

.general_card,
general-card {
    display: flex;
    flex-direction: column;
    background: var(--grey_light);
    .images-wrapper{
        height: 168px;
    }
    card-desc {
        display: flex;
        padding: 15px 20px;
        margin-right: auto;
        font-family: var(--camptonmedium);
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        background: var(--beige);
        color: #fff;
    }

    img {
        height: 150px;
        min-width: 100%;
        width: auto;

        &.icon_top {
            width: auto;
            height: fit-content;
            margin: 0;
            min-width: unset;
        }
    }

    .color-choice,
    color-choice {
        display: flex;
        margin: 0 49px 0px 49px;
        padding-bottom: 15px;

        .colorContent,
        color-content {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            margin-right: 8px;

            &:last-of-type {
                margin-right: 0;
            }

            &.active {
                position: relative;

                &::before {
                    content: '';
                    border: 1px solid var(--black);
                    border-radius: 100%;
                    width: 21px;
                    height: 21px;
                    position: absolute;
                    left: -3px;
                    top: -3px;
                }
            }

        }
    }
    .btn_small{
        margin-bottom: 20px;
    }
    title-product {
        display: flex;
        flex-direction: column;
        margin: 10px auto 5px auto;
        padding:0 30px;
        font-size: 23px;
        font-family: var(--camptonsemibold);
        font-weight: 400;

        name-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: hidden;
            
            span {
                margin-right: 5px;
            }
        }

        .main_title {
            width: 100%;
            margin-bottom: 5px;
            font-size: 14px;
            font-family: var(--camptonbook);
            font-weight: 400;
            text-transform: uppercase;
            color: rgba(44, 46, 53, 0.3);
        }

        .price {
            font-size: 18px;
            font-family: var(--camptonbook);
            font-weight: 400;
            margin-top: 5px;

            .new_price {
                white-space: nowrap;
                margin-right: 10px;
                font-family: var(--camptonmedium);
                color: #E51D3C;
            }

            .real_price {
                margin-right: 0;
                white-space: nowrap;
            }

            del,
            strike {
                white-space: nowrap;
            }
        }

        .card-subline {
            font-family: var(--camptonbook);
            font-size: 14px;
        }
    }

    >a {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 15px;
        padding-bottom: 0;
    }
}

div.web-slogan-top {
    top: 20px; // same value as tag 'nouveauté'
    right: 25px;
    text-align: center;
    margin: 0 0 0 6px;
    font-weight: bold;
    color: #205334;

    div.web-slogan-top-bg {
        padding: 5px 8px 2px 8px;
        background-color: #D2E1DE;
        font-weight: bold;
    }

    @media screen and(max-width: $mobile-bp) {
        top: 15px;
    }
}