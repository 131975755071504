div.prescription-social-refund-radio
{
    display: none;
    padding-top: 10px;
}

div.precription-social-refund-first 
{
    margin-right: 100px;
}

div.prescription-download-list {
    display: none;

    .prescription-download-container {
        display: flex;
        flex-direction: column;

        .prescription-download-item {
            border: 1px solid rgba(44, 46, 53, 0.2);
            padding: 40px;
            font-size: 15px;
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            label {
                cursor: pointer;
            }

            &:hover {
                border: 1px solid var(--black);
            }

            &::before {
                content: " ";
                padding-right: 45px;
                background: url(/assets/images/icons/_icon_pdf.svg);
                background-repeat: no-repeat;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
            }
        }
    }
}

.prescription_page 
{
    textarea {
        font-family: var(--campton-book);
    }
}