@import "assets/styles/scss/config/globals";

breaking-news {
    display: flex;
    padding: 20px 30px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: normal;
    color: var(--black);
    background: var(--grey_blue);

    img {
        margin-right: 25px;
        width: 40px;
        height: 40px;
    }

    @media screen and(max-width: $mobile-bp) {
        font-size: 14px;
        padding: 20px;

        img {
            display: none;
        }
    }
}