@import "assets/styles/scss/config/globals";

.cart {
    margin: 30px auto 115px auto;

    @media screen and(max-width: $mobile-bp) {
        margin: 30px 0;
    }
    .alma-payment-plans-container{
        background-color: var(--grey_light);
        padding-left: 0;
        padding-bottom: 0;
        svg {
            height: 25px; 
            width: 75px; 
        }
        div{
            font-family: var(--camptonbook);
            font-size: 16px;
            @media screen and(max-width: $mobile-bp) {
                font-size: 14px;
            }
        }
    }
    &.home_trial {
        cart-choice {
            margin-bottom: 50px;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 30px;
            }
        }

        left-cart .showFrame,
        left-cart .delete {
            @media screen and(max-width: $mobile-bp) {
                margin-top: 10px;
            }
        }

        left-cart cart-choice {
            &:last-of-type {
                @media screen and(max-width: $mobile-bp) {
                    margin-bottom: 30px;
                }
            }
        }

        right-cart ul:last-of-type {
            padding: 40px;

            @media screen and(max-width: $mobile-bp) {
                padding: 30px;
            }
        }

        right-cart ul:last-of-type li {
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid rgba(44, 46, 53, .1);

            &.proceed {
                padding-bottom: 0;
                margin-bottom: 0;
                border: none;
            }
        }

        right-cart ul:last-of-type li:last-of-type {
            overflow-y: auto;
            height: auto;
        }
    }

    breadcrumb-block {
        @media screen and(max-width: $mobile-bp) {
            display: none;
        }
    }

    sticky-payment {
        @media screen and(min-width: $desktop-bp) {
            display: none;
        }

        padding: 10px 15px;
        box-shadow: 0px 3px 35px #00000012;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
        background: #fff;

        sub-total {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 14px;
            font-family: var(--camptonbook);

            b,
            strong {
                font-family: var(--camptonsemibold);
                font-size: 16px;
                font-weight: normal;
            }
        }

        a {
            padding: 15px;
        }
    }
}

cart-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and(min-width: $desktop-bp) {
        flex-direction: row;
    }

    left-cart,
    right-cart {
        display: flex;
        flex-direction: column;
        .cart-image{
            padding: 20px;
            img {
                height: auto;
                width: 100%;
                object-fit: contain;
                }
            a { 
                cursor: pointer;
            }
        }
    }

    breaking-news {
        margin-bottom: 20px;
    }

    left-cart {
        margin-bottom: 40px;

        @media screen and(min-width: $desktop-bp) {
            width: 65%;
            margin-bottom: 0;
        }

        h1 {
            margin-bottom: 30px;
            padding: 0;
            text-transform: none;
            font-family: var(--camptonsemibold);
            font-size: 22px;
            font-weight: normal;
            text-align: left;
            line-height: 1.2;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 20px;
            }
        }

        cart-empty {
            text-align: center;
            height: 100px;
            border: 1px rgba(44, 46, 53, 0.1) solid;
            margin-bottom: 30px;
            line-height: 96px;
            font-size: 16px;
            font-weight: bold;
        }

        cart-choice {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid rgba(44, 46, 53, 0.1);
            margin-bottom: 50px;
            padding: 20px 35px;

            @media screen and(max-width: $mobile-bp) {
                flex-direction: column;
                padding: 30px;
                margin-bottom: 30px;
            }

            voucher-block {
                width: 100%;

                @media screen and(max-width: $mobile-bp) {
                    width: 100%;

                    >a {
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }

                ul {
                    display: flex;
                    flex-direction: column;

                    li {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        font-size: 16px;
                        font-family: var(--camptonbook);
                        font-weight: normal;
                        line-height: 1.2;
                        color: var(--grey_dark);

                        .price {
                            white-space: nowrap;
                            color: var(--black);
                        }
                    }

                    .voucher-title {
                        font-weight: bold;
                        color: var(--black);
                    }
                }
            }

            .delete {
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                margin-bottom: 30px;
                text-transform: uppercase;
                font-family: var(--camptonmedium);
                font-weight: normal;
                font-size: 14px;
                line-height: 1.2;

                a{
                    &:hover {
                        font-weight: bold;
                    }
                }
                
                @media screen and(max-width: $mobile-bp) {
                    order: 1;
                    margin-bottom: 0;
                    justify-content: center;

                    &:hover {
                        font-weight: bold;
                    }
                }

                img {
                    margin-left: 5px;
                    margin-bottom: 1px;
                }
            }

            .showFrame {
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                margin-bottom: 30px;
                text-transform: uppercase;
                font-family: var(--camptonmedium);
                font-weight: normal;
                text-decoration: underline;
                font-size: 14px;
                line-height: 1.2;
                color: var(--black);

                a {
                    &:hover {
                        font-weight: bold;
                    }
                }
                @media screen and(max-width: $mobile-bp) {
                    display: none;
                }

                img {
                    margin-left: 5px;
                }
            }

            choice-image {
                width: 50%;
                padding: 0 50px 0 15px;

                @media screen and(max-width: $mobile-bp) {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 0 15px 0;
                }

                img {
                    width: 275px;
                    object-fit: contain;
                }
            }

            choice-block {
                width: 50%;

                @media screen and(max-width: $mobile-bp) {
                    width: 100%;

                    >a {
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }

                ul {
                    display: flex;
                    flex-direction: column;

                    li {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 8px;
                        font-size: 16px;
                        font-family: var(--camptonbook);
                        font-weight: normal;
                        line-height: 1.2;
                        color: var(--grey_dark);

                        &.second-pair-reduction {
                            border-top: 1px solid var(--grey);
                            padding-top: 20px;
                            margin-top: 10px;
                            font-size: 18px;
                        }

                        &.total {
                            border-top: 1px solid var(--grey);
                            padding-top: 20px;
                            margin-top: 10px;
                            margin-bottom: 35px;
                            font-size: 18px;
                            font-family: var(--camptonbook);
                            font-weight: normal;

                            b,
                            strong {
                                font-family: var(--camptonsemibold);
                                font-size: 18px;
                                font-weight: normal;
                            }
                        }

                        .price {
                            white-space: nowrap;
                            color: var(--black);
                            margin-left: 10px;
                        }

                        b,
                        strong {
                            font-size: 18px;
                            font-family: var(--camptonmedium);
                            font-weight: normal;
                            color: var(--black);
                        }
                    }
                }

                >a,
                >button {
                    display: block;
                    font-size: 14px;
                    font-family: var(--camptonmedium);
                    font-weight: normal;
                    text-transform: uppercase;
                    text-decoration: underline;
                    text-align: right;
                }
            }
        }
    }

    right-cart {
        margin-left: 0;

        ul {
            padding: 40px;
            margin-bottom: 20px;
            background: var(--grey_light);

            @media screen and(max-width: $mobile-bp) {
                padding: 30px;
            }

            .login-page-link {
                text-decoration : underline;
                font-weight: bold;
            }

            &:last-of-type {
                padding: 20px 40px;

                @media screen and(max-width: $mobile-bp) {
                    padding: 20px 30px;
                }

                li {
                    padding-bottom: 20;
                    margin-bottom: 0;
                    border: none;

                    &:last-of-type {
                        flex-direction: column;
                        overflow: hidden;
                        transition: height 0.2s ease-out;
                    }
                }

                input-group {
                    display: flex;
                    width: 100%;
                    margin-top: 20px;
                    

                    input {
                        height: 50px;
                        width: 100%;
                        padding: 15px;
                        border: 0;
                        font-family: var(--camptonbook);
                        font-size: 16px;
                        background: #fff;

                        &.disabled-voucher-input {
                            pointer-events: none;
                            background-color: var(--silver);
                        }
                    }

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 1 0 auto;
                        height: 50px;
                        width: 50px;
                        background: var(--black);
                        color: #fff;
                        font-size: 14px;
                        font-family: var(--camptonmedium);
                        font-weight: normal;

                        &:disabled {
                            background-color: var(--silver);
                        }
                    }

                    .voucher-spinner {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50px;
                        height: 50px;
                        background-color: var(--black);
                        flex: 1 0 auto;
                    }
                }
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: 1px solid rgba(44, 46, 53, 0.1);
                margin-bottom: 20px;
                line-height: 1.5;
                font-size: 16px;
                font-weight: normal;
                font-family: var(--camptonbook);

                b,
                strong {
                    font-weight: normal;
                    font-family: var(--camptonsemibold);
                }

                &.accordion_responsive {
                    cursor: pointer;

                    &.is_active {
                        &::after {
                            content: '-';
                            position: absolute;
                            right: 50px;
                            font-size: 30px;
                            font-family: var(--camptonbook);
                            font-weight: 400;
                            color: var(--grey);

                            @media screen and(max-width: $mobile-bp) {
                                right: 40px;
                            }
                        }
                    }

                    &::after {
                        content: '+';
                        position: absolute;
                        right: 50px;
                        font-size: 30px;
                        font-family: var(--camptonbook);
                        font-weight: 400;
                        color: var(--grey);
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                    border: none;
                    padding-bottom: 0;
                }

                &.proceed {
                    flex-direction: row;
                        
                    a {
                        margin-bottom: 15px;

                        &:first-child {
                            margin-right: 10px;
                        }

                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                    

                    @media screen and (max-width: $mobile-bp),
                        screen  and (min-width: $desktop-bp) {
                        flex-direction: column;
                    
                        a {
                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            &:first-child {
                                margin-right: 0;
                            }
    
                            &:last-child {
                                margin-left: 0;
                            }
                        }
                    }

                    form {
                        width: 100%;

                        .inscription-btn {
                            margin-bottom: 0 !important;
                        }

                        .form-button {
                            padding: 15px 30px;
                            margin-bottom: 15px !important;
                            width: 100%;
                        }
                    }
                }

                >a {
                    width: 100%;
                }
            }
            &.voucher-form {
                p {
                    font-size: 12px;
                    color: #d75931;

                    a {
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }

                .validity-message {
                    color: var(--green);
                }
            }
        }

        @media screen and(min-width: $desktop-bp) {
            margin-left: 30px;
            width: 35%;
        }
                
        .voucher-accordion {
            padding-bottom: 0;
        }
    }
}

advices-block {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background: var(--beige);
    font-size: 14px;
    font-weight: normal;
    font-family: var(--camptonbook);
    line-height: 1.3;

    @media screen and(min-width: $desktop-bp) {
        padding: 20px 55px;
    }

    >img {
        height: 40px;
        width: 40px;
        flex: 0 0 40px;

        @media screen and(max-width: $mobile-bp) {
            height: 33px;
            width: 33px;
            flex: 0 0 33px;
        }
    }

    advices-desc {
        margin-left: 30px;

        @media screen and(max-width: $mobile-bp) {
            margin-left: 15px;
        }

        a {
            font-family: var(--camptonbook);
            font-weight: normal;
            font-size: 14px;

            &:hover {
                font-weight: bold;
            }
        }

        >b,
        strong {
            font-size: 16px;
            font-family: var(--camptonsemibold);
            font-weight: normal;
        }

        >div,
        >p,
        >span {

            b,
            strong,
            a {
                font-family: var(--camptonsemibold);
                font-weight: normal;
                font-size: 14px;
            }
        }
    }
}

reassurance-wrapper {
    display: flex;

    @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
    }

    reassurance-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% / 3);
        min-height: 145px;
        height: auto;
        margin-right: 20px;
        padding: 10px;
        border: 2px solid #D9D1C8;
        font-size: 14px;
        line-height: 1.2;
        font-weight: normal;
        font-family: var(--camptonbook);

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media screen and(max-width: $mobile-bp) {
                align-items: flex-start;
            }

            @media screen and(max-width: 374px) {
                display: block !important;
            }
        }

        @media screen and(max-width: $mobile-bp) {
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            min-height: 0;
            padding: 20px;
            margin-right: 0;
            margin-bottom: 10px;

            div {
                display: flex;
                flex-direction: column;
            }
        }

        &:last-of-type {
            margin-right: 0;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 0;
            }
        }

        b,
        strong {
            font-family: var(--camptonsemibold);
            font-weight: normal;
        }

        img {
            width: 45px;
            height: 35px;
            object-fit: contain;
            margin-bottom: 15px;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 0;
                flex: 0 0 45px;
                margin-right: 30px;
            }
        }
    }
}

second-pair-banner {
    width: 100%;
    padding: 20px;
    border: 2px solid #D9D1C8;
    margin-bottom: 20px;
}