@import "assets/styles/scss/config/globals";

.replacement {
    max-width: none;
    .blocks_description {
        padding-top: 80px;
        padding-bottom: 20px;
        margin-bottom: 70px;
        background: var(--grey_light);
        
        @media screen and(max-width: $mobile-bp) {
            padding-top: 50px;
            padding-bottom: 10px;
            margin-bottom: 50px;
        }

        cms-left-image, 
        cms-right-image {
            margin-bottom: 70px !important;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 50px !important;
            }
        }
    }
}

.background_beige{
    background: var(--beige);
}

.background_grey_blue{
    background: var(--grey_blue_2);
}

#bloc_1{

    @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
    }

    .frame_banner_text{
        width: 50%;
        @media screen and(max-width: $mobile-bp) {
            width: 100%;
        }
    }
    .frame_banner_img{
        width: 50%;

        @media screen and(max-width: $mobile-bp) {
            width: 80%;
        }
    }
}

#bloc_2{
    @media screen and(max-width: $mobile-bp) {
        flex-direction: column;
        margin-top: 82px;
        margin-bottom: 0px;
    }

    .frame_banner_text{
        width: 75%;
        @media screen and(max-width: $mobile-bp) {
            width: 100%;
        }
    }

    .frame_banner_img{
        width: 25%;
        position: relative;
        display: flex;
        align-items: center;

        @media screen and(max-width: $mobile-bp) {
            width: 250px;
            height: 160px;
        }
    }

    .img_absolute{
        position: absolute;
    
        @media screen and(max-width: $mobile-bp) {
            top: -85px;
        }
    }
}

#bloc_3{

    display: flex;
    align-items: end;
    padding:0;

    @media screen and(max-width: $mobile-bp) {
        flex-direction: column-reverse;
        align-items: center;
    }

    .frame_banner_text{
        width: 50%;
        padding: 20px 0px 20px 20px;

        @media screen and(max-width: $mobile-bp) {
            padding: 40px 20px;
            width: 100%;
        }

        @media screen and(min-width: $desktop-bp) {
            padding: 40px 0px 40px 75px;
        }
    }
    .frame_banner_img{
        width: 40%;
        position: relative;
        display: flex;
        align-items: end;
        justify-content: center;

        @media screen and(max-width: $mobile-bp) {
            width: 315px;
            height: 190px;
        }
    }
    .img_absolute{
        width: 70%;
        position: absolute;

        @media screen and(max-width: $mobile-bp) {
            width: 100%;
            top: -50px;
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 100px, rgba(0,0,0,0));
        }
    }
}


.frame-banner{
    display: flex;
    gap: 20px;
    align-items: center;
    height: auto;
    padding: 20px 20px;
    margin-top: 32px;
    margin-bottom: 32px;
    overflow: visible;

    @media screen and(max-width: $mobile-bp) {
        padding: 40px 20px;
        margin-top: 0;
    }
    
    @media screen and(min-width: $desktop-bp) {
        padding: 40px 75px;
    }

    .frame_banner_title{
        padding: 0;
        font-family: var(--camptonmedium);
        text-transform: none;
        font-size: 24px;
        text-align: start;
        
        @media screen and(max-width: $mobile-bp) {
            font-size: 32px;
        }
        
        @media screen and(min-width: $desktop-bp) {
            font-size: 42px;
        }

        span{
            background: var(--white);
        }
    }

    .frame_banner_paragraph{
        font-family: var(--camptonbook);
        font-size: 14px;

        @media screen and(max-width: $mobile-bp) {
            font-size: 18px;
        }
        
        @media screen and(min-width: $desktop-bp) {
            font-size: 18px;
        }
    }
}

frame-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 90px !important;

    @media screen and(max-width: $mobile-bp) {
        margin-bottom: 40px !important;
    }

    frame-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 75px auto;
        width: 100%;
        
        @media screen and(min-width: $desktop-bp) {
            width: 65%;
        }

        @media screen and(max-width: $mobile-bp) {
            margin: 0 auto 50px auto;
        }

        > a {
            padding-right: 50px !important;

            &:hover {
                &:after {
                    content: ' ';
                    background: url('/assets/images/icons/_icon_chevron_slider.svg') no-repeat;
                    background-size: 22px 22px;
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 14px;
                    right: 25px;
                }
            }

            &:after {
                content: ' ';
                background: url('/assets/images/icons/_icon_chevron_slider_white.svg') no-repeat;
                background-size: 22px 22px;
                width: 22px;
                height: 22px;
                position: absolute;
                top: 14px;
                right: 25px;
            }
        }

        h2,
        h3,
        h4 {
            text-align: center;
            margin-bottom: 20px;
            line-height: 1.2;
            font-family: var(--camptonsemibold);
            font-size: 28px;
            font-weight: normal;

            @media screen and(max-width: $mobile-bp) {
                margin-bottom: 10px;
                font-size: 22px;
            }
        }

        p, 
        div,
        span {
            margin-bottom: 25px;
            text-align: center;
            font-size: 14px;

            @media screen and(max-width: $mobile-bp) {
                font-size: 18px;
            }
            
            @media screen and(min-width: $desktop-bp) {
                font-size: 22px;
            }

        }

        @media screen and(max-width: $small-thr) {
            a {
                width: 100%;
            }
        }
    }

    frame-schema {
        display: flex;

        @media screen and(max-width: $mobile-bp) {
            flex-direction: column;
        }

        card-schema {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            width: calc((100% / 4));
            margin-right: 5px;
            
            @media screen and(max-width: 1269px) {
                width: calc((100% / 4) - 10px);
                margin-right: 20px;
            }

            @media screen and(min-width: $desktop-bp) {
                width: calc((100% / 4) - 30px);
                margin-right: 70px;
            }

            @media screen and(max-width: $mobile-bp) {
                flex-wrap: nowrap;
                width: 100%;
                margin-right: 0;
                margin-bottom: 35px;
            }

            img {
                width: 100px;

                @media screen and(max-width: $mobile-bp) {
                    left: 0;
                }
            }

            &:first-of-type {

                &:after {
                    content: ' ';
                    display: none;
                    background: url(/assets/images/icons/_arrow-replacement.svg) no-repeat;
                    background-size: 80px 15px;
                    width: 80px;
                    height: 15px;
                    left: 100%;
                    top: 20%;
                    position: absolute;

                    @media screen and(min-width: $desktop-bp) {
                        display: block;
                    }

                    @media screen and(max-width: $mobile-bp) {
                        display: none;
                    }
                }
            }

            &:nth-child(2) {

                &:after {
                    content: ' ';
                    display: none;
                    background: url(/assets/images/icons/_arrow-replacement.svg) no-repeat;
                    background-size: 80px 15px;
                    width: 80px;
                    height: 15px;
                    left: 100%;
                    top: 20%;
                    position: absolute;

                    @media screen and(min-width: $desktop-bp) {
                        display: block;
                    }

                    @media screen and(max-width: $mobile-bp) {
                        display: none;
                    }
                }
            }
            
            &:nth-child(3) {

                &:after {
                    content: ' ';
                    display: none;
                    background: url(/assets/images/icons/_arrow-replacement.svg) no-repeat;
                    background-size: 80px 15px;
                    width: 80px;
                    height: 15px;
                    left: 100%;
                    top: 20%;
                    position: absolute;

                    @media screen and(min-width: $desktop-bp) {
                        display: block;
                    }

                    @media screen and(max-width: $mobile-bp) {
                        display: none;
                    }
                }
            }

            &:last-of-type {
                margin-right: 0;

                @media screen and(max-width: $mobile-bp) {
                    margin-bottom: 0;
                }
            }

            title-schema {
                line-height: 1.2;
                margin-bottom: 10px;
                font-family: var(--camptonmedium);
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                
                @media screen and(min-width: $desktop-bp) {
                    font-size: 22px;
                }
                
                @media screen and(max-width: $mobile-bp) {
                    font-size: 22px;
                    text-align: left;
                }
            }

            p,
            div,
            span {
                width: 80%;
                font-family: var(--camptonbook);
                font-style: italic;
                font-size: 14px;
                text-align: center;
                line-height: 1.4;

                @media screen and(min-width: $desktop-bp) {
                    font-size: 16px;
                }

                @media screen and(max-width: $mobile-bp) {
                    font-size: 16px;
                }
            }
        }
    }
}