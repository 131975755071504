@import "assets/styles/scss/config/globals";

.error_page {
    margin-bottom: 120px;


    h2, 
    h3 {
        margin-bottom: 35px;
        text-align: center;
        line-height: 1.2;
        font-size: 28px;
        font-family: var(--camptonsemibold);
        font-weight: 400;
        text-transform: uppercase;

        @media screen and(max-width: $mobile-bp) {
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 20px;
            font-size: 22px;
        }
    }
}

wrapper-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        margin-bottom: 15px;
        font-family: var(--camptonsemibold);
        font-size: 28px;
        font-weight: normal;
        line-height: 1.2;

        @media screen and(max-width: $mobile-bp) {
            font-size: 22px;
        }
    }

    .wysiwyg {
        margin-bottom: 30px;
        line-height: 1.4;
        font-size: 18px;
        font-family: var(--camptonbook);
        font-weight: normal;

        @media screen and(max-width: $mobile-bp) {
            margin-bottom: 20px;
        }
    }

    .btn_3 {
        margin-bottom: 80px;

        @media screen and(max-width: $mobile-bp) {
            margin-bottom: 65px;
        }
    }
}