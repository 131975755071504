@import "assets/styles/scss/base/functions";
@import "assets/styles/scss/config/globals";

* {
	-webkit-tap-highlight-color: transparent;
	box-sizing: border-box;
	outline: 0;
}

*:before,
*:after {
	box-sizing: border-box;
}

:root {
	font-size: 62.5%;
}

html {
	height: 100%;
	min-height: 100%;
	font-size: 1.6rem;
	-webkit-font-smoothing: antialiased;
	scroll-behavior: smooth;
	color: var(--black);
}

@viewport {
	zoom: 0.75;
}

body {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100%;
	color: var(--black);
	background: #fff !important;
	font-family: var(--camptonbook);
	font-size: 1.6rem;
	font-weight: 400;
	font-style: normal;
	line-height: 1;
	overflow-x: hidden;

	&.scroll-locked {
		overflow: hidden;
	}
}

p {
	margin-bottom: 2rem;
	font-weight: 400;
	line-height: 1.45;

	&:last-child {
		margin-bottom: 0;
	}

	em {
		font-style: italic;
	}
}

/******************************/
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type="search"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type="email"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type="email"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type="text"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type="text"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type="tel"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type="tel"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input[type="email"]::-webkit-search-decoration,
input[type="email"]::-webkit-search-cancel-button,
input[type="email"]::-webkit-search-results-button,
input[type="email"]::-webkit-search-results-decoration,
input[type="text"]::-webkit-search-decoration,
input[type="text"]::-webkit-search-cancel-button,
input[type="text"]::-webkit-search-results-button,
input[type="text"]::-webkit-search-results-decoration,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

input[type="tel"]::-webkit-search-decoration,
input[type="tel"]::-webkit-search-cancel-button,
input[type="tel"]::-webkit-search-results-button,
input[type="tel"]::-webkit-search-results-decoration {
	display: none;
}

/* Hide password reveal button in MS Edge */
::-ms-reveal {
	display: none;
}

/******************************/

.hide_scroll {
	overflow-y: hidden;
}

a {
	color: inherit;
	text-decoration: none;
	transition: opacity 0.3s ease, color 0.3s ease, background 0.3s ease;
}

strong span {
	font-weight: 700 !important;
}

strong {
	font-weight: 700;
	display: contents;

	@include bp(ie101) {
		display: ruby-text-container;
	}
}

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

sup {
	vertical-align: top;
	font-size: 50%;
}

.wrapper {
	max-width: $l;
	padding: 0 1.5rem;
	margin: 0 auto;
	position: relative;

	@media screen and(max-width: $mobile-bp) {
		padding: 0 24px;
	}

	@include bp(xs) {
		/* max-width: $xs; // Adaptive website */
		max-width: $s;
		padding: 0 24px;
	}

	@include bp(s) {
		/* max-width: $s; // Adaptive website */
		max-width: $m;
		padding: 0 24px;
	}

	@include bp(m) {
		/* max-width: $m; // Adaptive website */
		max-width: $l;
		padding: 0 24px;
	}

	@include bp(l) {
		max-width: $l;
	}
}

.recaptcha-legal-mentions {
	box-sizing: border-box;
	margin: 0 auto;
	border: 1px solid var(--grey-3);
	border-radius: 0.5rem;
	padding: 1.5rem;
	padding-left: calc(48px + 5rem);
	background-color: var(--grey-6);
	background-image: url("https://www.gstatic.com/recaptcha/api2/logo_48.png");
	background-repeat: no-repeat;
	background-position: center left 2rem;
	min-height: calc(48px + 1rem);
	color: var(--grey);
	width: 32rem;
	font-size: 1.1rem;

	@media screen and (max-width: 479px) {
		width: 100%;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

div.recaptcha-legal-mentions a {
	text-decoration: underline;
}

button.add {
	margin-top: 15px;
}



/**************** Begin Popin messenger ***************/
notify-wrapper {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 300px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	margin: 0;
	padding: 0;
	z-index: 999;
}

notify-content {
	display: block;
	width: 100%;
	height: 0;
	background-color: white;
	margin-top: 1rem;
	padding: 2rem;
	transition: height 1s ease-in;
	box-shadow: 0 0 0.5rem 0.2rem rgba(64, 60, 55, .2)
}

notify-content.invisible {
	pointer-events: none;
	opacity: 0;
	height: auto;
}

notify-content.fade-out {
	pointer-events: none;
	opacity: 0;
	transition: opacity 1s ease-out;
}

flash-messenger {
	display: block;
	font-size: 1.6rem;
	font-family: var(--camptonbook);
	text-align: center;
	line-height: 1.2;
}

flash-messenger.error,
flash-messenger.critical {
	color: #a94442;
}

flash-messenger.warning {
	color: #8a6d3b;
}

flash-messenger.notice,
flash-messenger.info {
	color: #3c763d;
}

flash-messenger.debug {
	color: #31708f;
}

/**************** End Popin messenger ***************/

.js_input_phone beautiful-select {
	border: 0.1rem solid var(--grey-3) !important;
}

template,
[hidden] {
	display: none !important;
}

recipe-container,
news-container,
shows-container,
press-container {
	display: flex;
	flex-wrap: wrap;
}

.d-flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.row-reverse {
	flex-direction: row-reverse;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-start {
	justify-content: flex-start;
}

.justify-content-end {
	justify-content: flex-end;
}

.justify-content-between {
	justify-content: space-between;
}

.justify-content-around {
	justify-content: space-around;
}

.align-items-center {
	align-items: center;
}

.align-items-start {
	align-items: start;
}

.ml-2 {
	margin-left: 2px;
}

.mt-20 {
	margin-top: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.bold {
	font-family: var(--camptonsemibold);
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-justify {
	text-align: justify;
}

// tooltip for frames color disc 
.tooltip-color {
	text-decoration: none;
	position: relative;
	font-size: 14px;
}

.tooltip-color div {
	display: none;
}

.tooltip-color:hover div {
	display: block;
	position: fixed;
	overflow: hidden;
}

.green-text {
	color: var(--green);
}

.grey-text {
	color: var(--grey);
}

.bg-grey {
	background-color: var(--grey_disabled);
}

a.disabled {
	pointer-events: none;
	cursor: default;
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.white-space-nowrap {
	white-space: nowrap;
}

.strikethrough {
	text-decoration: line-through;
}

.text-size-75 {
	font-size: 75%;
}

.edit-input {
	padding: 10px;
	background-color: lightgreen;
	width: 100px;
}

.edit-mode-info {
	padding: 10px;
	background-color: lightsteelblue;
	width: 100px;
}

.x-field:hover {
	border: 1px dashed red;
}